import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CateringCard from '../../components/CateringCard';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import { getCategoryCateringProducts } from '../../selectors/cart/cartSelector';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { authenticatedSelector } from '../../selectors/user';
import { withRouter } from 'react-router-dom';
import { setVariant } from '../../lib/catering';
import { redirectToLogin } from '../../actions/app';
import * as cateringActions from '../../actions/catering';
import * as pageActions from '../../actions/page';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import Cookie from 'js-cookie';
import { fetchStoreNumberBySiteId } from '../../api/endpoints/delivery';
import { sendCateringItemAddTracking } from '../../lib/analytics/analytics';
import OverFlowContainer from '../../components/OverFlowContainer';

const impressionListName = 'Catering category';

function CateringCategoryPage({
  id,
  getCateringProductsByCategory,
  getPageById,
  catering,
  storeId,
  redirectToLogin,
  isAuthenticated,
  page
}) {
  useEffect(() => {
    getCateringProductsByCategory(id, {}).then(({ data }) => {
      try {
        const assortmentValuesCookie = Cookie.get('assortment_values');
        const assortmentValues =
          assortmentValuesCookie && JSON.parse(assortmentValuesCookie);

        if (assortmentValues && assortmentValues.userSiteId) {
          fetchStoreNumberBySiteId(assortmentValues.userSiteId)
            .then(({ data: storeNumberData }) => {
              const ga4ImpressionItems = data.map((prod, index) => {
                let price = 0;
                let discount = 0;
                let variant = undefined;
                let cakePortions = '';
                const productCategory =
                  prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                  undefined;

                const isCake = productCategory?.includes('tartor');
                let matchedVariant;
                if (prod.variants && prod.variants.length > 0) {
                  matchedVariant =
                    prod.variants.find(
                      x => x.name === 'Standard' && x.prices?.length
                    ) || prod.variants[0];

                  variant = matchedVariant.name;

                  const priceInfo = matchedVariant.prices.find(
                    x => x.storeNumber == storeNumberData.storeNumber
                  );
                  const ordPrice = priceInfo?.ordinaryPrice || 0;
                  const currPrice = priceInfo?.currentPrice || 0;

                  price = ordPrice;
                  discount =
                    ordPrice !== currPrice
                      ? (ordPrice - currPrice) * prod.minOrder
                      : 0;
                }
                if (isCake) {
                  cakePortions = prod.variants
                    .filter(x => x.name === 'Standard' && x.prices?.length)
                    ?.reduce(
                      (a, b) => (a < b.quantityFrom ? a : b.quantityFrom),
                      4
                    );
                }

                return {
                  item_id: matchedVariant?.gtin || prod.id,
                  item_name:
                    prod.name +
                    `${cakePortions ? ` - ${cakePortions} portioner` : ''}`,
                  item_brand: prod.brand || 'City Gross',
                  item_category: productCategory,
                  item_variant: variant,

                  item_list_name: impressionListName,
                  item_list_id: impressionListName,
                  index: index + 1,

                  price: price,
                  quantity: prod.minOrder || (isCake ? 1 : cakePortions),
                  discount: discount
                };
              });

              ga4.viewItemList({
                items: ga4ImpressionItems,
                item_list_id: impressionListName,
                item_list_name: impressionListName
              });
            })
            .catch(err => {
              console.error(err);

              const ga4ImpressionItems = data.map((prod, index) => {
                const productCategory =
                  prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                  undefined;

                const isCake = productCategory?.includes('tartor');
                return {
                  item_id: prod.id,
                  item_name: prod.name,
                  item_brand: prod.brand || 'City Gross',
                  item_category: productCategory,
                  item_variant: undefined,

                  item_list_name: impressionListName,
                  item_list_id: impressionListName,
                  index: index + 1,

                  price: 0,
                  quantity: prod.minOrder || (isCake ? 1 : 4),
                  discount: 0
                };
              });

              ga4.viewItemList({
                items: ga4ImpressionItems,
                item_list_id: impressionListName,
                item_list_name: impressionListName
              });
            });
        } else {
          const ga4ImpressionItems = data.map((prod, index) => {
            const productCategory =
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined;

            const isCake = productCategory?.includes('tartor');
            return {
              item_id: prod.id,
              item_name: prod.name,
              item_brand: prod.brand || 'City Gross',
              item_category: productCategory,
              item_variant: undefined,

              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: 0,
              quantity: prod.minOrder || (isCake ? 1 : 4),
              discount: 0
            };
          });

          ga4.viewItemList({
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });
        }
      } catch (error) {
        console.error(error);
      }
    });
    getPageById(id);
  }, []);

  const { products } = catering;
  return (
    <>
      <OverFlowContainer>
        <ContentBlocksArea blocks={page.contentBlocks} />
      </OverFlowContainer>
      <div className={'grid-container u-mx-0'}>
        {products &&
          products.length > 0 &&
          products.map((product, index) => {
            const hasSides = product.sides && product.sides.length > 0;
            const hasVariants =
              product.variants && Object.keys(product.variants).length > 0;

            // REFACTOR THIS FUCKING SHIT, filter out variants with no price. [0].prices
            const isBuffe = !!product.minOrder && !!product.maxOrder;
            const variants = Object.keys(product.variants);
            const isStandard = variants.findIndex(
              variant => variant.toLowerCase() === 'standard'
            );
            return hasVariants && product.sellable ? (
              <CateringCard
                id={product.id}
                key={product.id}
                title={product.name}
                label={product.label}
                singularLabel={product.singularLabel}
                description={product.description}
                storeId={storeId}
                unit={product.unit}
                variants={hasVariants && product.variants}
                hasVariants={hasVariants}
                hasSides={hasSides}
                sides={(hasSides && product.sides) || []}
                url={product.url}
                productImageUrl={product.image.url}
                isBuffe={isBuffe}
                minOrder={product.minOrder}
                maxOrder={product.maxOrder}
                sendCateringItemAddTracking={orderlines =>
                  sendCateringItemAddTracking(product, orderlines, index + 1)
                }
                defaultVariant={variants[isStandard] || variants[0]}
                setVariant={(variantType, selectedVariant) =>
                  setVariant(product.id, variantType, selectedVariant)
                }
                isAuthenticated={isAuthenticated}
                redirectToLogin={redirectToLogin}
                esalesClickTrigger={
                  product.ticket
                    ? () => ticketNotifierByClick(product.ticket)
                    : () => {}
                }
                esalesClickTriggerOnAddingToCart={
                  product.ticket
                    ? () => ticketNotifierByAddingToCard(product.ticket)
                    : () => {}
                }
                availability={product.availability}
                impressionListName={impressionListName}
                impressionListPosition={index + 1}
              />
            ) : null;
          })}
      </div>
      <OverFlowContainer>
        <ContentBlocksArea blocks={page.bottomContentBlocks} />
      </OverFlowContainer>
    </>
  );
}

CateringCategoryPage.propTypes = {
  getProductsByCategory: PropTypes.func,
  products: PropTypes.array,
  id: PropTypes.number,
  catering: PropTypes.object,
  storeId: PropTypes.number,
  redirectToLogin: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

CateringCategoryPage.required = [
  (state, params) => {
    return cateringActions.getCateringProductsByCategory(params.id);
  }
];

const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    catering: getCategoryCateringProducts(state),
    title: breadCrumbSelector(state, props),
    isAuthenticated: authenticatedSelector(state),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.product.fetching
  };
};

const mapDispatchToProps = {
  getPageById: pageActions.getPageById,
  getCateringProductsByCategory: cateringActions.getCateringProductsByCategory,
  redirectToLogin
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CateringCategoryPage)
);
