import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../../components/Link';
import './ContentView.scss';
import Pagination from '../../../components/Pagination';

const ContentView = ({ items, requestMoreContent, pagination }) => {
  // Suggestion: we could add the indexed body from each article in a toggleable window maybe.. We get the data already..
  const categoryTypes = [
    'bagpage',
    'cateringcategorypage',
    'recipecategorypage',
    'productcategorypage'
  ];

  return (
    <div className="c-searchresultcontent">
      {items.map((item, i) => {
        let prefix = '';
        const hasPrefix = categoryTypes.includes(item.type);

        if (
          hasPrefix &&
          item.type === 'productcategorypage' &&
          item.parentname
        ) {
          prefix = `${item.parentname} > `;
        }
        if (hasPrefix && item.type === 'recipecategorypage') {
          prefix = `Recept > `;
        }
        if (hasPrefix && item.type === 'cateringcategorypage') {
          prefix = `Catering > `;
        }
        if (hasPrefix && item.type === 'bagpage') {
          prefix = `Matkassar > `;
        }

        return (
          <div key={i} className="c-searchresultcontent__item">
            <Link to={item.url}>
              {prefix}
              {item.name}
            </Link>
          </div>
        );
      })}
      {items?.length > 0 && pagination && 
          <Pagination
          fetchPage={requestMoreContent}
          pageCount={pagination?.pageCount}
          pageIndex={pagination?.pageIndex}
          totalCount={pagination?.totalCount}
          itemsPerPage={pagination.pageSize}
          />
      }
    </div>
  );
};

ContentView.propTypes = {
  items: PropTypes.array,
  requestMoreContent: PropTypes.func,
  pagination: PropTypes.shape({
    totalCount: PropTypes.number
  })
};

export default ContentView;
