import RichTextGrid from './RichTextGrid';
import RecipeArticleBlock from './RecipeArticleBlock';
import ProductArticleBlock from './ProductArticleBlock';
import CateringArticleBlock from './CateringArticleBlock';
import RecommendationBlock from './RecommendationBlock';
import TeaserGrid from './TeaserGrid';
import ResponsiveImage from './ResponsiveImage';
import YouTubeBlock from './YoutubeBlock';
import VideoBlock from './Videoblock';
import TextWithImage from './TextWithImage';
import FavouritesBlock from './FavouritesBlock';
import MostBoughtProductsBlock from './MostBoughtProductsBlock';
import LoginBlock from './LoginBlock';
import FormBlock from './FormBlock';
import TopSellersBlock from '../components/ProductPanels/ProductTopSellers';
import ScriptBlock from './ScriptBlock';
import StoreBlock from './StoreBlock';
import InfoPlaceholderBlock from './InfoPlaceholderBlock';
import MapPlaceholderBlock from './MapPlaceholderBlock';
import OpeningHoursPlaceholderBlock from './OpeningHoursPlaceholderBlock';

export default {
  RichTextGrid,
  RecipeArticleBlock,
  ProductArticleBlock,
  CateringArticleBlock,
  RecommendationBlock,
  TeaserGrid,
  ResponsiveImage,
  YouTubeBlock,
  VideoBlock,
  TextWithImage,
  FavouritesBlock,
  MostBoughtProductsBlock,
  LoginBlock,
  FormBlock,
  TopSellersBlock,
  ScriptBlock,
  StoreBlock,
  InfoPlaceholderBlock,
  MapPlaceholderBlock,
  OpeningHoursPlaceholderBlock
};
