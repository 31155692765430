import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as pageActions from '../../actions/page';
import * as bagActions from '../../actions/bag';
import { makeBagEnhancerSelector } from '../../selectors/bag/bagSelectors';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import FoodbagCategory from '../../modules/FoodbagCategory/FoodbagCategory';

class BagFolderPage extends Component {
  state = {
    errorPage: false,
    errorBags: false
  };

  componentDidMount() {
    const { bags, page, id } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }

    if (!bags || bags.length <= 0) {
      this.getBagsContent();
    }
  }

  getPageContent = () => {
    const { id, getPageById } = this.props;
    this.setState({
      errorPage: false
    });
    getPageById(id).catch(err => {
      console.error('getPageById failed', err); // eslint-disable-line
      this.setState({
        errorPage: true
      });
    });
  };

  getBagsContent = () => {
    const { getAllBags } = this.props;
    this.setState({
      errorBags: false
    });
    getAllBags().catch(err => {
      console.error('getAllBags failed', err); // eslint-disable-line

      this.setState({
        errorBags: true
      });
    });
  };

  render() {
    const { title } = this.props;
    const { errorBags, errorPage } = this.state;

    return (
      <Fragment>
        <PageTitle {...title} />
        <FoodbagCategory
          {...this.props}
          errorPage={errorPage}
          getPageContent={this.getPageContent}
          errorBags={errorBags}
          getBagsContent={this.getBagsContent}
        />
      </Fragment>
    );
  }
}

BagFolderPage.propTypes = {
  title: PropTypes.string,
  bags: PropTypes.object,
  page: PropTypes.object,
  id: PropTypes.number,
  getAllBags: PropTypes.func,
  getPageById: PropTypes.func
};

BagFolderPage.required = [
  (state, params, noCache) =>
    pageActions.getPageById(params.id, state.router.location.search, noCache, params.userSiteId),
  () => bagActions.getAllBags()
];

const mapStateToProps = (state, props) => {
  const bagSelector = makeBagEnhancerSelector();
  return {
    title: breadCrumbSelector(state, props),
    page: pageSelector(state, props),
    id: pageIdSelector(state, props),
    bags: bagSelector(state),
    fetching: state.bag.fetching
  };
};

const mapDispatchToProps = {
  getAllBags: bagActions.getAllBags,
  getPageById: pageActions.getPageById
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BagFolderPage)
);
