import React from 'react';
import ContentBlocksArea from '../ContentBlocksArea';
import Image from '../Image';
import ProgressivePicture from '../ProgressivePicture';
import './PromotionHeader.scss';
import { EpiserverImage } from '../../types/episerver/image';

interface IPromotionHeader {
  contentBlocks: any[],
  topImage: EpiserverImage,
  promoImage: EpiserverImage,
  headline: string,
  description: string
}

const PromotionHeader : React.FC<IPromotionHeader> = ({
  contentBlocks,
  topImage,
  promoImage,
  headline,
  description
}) => {
  return (
    <div className="c-promotionheader">
      <div className="images">
        {topImage && <ProgressivePicture largeImage={topImage} />}
        {promoImage && (
          <Image
            className="bag"
            src={promoImage.url}
            width={259}
            height={340}
            alt={''}
            title={''}
          />
        )}
      </div>
      <h1>{headline}</h1>
      <div className="intro">{description}</div>
      <ContentBlocksArea blocks={contentBlocks} />
    </div>
  );
};

export default PromotionHeader;
