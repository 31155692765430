import {
  FETCH_BAGS_REQUESTED,
  FETCH_BAGS_RECEIVED,
  FETCH_BAGS_FAILED,
  FETCH_BAG_REQUESTED,
  FETCH_BAG_RECEIVED,
  FETCH_BAG_FAILED,
  FETCH_BAGVARIANTWEEK_REQUESTED,
  FETCH_BAGVARIANTWEEK_RECEIVED,
  FETCH_BAGVARIANTRECIPES_RECEIVED,
  FETCH_BAGVARIANTWEEK_FAILED,
  FETCH_BAGVARIANTCURRENTWEEK_REQUESTED,
  FETCH_BAGVARIANTCURRENTWEEK_RECEIVED,
  FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED,
  FETCH_BAGVARIANTCURRENTWEEK_FAILED,
  BagsState
} from '../../types/reducers/bag';

const initialState : BagsState = {
  fetching: false,
  bags: [],
  bagVariant: {
    variant: null,
    recipes: [],
    homeList: [],
    homeListIngredients: [],
    error: null,
    fetching: false
  },
  currWeekBagVariant: {
    variant: null,
    recipes: [],
    homeList: [],
    homeListIngredients: [],
    error: null,
    fetching: false
  },
  error: null
};

const fetching = (state, action) => ({
  ...state,
  fetching: action.fetching
});

const error = (state, action) => ({
  ...state,
  error: action.error,
  fetching: false
});


export default function bagReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AssortmentsActionTypes
) : BagsState {
  switch (action.type) {
    case FETCH_BAGS_REQUESTED: 
      return fetching(state, action)
    case FETCH_BAG_REQUESTED: 
      return fetching(state, action)
    case FETCH_BAGVARIANTWEEK_REQUESTED: {
      return {
        ...state,
        bagVariant: {
          ...state.bagVariant,
          fetching: true
        }
      };
    }
    case FETCH_BAGVARIANTCURRENTWEEK_REQUESTED: {
      return {
        ...state,
        currWeekBagVariant: {
          ...state.currWeekBagVariant,
          fetching: true
        }
      };
    }
    case FETCH_BAGS_FAILED: 
      return error(state, action)
    case FETCH_BAG_FAILED: 
      return error(state, action)
    case FETCH_BAGVARIANTWEEK_FAILED: {
      return {
        ...state,
        bagVariant: {
          ...initialState.bagVariant,
          fetching: false,
          error: action.error
        }
      };
    }
    case FETCH_BAGVARIANTCURRENTWEEK_FAILED: {
      return {
        ...state,
        currWeekBagVariant: {
          ...initialState.currWeekBagVariant,
          fetching: false, 
          error: action.error
        }
      };
    }
    case FETCH_BAG_RECEIVED: {
      return {
        ...state,
        fetching: false,
        bags: state.bags.concat(action.payload)
      };
    }
    case FETCH_BAGS_RECEIVED: {
      return {
        ...state,
        fetching: false,
        bags: action.payload
      };
    }
    case FETCH_BAGVARIANTWEEK_RECEIVED: {
      return {
        ...state,
        bagVariant: {
          ...state.bagVariant,
          variant: action.variant,
          recipes: action.recipes,
          homeList: action.homeList,
          homeListIngredients: action.homeListIngredients,
          error: null,
          fetching: false
        }
      };
    }
    case FETCH_BAGVARIANTCURRENTWEEK_RECEIVED: {
      return {
        ...state,
        currWeekBagVariant: {
          ...state.currWeekBagVariant,
          variant: action.variant,
          recipes: action.recipes,
          homeList: action.homeList,
          homeListIngredients: action.homeListIngredients,
          error: null,
          fetching: false
        }
      };
    }
    case FETCH_BAGVARIANTRECIPES_RECEIVED: {
      return {
        ...state
      };
    }
    case FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
}
