import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle } from '../../components/PageTitle';
import FoodbagCategory from '../../modules/FoodbagCategory/FoodbagCategory';

const BagCategoryPage = props => (
  <Fragment>
    <LinkablePageTitle {...props.title} />
    <FoodbagCategory {...props} />
  </Fragment>
);

const mapStateToProps = (state, props) => ({
  title: breadCrumbSelector(state, props),
  id: pageIdSelector(state, props),
  fetching: state.bag.fetching
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(BagCategoryPage)
);
