import { createSelector } from 'reselect';
import { activeStoreNoSelector } from '../assortments';
import { BagVariant, BagPrice, Bag } from '../../types/xim/bag';
import { AppState } from '../../reducers';

const getBags = (state: AppState) => state.bag.bags;
const getBlockBags = (_, bags : Bag[]) => bags;

export const determinePrice = (prices : BagPrice[], defaultPrice: BagPrice | null, storeNumber: string | number | null = null) => {
  const defPrices = prices || [];
  const price = storeNumber
  ? defPrices.find(p => p.storeNumber === storeNumber) || null
  : defaultPrice || null;

  return price
};

export const minMaxVariants = (variants : BagVariant[]) => {
  let minMeals, maxMeals, minPortions, maxPortions;

  for (let i = 0, len = variants.length; i < len; i++) {
    let v = variants[i].meals;
    minMeals = !minMeals || v < minMeals ? v : minMeals;
    maxMeals = !maxMeals || v > maxMeals ? v : maxMeals;
  }
  for (let i = 0, len = variants.length; i < len; i++) {
    let v = variants[i].portions;
    minPortions = !minPortions || v < minPortions ? v : minPortions;
    maxPortions = !maxPortions || v > maxPortions ? v : maxPortions;
  }

  return {
    minMeals,
    maxMeals,
    minPortions,
    maxPortions
  };
};


const determineVariantPrices = (variants : BagVariant[], storeNumber: string | number | null) => {
  const newVariants = variants.map(variant => {
    return {
      ...variant,
      price: determinePrice(variant.prices, (variant.prices ? variant.prices[0] : null), storeNumber) || {
        storeNumber: storeNumber,
        currentPrice: 0,
        ordinaryPrice: 0
      }
    };
  });
  return newVariants;
};

const bagDefaults = (bag: Bag) => {
  return {
    ...bag
  };
};

export const makeBagEnhancerSelector = (selector = getBags) =>
  createSelector([selector, activeStoreNoSelector], (bags: Bag[]) => {
    const extendedBags = bags.map(bag => bagDefaults(bag));
    return bags ? extendedBags : [];
  });

export const allBagsSelector = createSelector([getBags, activeStoreNoSelector], (bags: Bag[]) => {
    const extendedBags = bags.map(bag => bagDefaults(bag));
    return bags ? extendedBags : [];
  });

export const makeBagBlockEnhancerSelector = createSelector(
  [getBlockBags],
  (bags: Bag[]) => {
    const extendedBags = bags.map(bag => bagDefaults(bag));
    return bags ? extendedBags : [];
  }
);
