import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import RichText from '../../containers/RichText';
import Image from '../../components/Image';
import ProgressivePicture from '../../components/ProgressivePicture';
import BagVariants from './BagVariants';
import WeekPagination from '../../components/WeekPagination';
import { CenteredSpinner } from '../../components/Spinner/Spinner';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import Grid from '../../components/Grid';
import RecipeCard from '../../components/RecipeCard';
import getISOWeek from 'date-fns/getISOWeek';
import HomeList from '../../containers/HomeList';
import { getNextBagDeliveryDate } from '../../lib/delivery';
import { sortedBagVariants, setImage } from '../../lib/bag';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import queryParams from '../../lib/queryParams';
import { viewBag } from '../../store/middleware/analytics';
import './Foodbag.scss';
import { toggleModalActive } from '../../actions/assortments';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { openDeliveryPicker } from '../../actions/sideModal';


const impressionListName = 'Bag Weekly Recipes';
class Foodbag extends Component {
  constructor(props) {
    super(props);
    const sortedBags = sortedBagVariants(this.props.bag.variants);
    const parsed = queryString.parse(props.location.search);
    this.state = {
      variants: sortedBags,
      selectedVariant: parsed.id
        ? sortedBags.find(v => v.id === parsed.id)
        : sortedBags[0],
      selectedDate: getNextBagDeliveryDate(),
      variantsBag: props.bagVariant,
      ga4TrackingSent: false
    };
  }

  componentDidMount() {
    const { bag } = this.props;
    const { selectedVariant } = this.state;
    this.syncAndFetch();
    viewBag(bag, selectedVariant ? selectedVariant : bag.id);
  }
  componentDidUpdate(prevProps, prevState) {
    const { bagVariant } = this.props;

    if (bagVariant !== prevProps.bagVariant) {
      this.setState({
        variantsBag: bagVariant
      });
    }
    if (this.props.storeId !== prevProps.storeId) {
      this.syncAndFetch();
    }
    if (
      prevProps.bagVariant.recipes !== bagVariant.recipes &&
      bagVariant.recipes.length > 0
    ) {
      try {
        if (!this.state.ga4TrackingSent) {
          const ga4ImpressionItems = bagVariant.recipes.map((recipe, index) => {
            let recipePrice = 0;

            if (this.props.storeId) {
              const preview = recipe.previews.find(
                x => x.storeNumber == this.props.storeId
              );
              if (preview) recipePrice = preview.minimumPrice || 0;
            }

            return {
              item_id: recipe.id,
              item_name: recipe.name,
              item_brand: recipe.source || 'City Gross',
              item_category:
                '/recept' +
                  recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
                undefined,
              item_variant: recipe.id,

              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: recipePrice,
              quantity: 1,
              discount: 0
            };
          });

          ga4.viewItemList({
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });

          this.setState({
            ga4TrackingSent: true
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  syncAndFetch() {
    const { location, bag } = this.props;
    const parsed = queryString.parse(location.search);
    const selectedVariant = parsed.id
      ? this.state.variants.find(v => v.id === parsed.id)
      : null;
    const isValidDate = parsed.t ? !isNaN(new Date().setTime(parsed.t)) : false;
    this.setState(state => ({
      selectedVariant: selectedVariant
        ? selectedVariant
        : state.variants[0] || bag.variants[0],
      selectedDate: isValidDate ? new Date(+parsed.t) : state.selectedDate
    }));
    this.fetchNewVariant(
      selectedVariant
        ? selectedVariant.id
        : this.state.variants[0]?.id || bag.variants[0]?.id,
      isValidDate ? new Date(+parsed.t) : this.state.selectedDate
    );
  }

  updateQuery(date, id) {
    const { location, history } = this.props;
    const parsed = queryString.parse(location.search);
    parsed.id = id;
    history.replace(`${location.pathname}?${queryParams(parsed)}`);
  }

  fetchNewVariant = (bagId, date) => {
    this.setState({
      selectedDate: date,
      ga4TrackingSent: false
    });

    const { getBagVariantWeek } = this.props;
    const week = getISOWeek(date);
    const year = date.getFullYear();
    getBagVariantWeek(bagId, year, week);
  };

  handleSelectVariant = variant => {
    const { variants, selectedDate } = this.state;
    const foundVariant = variants.find(v => v.value === variant);

    viewBag(this.props.bag, foundVariant);
    this.setState({ selectedVariant: foundVariant });
    this.updateQuery(selectedDate, foundVariant.id);

    this.props.getBagVariantWeek(
      foundVariant.id,
      selectedDate.getFullYear(),
      getISOWeek(selectedDate)
    );
  };

  render() {
    const {
      addToCart,
      title,
      bag,
      bagVariant,
      topImage,
      contentArea1,
      contentArea2,
      contentArea3,
      isAuthenticated,
      setRecipeFavorite,
      removeFavorite,
      redirectToLogin,
      isFruitBag,
      openModal,
      location,
      deliveryOptions,
      toggleInfoModalActive,
      storeId,
      toggleAssortmentPicker,
      openDeliveryPicker
    } = this.props;
    const { variants, selectedVariant, selectedDate } = this.state;

    const setRecipeFavoriteFn = isAuthenticated
      ? setRecipeFavorite
      : redirectToLogin;
    const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;
    const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;

    const selectedWeek = getISOWeek(selectedDate);
    const date = new Date();
    date.setYear(date.getFullYear() + 1);
    const priceValidUntil = date.toLocaleDateString('sv-SE');
    const fromDateCond = bag.fromDate ? date >= new Date(bag.fromDate) : true;
    const toDateCond = bag.toDate ? date <= new Date(bag.toDate) : true;
    const isSellable = bag.sellable && fromDateCond && toDateCond;

    const image = setImage(bag.images, 0);

    return (
      <div
        className="c-foodbag"
        data-product-id={selectedVariant?.id}
        itemType={'http://schema.org/Product'}
        itemScope
      >
        <meta itemProp="productID" content={selectedVariant?.id} />
        <meta itemProp="sku" content={selectedVariant?.id} />
        <meta itemProp="brand" content={'Citygross - matkassar'} />
        <meta
          itemProp="url"
          content={
            'https://www.citygross.se' +
            location.pathname +
            `?id=${selectedVariant?.id}`
          }
        />
        <meta itemProp="description" content={bag.description} />
        <meta itemProp="item_group_id" content={bag.id} />

        <PageTitle {...title} />
        <div className="c-foodbag__head">
          <div className="c-foodbag__images">
            {topImage && (
              <ProgressivePicture
                largeImage={topImage}
                maxWidth={{
                  large: 1312,
                  small: 767
                }}
              />
            )}
          </div>
          <div className={'c-foodbag__content-container'}>
            <div className={'c-foodbag__left-column'}>
              <h1 itemProp="name">{bag.name}</h1>
              <div className="c-foodbag__intro">
                <RichText text={bag.description} />
              </div>
            </div>
            <div className={'c-foodbag__right-column'}>
              <link
                itemProp="image"
                href={'https://www.citygross.se' + image.url}
              />
              <Image
                className="c-foodbag__bag"
                src={image.url}
                alt={image.alt}
                title={image.alt}
              />
            </div>
          </div>
        </div>

        <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <link itemProp="availability" href="http://schema.org/InStock" />
          <link
            itemProp="itemCondition"
            href="http://schema.org/NewCondition"
          />
          <meta itemProp="priceValidUntil" content={priceValidUntil} />
          <meta
            itemProp="price"
            content={`${selectedVariant?.price?.currentPrice || bag.fromPrice}`}
          />
          <meta itemProp="priceCurrency" content="SEK" />
        </div>

        {selectedVariant && (
          <BagVariants
            addToCart={addToCartFn}
            bag={bag}
            variants={variants}
            selectedVariant={selectedVariant}
            handleSelectVariant={this.handleSelectVariant}
            isFruitBag={isFruitBag}
            openModal={openModal}
            deliveryOptions={deliveryOptions}
            toggleInfoModalActive={toggleInfoModalActive}
            isSellable={isSellable}
            storeId={storeId}
          />
        )}

        <div className="c-foodbag__contentarea">
          <ContentBlocksArea blocks={contentArea1} />
        </div>
        {!isFruitBag && (
          <div className="c-foodbag__recipes">
            <h2>Recept i kassen - v{selectedWeek}</h2>
            <WeekPagination
              dateToday={getNextBagDeliveryDate()}
              onPaginate={date =>
                this.fetchNewVariant(selectedVariant?.id, date)
              }
              selectedWeek={selectedWeek}
            />
            {bagVariant.fetching ? (
              <CenteredSpinner dark />
            ) : (
              <div>
                {bagVariant.error && <div>{bagVariant.error}</div>}
                <Grid>
                  {bagVariant.recipes.map((recipe, index) => (
                    <div key={recipe.id} className="c-foodbag__card">
                      <RecipeCard
                        minimal
                        id={recipe.id}
                        weightWatchersGreenPoints={
                          recipe.weightWatchersGreenPoints
                        }
                        weightWatchersBluePoints={
                          recipe.weightWatchersBluePoints
                        }
                        weightWatchersPurplePoints={
                          recipe.weightWatchersPurplePoints
                        }
                        weightWatchersPoints={recipe.weightWatchersPoints}
                        favorite={recipe.favorite}
                        cookingTime={recipe.cookingTime}
                        url={recipe.url}
                        name={recipe.name}
                        image={recipe.image}
                        setRecipeFavorite={setRecipeFavoriteFn}
                        removeFavorite={removeFavoriteFn}
                        portions={selectedVariant?.portions}
                        ticket={recipe.ticket}
                        impressionListName={impressionListName}
                        impressionListPosition={index + 1}
                      />
                    </div>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        )}
        <div className="c-foodbag__contentarea">
          <ContentBlocksArea blocks={contentArea2} />
        </div>
        {bagVariant && (
          <HomeList
            products={this.state.variantsBag.homeList || []}
            nonBoundProducts={this.state.variantsBag.homeListIngredients || []}
            storeId={storeId}
            noStoreAction={() => /* toggleAssortmentPicker(true) */ openDeliveryPicker()}
          />
        )}
        <div className="c-foodbag__contentarea u-mt-20">
          <ContentBlocksArea blocks={contentArea3} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: selectCurrentAssortment(state)
});

const mapDispatchToProps = {
  toggleAssortmentPicker: toggleModalActive,
  openDeliveryPicker
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Foodbag)
);
