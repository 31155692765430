import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProductById } from '../../actions/product';
import { authenticatedSelector } from '../../selectors/user';
import ProductSingle from '../../modules/ProductSingle/ProductSingle';
import LoadBlock from '../../components/LoadingBlock';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { allProductsWithDefaults } from '../../selectors/product/productSelectors';

class ProductSinglePage extends Component {
  componentDidMount() {
    this.getProduct();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (prevProps.id !== id) {
      this.getProduct();
    }
  }

  componentWillUnmount() {
    if (this.props && this.props.isFetching) {
      return false;
    }
  }

  getProduct = () => {
    const { product, isFetching, getProductById, id } = this.props;
    if (!product && !isFetching) {
      getProductById(id);
    }
  };

  render() {
    const { product, storeId, isFetching, isAuthenticated } = this.props;
    return product ? (
      <ProductSingle
        product={product}
        currentBreadCrumb={{ name: product.name, link: { url: product.url } }}
        storeId={storeId}
        isFetching={isFetching}
        isAuthenticated={isAuthenticated}
        isFlyout={this.props.isFlyout}
        />
    ) : (
      <LoadBlock />
    );
  }
}

ProductSinglePage.required = [
  (state, { params }) => {
    return getProductById(params.productId);
  }
];

const mapStateToProps = (state, props) => {
  const productId = props.match.params.productId;

  return {
    id: productId,
    storeId: selectCurrentAssortment(state),
    isFetching: state.product.fetching,
    isAuthenticated: authenticatedSelector(state),
    product: allProductsWithDefaults(state)?.find(
      product => product.id?.toLowerCase() === productId?.toLowerCase()
    )
  };
};

const mapDispatchToProps = {
  getProductById,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSinglePage)
);
