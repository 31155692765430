import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  CART_LOADING,
  CART_RECEIVED,
  CART_SYNC,
  CART_REPLACE,
  CART_ERROR,
  CART_REQUESTED,
  CLEAR_ITEMS,
  SET_SELECTED_SLOT_ID,
  REPLACEMENT_MODE_ENTERED,
  REPLACEMENT_MODE_EXITED,
  DELETE_USABLE_CARTS,
  UPDATE_LOWEST_PICKING_FEE
} from '../../types/reducers/cart';
import makeReducer from '../makeReducer';
import itemReducer from './itemsReducer';
import recipeReducer from './recipeReducer';
import bagReducer from './bagsReducer';
import discountReducer from './discountReducer';
import { splitByType } from '../../lib/cart';
import cateringReducer from './cateringReducer';

export const initialState = {
  id: '',
  store: null,
  totalAmount: 0,
  totalDiscount: 0,
  totalTaxAmount: 0,
  loading: false,
  refreshing: false,
  isReplacement: false,
  replacementForOrderId: null,
  selectedSlotId: null,
  lowestPickingFee: null,
  highestPickingFee: null,
  lowestPickingFeeLimit: null,
  originalPickingFee: null
};

const sync = (state, { payload }) => {
  const { items, recipes, ...rest } = splitByType(payload.cart);

  return {
    ...state,
    ...rest,
    loading: false,
    refreshing: false
  };
};

const ACTION_HANDLERS = {
  [CART_RECEIVED]: sync,
  [CART_SYNC]: sync,
  // For meta CART_SYNC & CART_REPLACE behaves the same, difference is in the item & recipe reducer
  [CART_REPLACE]: sync,
  // Just record the error, TODO handle it maybe?
  [CART_ERROR]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  },
  [CART_REQUESTED]: (state, action) => {
    return {
      ...state,
      loading: true
    };
  },
  [CART_LOADING]: (state, action) => {
    return {
      ...state,
      refreshing: action.refreshing
    };
  },
  [REPLACEMENT_MODE_ENTERED]: (state, action) => {
    return {
      ...state,
      isReplacement: true
    };
  },
  [REPLACEMENT_MODE_EXITED]: (state, action) => {
    return {
      ...state,
      isReplacement: false
    };
  },
  [SET_SELECTED_SLOT_ID]: (state, action) => {
    return {
      ...state,
      selectedSlotId: action.selectedSlotId
    };
  },
  [LOGOUT_SUCCESS]: (state, action) => initialState,
  [CLEAR_ITEMS]: (state, action) => {
    return {
      ...initialState,
      store: state.store
    };
  },
  [DELETE_USABLE_CARTS]: (state, action) => {
    return {
      ...initialState,
      store: state.store
    };
  },
  [UPDATE_LOWEST_PICKING_FEE]: (state, action) => {
    return {
      ...state,
      lowestPickingFee: action.lowestPickingFee,
      highestPickingFee: action.highestPickingFee,
      lowestPickingFeeLimit: action?.lowestPickingFeeLimit,
      originalPickingFee: action?.originalPickingFee
    };
  }
};

export const metaReducer = makeReducer(ACTION_HANDLERS, initialState);

export default combineReducers({
  meta: metaReducer,
  items: itemReducer,
  recipes: recipeReducer,
  bags: bagReducer,
  cateredMeals: cateringReducer,
  discount: discountReducer
});
