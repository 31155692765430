/* eslint-disable import/namespace */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Logo, Nav, NavButton } from '@citygross/components';
import { Icons } from '@citygross/icons';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import NewSearch from '../../../modules/Search/NewSearch';
// @ts-ignore
import LogoSmall from '../assets/logo_small.svg';
import UserLoginState from '../UserLoginState';
import Shopping from '../../../containers/Shopping';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchQuickSearch,
  selectIsSearchOpen,
  selectNewSearchLoading,
  selectNewSearchMeta,
  selectNewSearchNeedle,
  setNeedle,
  setKeepOpen,
  setOpenSearch
} from '../../../slices/searchSlice';
import { useWindowSize } from '@citygross/utils';
import debounce from 'lodash.debounce';
import { authenticatedSelector } from '../../../selectors/user';
import './TopNav.scss';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal';
import Skeleton from 'react-loading-skeleton';
import { BodyText, TextTypes } from '@citygross/typography';
import { HEADER_BREAKPOINT, HEADER_MAX_WIDTH } from '../Header';
import { sendSearchFieldEvent } from '../../../lib/analytics/analytics';

type TNavTop = {
  id?: number;
  name: string;
  pageId?: number;
  path: string;
  type?: string;
};

type TLocation = {
  pathname: string;
  search: string;
  hash: string;
  key: string;
  query: {
    Q?: string;
    page?: string;
    store?: string;
  };
};

type TTopNav = {
  navTop: TNavTop[];
  location?: TLocation;
  storeId?: string;
  notificationStatus: any;
  notificationHeight: any;
};

const TopNav = ({
  navTop,
  storeId,
  notificationHeight,
  notificationStatus
}: TTopNav) => {
  const needle = useSelector(selectNewSearchNeedle);
  const loading = useSelector(selectNewSearchLoading);
  const meta = useSelector(selectNewSearchMeta);
  const searchIsOpen = useSelector(selectIsSearchOpen);
  const isAuthenticated = useSelector(authenticatedSelector);
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputMobileRef = useRef<HTMLInputElement>(null);
  const [localSearch, setLocalSearch] = useState(needle || '');

  useEffect(() => {
    if (searchIsOpen && document?.body) {
      document.body.style.overflowY = 'scroll';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'relative';
    }
  }, [searchIsOpen]);

  const focusSearch = useCallback(() => {
    if (width < 991) {
      searchInputMobileRef?.current?.focus();
    } else {
      searchInputRef?.current?.focus();
    }
  }, [width]);

  const openSearch = () => {
    dispatch(setOpenSearch(true));
    focusSearch();
  };

  const closeSearch = () => {
    dispatch(setOpenSearch(false));
  };

  const emptyField = () => {
    dispatch(setNeedle(''));
    focusSearch();
    setLocalSearch('');
  };

  const onSubmit = () => {
    const { totalCount } = meta;
    if (totalCount > 0 && !loading) {
      history.push(
        `/sok?Q=${encodeURIComponent(needle)}&store=${storeId}&page=0`
      );
      searchInputMobileRef?.current && searchInputMobileRef?.current?.blur();
      searchInputRef?.current && searchInputRef?.current?.blur();
      closeSearch();
    }
  };

  const onInputChange = e => {
    setLocalSearch(e.target.value);
    dispatch(setNeedle(e.target.value));

    if (e.target.value?.length > 0) {
      dispatch(setOpenSearch(true));
    }
  };

  const doSearch = query => {
    dispatch(
      fetchQuickSearch(
        'q=' + encodeURIComponent(query) + `&store=${storeId ?? ''}`
      )
    );
  };

  const debouncedSearch = useCallback(
    debounce(doSearch, 500, { trailing: true }),
    [storeId]
  );

  useEffect(() => {
    debouncedSearch(needle);
  }, [needle]);

  const navTopLinks = [
    ...navTop?.filter(link => link.type !== 'BagFolderPage'),
    {
      path: '/matvaror/veckans-erbjudande',
      name: 'Erbjudanden',
      id: 'erbjudanden'
    },
    {
      path: '/butiker',
      name: 'Butiker',
      id: 'butiker'
    }
  ];
  const prevNeedleRef = useRef<string>('');
  const sendSearchTracking = useCallback(() => {
    if (prevNeedleRef.current !== needle) {
      sendSearchFieldEvent(needle || 'none');
      prevNeedleRef.current = needle;
    }
  }, [prevNeedleRef, needle]);

  return (
    <Nav
      maxWidth={HEADER_MAX_WIDTH}
      mobileTopPosition={notificationHeight}
      overlayTopPosition={notificationHeight ? notificationHeight : 0}
      logo={
        <NavLink to={'/'} exact={true} key={'logo'}>
          <Logo logoImage={<img src={LogoSmall} alt="logo" />} />
        </NavLink>
      }
      onSearchBlur={sendSearchTracking}
      mainLinks={
        navTop?.length < 1
          ? Array.from(Array(5).keys()).map(key => (
              <Skeleton width={75} key={key} />
            ))
          : navTopLinks?.map(navItem => (
              <NavLink
                to={navItem.path}
                exact={navItem.path === '/'}
                key={navItem.id}
                className={'topnav-navlink'}
                activeClassName={
                  navItem.path === '/matvaror' &&
                  location?.pathname?.includes('/matvaror/veckans-erbjudande')
                    ? ''
                    : 'active'
                }
              >
                {navItem.name}
              </NavLink>
            ))
      }
      searchInputRef={searchInputRef}
      searchInputMobileRef={searchInputMobileRef}
      searchCancelLabel={
        needle && needle?.length > 0 ? (
          <span
            role="button"
            tabIndex={0}
            className="header-search__cancel"
            onClick={() => {
              emptyField();
            }}
          >
            <Icons.Cross height={16} width={16} />
          </span>
        ) : (
          <p
            onClick={() => {
              closeSearch();
            }}
            className="header-search__cancel-text"
          >
            Avbryt
          </p>
        )
      }
      searchIcon={
        <div
          className={'header-search__icon header-pointer__container'}
          onClick={() => {
            openSearch();
          }}
          tabIndex={0}
          role={'button'}
        >
          <Icons.Search
            width={
              width > HEADER_BREAKPOINT ||
              (width < HEADER_BREAKPOINT && searchIsOpen)
                ? 20
                : 24
            }
            height={
              width > HEADER_BREAKPOINT ||
              (width < HEADER_BREAKPOINT && searchIsOpen)
                ? 20
                : 24
            }
          />
        </div>
      }
      searchValue={localSearch}
      searchOnChange={e => {
        setLocalSearch(e.target.value);
        onInputChange(e);
      }}
      searchPlaceHolder={'Sök varor och recept'}
      searchOnSubmit={() => onSubmit()}
      searchIsOpen={searchIsOpen}
      onSearchClick={() => {
        openSearch();
      }}
      closeSearch={() => {
        closeSearch();
      }}
      searchResultElement={
        <NewSearch
          needle={needle}
          loading={loading}
          notificationStatus={notificationStatus}
          notificationHeight={notificationHeight}
          searchIsOpen={searchIsOpen}
          storeId={storeId}
          closeSearch={() => closeSearch()}
          rounded={width > HEADER_BREAKPOINT}
          setKeepOpen={() => {
            dispatch(setKeepOpen(true));
          }}
        />
      }
      rightContent={
        <div className={'header-content__right'}>
          <UserLoginState
            authenticated={isAuthenticated}
            closeSearch={closeSearch}
          />

          <Shopping />
          <NavButton
            // @ts-ignore
            tabIndex={0}
            color={'white'}
            icon={<Icons.Menu />}
            size={'header'}
            borderRadius={100}
            noWrap
            onClick={() => {
              closeSearch();
              dispatch(openSideModal(SideModalTabs.MENU));
            }}
            noShadow
            hoverBackground="#F1F1F1"
            width={42}
            height={42}
          >
            <BodyText size={TextTypes.TextSize.SMALL} fontWeight={'medium'}>
              {' '}
              Meny
            </BodyText>
          </NavButton>
        </div>
      }
    />
  );
};

export default TopNav;
