import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import * as pageActions from '../../actions/page';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import FullWidthPageModule from '../../modules/FullWidthPage';

class ProductFolderPage extends Component {
  state = {
    pageError: false
  };

  componentDidMount() {
    const { page, id } = this.props;

    // Dont refetch startpage if already been reloaded
    if (page.id !== id) {
      this.getPageContent();
    }
  }

  getPageContent = () => {
    const { id, getPageById } = this.props;
    this.setState({
      pageError: false
    });

    getPageById(id).catch(err => {
      console.error('getPageById failed', err);
      this.setState({
        pageError: true
      });
    });
  };

  render() {
    const { page, title } = this.props;
    const { pageError } = this.state;
    return (
      <Fragment>
        <PageTitle {...title} />
        <FullWidthPageModule
          {...page}
          title={title}
          blocks={page.contentBlocks}
          error={pageError}
          errorRetryFn={this.getPageContent}
        />
      </Fragment>
    );
  }
}

ProductFolderPage.required = [
  (state, params, noCache) => {
    return pageActions.getPageById(params.id, state.router.location.search, noCache, params.userSiteId);
  }
];

function mapStateToProps(state, props) {
  return {
    title: breadCrumbSelector(state, props),
    page: pageSelector(state, props),
    id: pageIdSelector(state, props)
  };
}

const mapDispatchToProps = {
  getPageById: pageActions.getPageById
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductFolderPage)
);
