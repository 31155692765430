import { Image } from '../types/xim/image';
import { Bag, BagVariant } from '../types/xim/bag';
const config = require('../config/config');

export const setImage = (images: Image[], type: number) => {
  const image = (images || []).find(image => image.type === type);

  return image
    ? { ...image, url: `${config.PICTURE_BASE_URL}/bags/${image.url}` }
    : { url: '', alt: '', type: 0 };
};

export const sortedBagVariants = (variants: BagVariant[]) => {
  return variants
    .filter(x => {
      const today = new Date();
      const fromCond = x.fromDate ? new Date(x.fromDate) <= today : true;
      const toCond = x.toDate ? new Date(x.toDate) >= today : true;

      const isSellable = x.visible && x.sellable && fromCond && toCond;
      return isSellable;
    })
    .sort((a, b) => {
      return a.portions - b.portions;
    })
    .map(({ portions, meals, gtin, id, prices, name }) => {
      return ({
      value: gtin,
      id,
      name,
      portions: portions,
      label: `${meals} middagar, ${portions} portioner`,
      prices
    })});
};

export const getBagByBagSmallestSize = (bag: Bag): BagVariant | null => {
  let tempBag: BagVariant | null = null;

  bag?.variants?.forEach(bag => {
    if (tempBag) {
      if (bag.meals === tempBag?.meals) {
        tempBag = bag?.portions < tempBag?.portions ? bag : tempBag;
      } else {
        tempBag = tempBag && bag?.meals < tempBag?.meals ? bag : tempBag;
      }
    } else {
      tempBag = bag;
    }
  });

  return tempBag;
};

export const getBagPriceByStore = (bag: Bag, storeNumber?: string | number | null ) => {
  const smallestBag = getBagByBagSmallestSize(bag);
  const currentStoreBag = smallestBag?.prices?.find(
    bag => Number(bag.storeNumber) === Number(storeNumber)
  );
  return currentStoreBag?.currentPrice || (bag?.fromPrice ?? 0);
};