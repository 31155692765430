import React, { Component } from 'react';
import Select from '../../../components/Select';
import { Button } from '../../../components/Buttons';
import { withSelectable } from '../../../components/Selectable';
import './BagVariants.scss';
import { connect } from 'react-redux';
import {
  selectCurrentAssortment,
  selectedDeliverySettings
} from '../../../selectors/assortments';
import {openDeliveryPicker } from '../../../actions/sideModal'


const QUANTITY = { value: 1, unit: 'Piece', originalUnit: 'Piece' };


const SelectableSelect = withSelectable(Select);
export class BagVariants extends Component {
  state = {
    adding: false,
  };

  addToCartFn = () => {
    const { bag, selectedVariant, addToCart, openModal } = this.props;

    this.setState({
      adding: true
    });
    addToCart({
      gtin: selectedVariant.value,
      itemNo: selectedVariant.id,
      quantity: QUANTITY,
      name: selectedVariant.name,
      bag: bag
    })
      .then(() => {
        this.setState({
          success: true
        });
      })
      .catch(() => {
        openModal({
          title: 'Hoppsan, något gick fel!',
          body:
            'Tyvärr kan vi inte lägga till matkassen i din varukorg just nu. Kontakta kundservice om problemet kvarstår.',
          confirmLabel: 'Stäng',
          cancelLabel: null,
          onConfirmClick: () => {}
        });
      })
      .finally(() => {
        this.setState({
          adding: false
        });
        setTimeout(() => {
          this.setState({
            success: false
          });
        }, 1000);
      });
  };

  render() {
    const {
      variants,
      selectedVariant,
      handleSelectVariant,
      isFruitBag,
      deliveryOptions,
      openDeliveryPicker,
      isSellable,
      storeId
    } = this.props;
    const { currentPrice } = selectedVariant.prices[0];
    const { adding, success } = this.state;
    const hasStoreSelected =
      (storeId ) !== null || undefined;
    const selectedStorePrice = selectedVariant?.prices?.find(
      bag => Number(bag.storeNumber) === Number(storeId)
    )?.currentPrice;

    

    return (
      <div className="c-bagdetails">
        <div className="shop">
          {!isFruitBag && (
            <div className="configure">
              <SelectableSelect
                onSelect={handleSelectVariant}
                options={variants}
                defaultValue={selectedVariant.value}
              />
            </div>
          )}
          <span className="price">
            {selectedStorePrice ?? currentPrice  }
            {' kr'}
          </span>
          {deliveryOptions.canAddBags ? (
            <Button
              primary
              onClick={this.addToCartFn}
              loading={adding}
              success={success}
              disabled={
                !isSellable || (!selectedStorePrice && hasStoreSelected)
              }
            >
              Lägg i varukorg
            </Button>
          ) : (
            <Button primary onClick={() => openDeliveryPicker()}>
              Byt leveranssätt
            </Button>
          )}

          <div className="delivery">
            Köper du en matkasse idag kan den levereras tidigast om en vecka.
            <br />
            Leverans sker på måndagar.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeId: selectCurrentAssortment(state),
  deliveryOption: selectedDeliverySettings(state)
});

const mapDispatchToProps = {
  openDeliveryPicker
}

export default connect(mapStateToProps, mapDispatchToProps)(BagVariants);
