import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Cookies from 'universal-cookie';

import { LazyProgressivePicture } from '../../components/ProgressivePicture';
import './StoreBlock.scss';
import { LinkButton } from '../../components/Buttons';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { AssortmentCookie, StoreMeta } from '../../types/assortments';

const cookieStorage = new Cookies();

const StoreBlock: React.FC = () => {
  const stores = useSelector((state: AppState) => state.assortments.stores);
  const loading = useSelector(
    (state: AppState) => state.assortments.assortmentLoading
  );
  const [store, setStore] = useState<StoreMeta>();

  const assortmentValues: AssortmentCookie = cookieStorage.get(
    'assortment_values'
  );
  const userSiteId = assortmentValues?.userSiteId;

  useEffect(() => {
    if (!stores || stores?.length <= 0 || !userSiteId || loading) return;

    const userStore = stores.find(x => x.id == parseInt(userSiteId));
    if (userStore) {
      setStore(userStore);
    }
  }, [userSiteId, stores, loading]);

  const storeData = store?.populated;
  const hasImage = storeData?.newsDesktopImage && storeData?.newsMobileImage;

  return store && storeData?.newsShowBlock ? (
    <div className="b-store-block__wrapper">
      <div>
        {hasImage && (
          <LazyProgressivePicture
            largeImage={storeData?.newsDesktopImage}
            smallImage={storeData?.newsMobileImage}
            maxWidth={{
              large: 930,
              small: 767
            }}
          />
        )}
      </div>
      {storeData?.newsHeader && (
        <div
          className={classNames('b-store-block__text', {
            hasImage
          })}
        >
          <div className="b-store-block__title">{storeData?.newsHeader}</div>
          {storeData?.newsBody && (
            <div
              className="b-store-block__subtitle"
              dangerouslySetInnerHTML={{ __html: storeData?.newsBody }}
            ></div>
          )}
          {storeData?.newsUrlList && storeData?.newsUrlList.length > 0 && (
            <div className="b-store-block__cta">
              <LinkButton link={storeData?.newsUrlList[0].link} primary>
                {storeData?.newsUrlList[0].text ??
                  storeData?.newsUrlList[0].link?.url}
              </LinkButton>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default StoreBlock;
