import Cookies from 'universal-cookie';
import Cookie from 'js-cookie';
import {
  fetchClosestStoreByZip,
  fetchSites,
  fetchStoreNumberBySiteId,
  fetchStoreNumberByZipCode
} from '../../api/endpoints/delivery';
import { getStores } from '../../api/endpoints/page';
import { setUserDeliverySettings } from '../../api/endpoints/customer';
import {
  receiveStoreInformations,
  setActiveAssortment,
  toggleModalActive,
  setActiveDeliveryMethod,
  toggleAssortmentsLoading,
  toggleSitesLoading,
  toggleLastSelected
} from './sync';

import { StoreMeta, DeliveryMethods } from '../../types/assortments';
import { addYears } from 'date-fns';

import { updatePickingFee } from '../cart';
const cookieStorage = new Cookies();

export const getSitesWithMeta = () => async dispatch => {
  dispatch(toggleSitesLoading(true));

  const sitesReq = await fetchSites({ siteTypeId: 3 });
  const { selectableStores, allStores } = await getStores();
  let sites = sitesReq.data.sites;
  const siteSources: StoreMeta[] = [];
  selectableStores.forEach(site => {
    const match = sites.find(x => x.id === site.data.siteId);
    if (match) {
      siteSources.push({
        ...match,
        populated: site.data
      });
    }
  });

  dispatch(
    receiveStoreInformations(
      siteSources?.sort((a, b) => a.name?.localeCompare(b.name)),
      allStores.filter(x => x).map(s => s.data)
    )
  );
  dispatch(toggleSitesLoading(false));
};

export const setAssortment = (
  deliveryType: DeliveryMethods,
  siteId: number | string,
  zipCode?: number | string | null
) => async (dispatch, getState) => {
  dispatch(toggleAssortmentsLoading(true));

  const state = getState();

  const checkIfDeliveryIsChanged =
    state.assortments.deliveryMethod !== deliveryType;
  zipCode = zipCode ?? null;
  try {
    if (deliveryType == 'homeDelivery' && zipCode) {
      const recStores = await fetchClosestStoreByZip(zipCode?.toString());

      let storeList = state?.assortments?.stores;
      if (!state?.assortments?.stores) {
        const sitesReq = await fetchSites({ siteTypeId: 3 });

        storeList = sitesReq.data?.sites;
      }
      const store = storeList.find(
        store =>
          Number(store.storeNumber) === Number(recStores?.data?.[0]?.store)
      );
      // in case first store's not a valid store included in list *ex. hyllie
      const store2 = storeList.find(
        store =>
          Number(store.storeNumber) === Number(recStores?.data?.[1]?.store)
      );

      const data = await fetchStoreNumberByZipCode(zipCode);

      const actualStore = store || store2;
      if (data.data.grocery) {
        dispatch(
          setActiveAssortment(
            deliveryType,
            siteId,
            Number(actualStore.id),
            zipCode,
            Number(actualStore.storeNumber),
            data.provider,
            data.data
          )
        );
        dispatch(toggleLastSelected(checkIfDeliveryIsChanged));
      } else {
        // Needed for the incremental release of few stores at launch.
        const fallBackData = await fetchStoreNumberBySiteId(siteId);
        dispatch(
          setActiveAssortment(
            deliveryType,
            siteId,
            null,
            zipCode,
            Number(fallBackData.data.storeNumber),
            fallBackData.data.provider
          )
        );
        dispatch(toggleLastSelected(checkIfDeliveryIsChanged));
      }
    } else {
      const data = await fetchStoreNumberBySiteId(siteId);

      // @TODO: should probably not be hardcoded, but no other way to know which provider we are at currently.
      dispatch(
        setActiveAssortment(
          deliveryType,
          siteId,
          null,
          zipCode,
          Number(data.data.storeNumber),
          data.data.provider
        )
      );
      dispatch(toggleLastSelected(checkIfDeliveryIsChanged));
    }
  } catch (e) {
    // We need to prompt the user to choose a new assortment
    console.error('setAssortment::', e);
    dispatch(toggleModalActive(true));
    dispatch(toggleAssortmentsLoading(false));
    throw e;
  }

  // Logic below is for persisting assortment by db & localstorage
  try {
    const cacheValues = {
      ...cookieStorage.get('assortment_values'),
      deliveryType,
      userPostalCode: zipCode,
      userSiteId: siteId
    };

    // if (zipCode == null) {
    //   delete cacheValues.userPostalCode;
    // }

    cookieStorage.set('assortment_values', cacheValues, {
      path: '/',
      expires: addYears(new Date(), 2)
    });
    if (state.auth.isAuthenticated) {
      await setUserDeliverySettings({
        userDeliveryMethod: deliveryType,
        userPostalCode: zipCode?.toString(),
        userSiteId: siteId
      });
    }
    dispatch(toggleAssortmentsLoading(false));
  } catch (e) {
    dispatch(toggleAssortmentsLoading(false));
    console.error('Failed to save deliverySettings', e);
  }
};

export const changeDeliveryMethod = (
  deliveryType: DeliveryMethods
) => async dispatch => {
  try {
    const cacheValues = {
      ...cookieStorage.get('assortment_values'),
      deliveryType
    };
    cookieStorage.set('assortment_values', cacheValues, {
      path: '/',
      expires: addYears(new Date(), 2)
    });

    await setUserDeliverySettings({
      userDeliveryMethod: deliveryType
    });
  } catch (e) {
    console.error('Failed to save deliverySettings', e);
  }

  dispatch(setActiveDeliveryMethod(deliveryType));
};
