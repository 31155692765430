/* eslint-disable import/named */
// eslint-disable-next-line import/namespace
import { createAsyncThunk,  createDraftSafeSelector,  createSlice } from '@reduxjs/toolkit';
import { groupBy } from '../lib/utils';
import * as endpoints from '../api/endpoints/search';
import {makeRecipeEnhancerSelector} from '../selectors/recipe/recipeSelectors.js'
import { getCartItems } from '../selectors/cart/cartSelectorNew';
import { makeProductEnhancerSelector } from '../selectors/product/productSelectors';
import { productCombination } from '../selectors/cart/cartItemsCombiners';
import { makeBagEnhancerSelector } from '../selectors/bag/bagSelectors';
import { Location } from 'history';
import queryParams from '../lib/queryParams';
import { updateHistory } from '../lib/utils';
import qs from 'query-string';
import { TSearchState } from '../types/search/searchResult';

const initialState: TSearchState = {
  product: {
    title: 'Matvaror',
    items: [],
    count: 0
  },
  productCategoryPage: {
    title: 'MatvarorCategoryPage',
    items: [],
    count: 0
  },
  recipe: {
    title: 'Recept',
    items: [],
    count: 0
  },
  recipeCategoryPage: {
    title: 'ReceptCategoryPage',
    items: [],
    count: 0
  },
  cmscontent: {
    title: 'Sidor',
    items: [],
    count: 0
  },
  cateredmeal: {
    title: 'Catering',
    items: [],
    count: 0
  },
  cateringCategoryPage: {
    title: 'cateringCategoryPage',
    items: [],
    count: 0
  },
  bag: {
    title: 'Matkassar',
    items: [],
    count: 0
  },
  bagPage: {
    title: 'BagPage',
    items: [],
    count: 0
  },
  storePage: {
    title: 'Butiker',
    items: [],
    count: 0
  },
  meta: {},
  needle: '',
  count: 0,
  open: false,
  keepOpen: false,
  searchResult: {
    currentSearch: '',
    recipe: {
      items: []
    },
    recipeCategoryPage: {
      items: []
    },
    product: {
      items: []
    },
    productCategoryPage: {
      items: []
    },
    cmscontent: {
      items: []
    },
    cateredmeal: {
      items: []
    },
    cateringCategoryPage: {
      items: []
    },
    bag: {
      items: []
    },
    bagpage: {
      items: []
    },
    storepage: {
      items: []
    },
    category:{
      items:[]
    },
    pagination: {
      count: Infinity,
      pageIndex: 1,
      pageSize: 50,
      totalCount: Infinity
    },
    loading: false,
    activeFilter: null
  },
  loading: false,
  errorMsg: ''
};


export const fetchQuickSearch = createAsyncThunk(
    'newSearch/fetchQuickSearch',
    async (fetchQuickSearch: string, thunkAPI) => {
        try{
            const response = await endpoints.quickSearch(fetchQuickSearch)
            return response.data
        }catch(_e){
          let e:any= _e ;
       
            return thunkAPI.rejectWithValue(e.message)

        }
    }
  )

type TFetchExtendedSearch = {
  Q?: string,
  page?: string,
  store?: number,
  location?: Location,
  type?: string,
  brand?: string,
  cookingtime?: string,
  marking?: string,
  sort?: string
}
const ignoredKeys = ['page'];

const makeQuery = (params: TFetchExtendedSearch, paramObj: TFetchExtendedSearch) => {
  if(params?.brand){
    paramObj['brand'] = params.brand
  }
  if(params?.cookingtime){
    paramObj['cookingtime'] = params.cookingtime
  }
  if(params?.marking){
    paramObj['marking'] = params.marking
  }
  if (params.type) {
    paramObj['type'] = params.type;
  }
  if (params.sort) {
    paramObj['sort'] = params.sort;
  }
  return paramObj
}

export const fetchExtendedSearch = createAsyncThunk(
    'newSearch/fetchExtendedSearch',
    async (paramObj: TFetchExtendedSearch, thunkAPI) => {
      try{
        const {Q, page , store, location} = paramObj
        const locationPage = page && page !== '0' ? +page : 1;
        const q = makeQuery(paramObj, {Q: Q, page:locationPage.toString(), store: store});
        thunkAPI.dispatch(updateHistory({...q}, location));
       const pageToFetch = Math.max(0,Number(page || 1) - 1)
        const query = queryParams(makeQuery(paramObj, {Q: Q, page:pageToFetch?.toString(), store: store}));
            const response = await endpoints.search(query)
            return {...response.data, currentSearch : Q}
        }catch(_e){
          let e:any= _e ;
       
            return thunkAPI.rejectWithValue(e.message)

        }
    }
  )


type TFetchPaginatedSearch = {
  paginateParams:{

    store:number,
    page: number,
  },

  location: Location
}

export const fetchPaginatedSearch = createAsyncThunk('newSearch/fetchPageinatedSearch',
async(paramObj:TFetchPaginatedSearch, thunkAPI) => {
  const {paginateParams, location} = paramObj
  const query = qs.parse(location.search);

  const queryP = Object.assign({}, query, paginateParams);
  // updates url
  thunkAPI.dispatch(updateHistory({...queryP, page: +queryP.page + 1}, location, ignoredKeys));
  const response = await  endpoints.search(queryParams(queryP))
  return response.data;
  });




const searchSlice = createSlice({
  name: 'newSearch',
  initialState: initialState,
  reducers: {
    setNeedle : (state, action) => {state.needle = action.payload },
    setOpenSearch: (state, action) => {
      if(state.keepOpen){
        state.open = true 
        state.keepOpen = false
      }else{
        state.open =  action.payload
      }
    },
    setKeepOpen: (state, action) => {state.keepOpen = action.payload}


  },
  extraReducers: 
  (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
    .addCase(fetchQuickSearch.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true
      }
    })
    .addCase(fetchQuickSearch.fulfilled, (state, action) => {
      if(action.payload){
        return receiveSearch(state, action.payload)
      }
    })
    .addCase(fetchQuickSearch.rejected, (state, action) => {
      if(action.error.message){
        state.errorMsg = action.error.message
        state.loading = false
      }
    })
    .addCase(fetchExtendedSearch.pending, (state, action) => {
      if (state.searchResult.loading === false) {
        state.searchResult.loading = true
      }
    })
    .addCase(fetchExtendedSearch.fulfilled, (state, action) => {
      if(action.payload){

        const { data, meta, currentSearch } = action.payload;
        let selectedFilter = meta?.filters?.type?.options?.find(
          opt => opt.selected === true
        ).id;
    
        let activeSelectedFilter =meta?.filters?.type?.options?.find(
          opt => opt.selected === true
        ).id;
    
        if (selectedFilter === 'store') {
          selectedFilter = 'storepage';
        }
        return {
          ...state,
          searchResult :{
            ...state.searchResult,
            currentSearch: currentSearch,
            [selectedFilter]: {
              items: data,
              ...meta
            },
            pagination: {
              ...meta
            },
            activeFilter: activeSelectedFilter,
            loading: false
          }
        }
      }
    })
    .addCase(fetchExtendedSearch.rejected, (state, action) => {
      if(action.error.message){
        state.errorMsg = action.error.message
        state.searchResult.loading = false

      }
    })
    .addCase(fetchPaginatedSearch.pending, (state, action) => {
      if (state.loading === false) {
        state.searchResult.loading = true
      }
    })
    .addCase(fetchPaginatedSearch.fulfilled, (state, action) => {
      if(action.payload){
        const { data, meta } = action.payload;
        const selectedFilter = meta.filters.type.options.find(
          opt => opt.selected === true
        ).id;
        return {
          ...state,
          searchResult: {
            ...state.searchResult,
            [selectedFilter]: {
              items: data
            },
            pagination: {
              ...meta
            },
            activeFilter: selectedFilter,
            loading: false
          }
        };
      }
    })
    .addCase(fetchPaginatedSearch.rejected, (state, action) => {
      if(action.error.message){
        state.errorMsg = action.error.message
        state.searchResult.loading = false

      }
    })
  },
});


export const { setNeedle, setOpenSearch, setKeepOpen } =
searchSlice.actions

// @TODO: should perhaps move all selectors
export const selectIsSearchOpen = (state) => state.newSearch.open
export const selectIsSearchKeepOpen = (state) => state.newSearch.keepOpen
export const selectNewSearchNeedle = (state) => state.newSearch.needle
export const selectNewSearch = (state) => state.newSearch
export const selectNewSearchLoading = (state) => state.newSearch.loading
export const selectNewSearchResultLoading = (state) => state.newSearch.searchResult.loading
export const selectCurrentSearch = (state) => state.newSearch.searchResult.currentSearch
export const selectNewSearchMeta = (state) => state.newSearch.meta


const selectSearchProducts = state => state.newSearch.product.items;
const selectSearchRecipes = state => state.newSearch.recipe.items;
const selectSearchBags = state => state.newSearch.bag.items;
const productsWithDefault =  makeProductEnhancerSelector(selectSearchProducts);

export const selectCombinedSearchRecipes =  makeRecipeEnhancerSelector(selectSearchRecipes)

export const selectCombinedSearchProducts = createDraftSafeSelector(
  [getCartItems, productsWithDefault],
  productCombination
);
export const selectCombinedSearchBags =  makeBagEnhancerSelector(
  selectSearchBags
);

export const selectNewSearchResult = (state) => {
  const {newSearch} = state
  const {recipe, product, bag} = newSearch
  return({
    ...newSearch,
    recipe: {
      ...recipe,
      items: selectCombinedSearchRecipes(state),
    },
    product:{
      ...product,
      items: selectCombinedSearchProducts(state)
    },
    bag:{
      ...bag,
      items: selectCombinedSearchBags(state)
    }
  })
}

const getSearchPageResultProducts = state =>
  state.newSearch.searchResult.product.items;
const getSearchPageResultRecipes = state => state.newSearch.searchResult.recipe.items;
const getSearchPageResultBags = state => state.newSearch.searchResult.bag.items;

const searchResultPageProductsWithDefault = makeProductEnhancerSelector(
  getSearchPageResultProducts
);
export const selectSearchResultPageBags = makeBagEnhancerSelector(
  getSearchPageResultBags
);
export const selectSearchResultPageProducts = createDraftSafeSelector(
  [getCartItems, searchResultPageProductsWithDefault],
  productCombination
);
export const selectSearchResultPageRecipes = makeRecipeEnhancerSelector(
  getSearchPageResultRecipes
);

export const newSearchReducer = searchSlice.reducer


function receiveSearch (state, payload)  {
  const groupedItems =
   payload.data && groupBy('type', payload.data);

  const {
    product = [],
    productcategorypage = [],
    recipe = [],
    recipecategorypage = [],
    cmscontent = [],
    bag = [],
    bagpage = [],
    storepage = [],
    cateredmeal = [],
    cateringcategorypage = []
  } = groupedItems;

  return {
    ...state,
    product: {
      title: 'Matvaror',
      items: product,
      count: product.length
    },
    productCategoryPage: {
      title: 'MatvarorCategoryPage',
      items: productcategorypage,
      count: productcategorypage.length
    },
    recipe: {
      title: 'Recept',
      items: recipe,
      count: recipe.length
    },
    recipeCategoryPage: {
      title: 'ReceptCategoryPage',
      items: recipecategorypage,
      count: recipecategorypage.length
    },
    cmscontent: {
      title: 'Sidor',
      items: cmscontent,
      count: cmscontent.length
    },
    bag: {
      title: 'Matkassar',
      items: bag,
      count: bag.length
    },
    bagPage: {
      title: 'BagPage',
      items: bagpage,
      count: bagpage.length
    },
    storePage: {
      title: 'Butiker',
      items: storepage,
      count: storepage.length
    },
    cateredmeal: {
      title: 'Catering',
      items: cateredmeal,
      count: cateredmeal.length
    },
    cateringCategoryPage: {
      title: 'cateringCategoryPage',
      items: cateringcategorypage,
      count: cateringcategorypage.length
    },
    meta: {
      ...payload.meta
    },
    loading: false,
    count: payload.data.length
  };
}