import { array, bool, shape, number, string, oneOfType } from 'prop-types';

export default shape({
  bags: array,
  cancelledDate: string,
  cartId: oneOfType([number, string]),
  charges: array,
  checkoutCompletedDate: string,
  coupons: array,
  customerNo: string,
  deletedDate: string,
  deliveryFee: shape({
    amount: number,
    taxAmount: number,
    taxPercentage: number,
    totalDiscountAmount: number
  }),
  discountCodes: array,
  id: string,
  isReplacement: bool,
  items: array,
  loading: bool,
  orderId: oneOfType([number, string]),
  orderNumber: oneOfType([number, string]),
  recipes: array,
  refreshing: bool,
  replacementForOrderId: oneOfType([number, string]),
  store: number,
  storeNo: number,
  subscribtionFrequency: number,
  totalAmount: number,
  totalDiscount: number,
  totalTaxAmount: number
});
