import {
  ITEM_ADDED,
  ITEM_ADDED_WITH_DEBOUNCE,
  APPLY_QUEUED_ACTIONS,
  DEBOUNCE_START,
  ADD_TO_QUEUE,
  SET_DEBOUNCE,
  QUEUE_READY,
  PENDING_FIRST_ADD,
  CLEAR_QUEUE,
  ITEM_ADD_ERROR,
  CLEAR_ITEM_ADD_ERRORS,
  BAG_ADDED,
  BAG_REMOVED,
  BAGS_REMOVED,
  BAGSUBSCRIPTION_UPDATED,
  ITEM_REMOVED,
  ITEMS_REMOVED,
  CART_LOADING,
  CART_RECEIVED,
  CART_SYNC,
  CART_REPLACE,
  CART_ERROR,
  CART_REQUESTED,
  RECIPE_ADDED,
  RECIPE_REMOVED,
  RECIPE_QUANTITY_INCREASED,
  RECIPE_QUANTITY_DECREASED,
  SET_RECIPE_REFS,
  SET_PRODUCT_REFS,
  SET_BAG_REFS,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED,
  REPLACEMENT_MODE_ENTERED,
  REPLACEMENT_MODE_EXITED,
  CLEAR_ITEMS,
  SET_HANDLE,
  SET_SELECTED_SLOT_ID,
  DISCOUNT_APPLY,
  DISCOUNT_FAILED,
  DISCOUNT_SUCCESS,
  DISCOUNT_REMOVE,
  DELETE_USABLE_CARTS,
  REFRESH_CART,
  UPDATE_LOWEST_PICKING_FEE,
  CATERING_ADDED,
  SET_CATERED_MEALS_REFS,
  CATERING_REMOVED,
  ADD_TO_IMEDIATE_QUEUE,
  DISCOUNT_RESET
} from '../../types/reducers/cart';

import CartItem from '../../lib/cartItem';

export function addBagToCart(bagItem) {
  const { bag, ...item } = bagItem;
  return {
    type: BAG_ADDED,
    bag,
    item
  };
}

export function addCateredMealToCart(cateredMeal) {
  return {
    type: CATERING_ADDED,
    cateredMeal: cateredMeal
  };
}
export function cartLoading(loading) {
  return {
    type: CART_LOADING,
    refreshing: loading
  };
}

export function addToCartEager(product) {
  const { gtin, id } = product;
  const item = CartItem.make(gtin, { value: 1 });

  return {
    type: ITEM_ADDED_WITH_DEBOUNCE,
    item: {
      itemNo: id,
      type: 'Item',
      product,
      ...item
    }
  };
}

export function addToCart(product) {
  const time = 300;
  const thunk = addToCartEager(product);

  thunk.meta = {
    debounce: {
      time,
      key: 'k-' + product.id,
      trailing: true
    }
  };

  return thunk;
}

export function applyQueuedActions() {
  return {
    type: APPLY_QUEUED_ACTIONS
  };
}

export function debounceStart(timeoutId) {
  return {
    type: DEBOUNCE_START,
    timeoutId
  };
}

export function addToQueue(item) {
  return {
    type: ADD_TO_QUEUE,
    item
  };
}

export function setDebounce() {
  return {
    type: SET_DEBOUNCE
  };
}

export function queueReady() {
  return {
    type: QUEUE_READY
  };
}

export function pendingFirstAdd(set) {
  return {
    type: PENDING_FIRST_ADD,
    set
  };
}

export function addQueueToCart(queue, firstAdd = false) {
  return {
    type: ITEM_ADDED,
    queue,
    firstAdd
  };
}
export function addToImediateQueue(item) {
  return {
    type: ADD_TO_IMEDIATE_QUEUE,
    ...item
  };
}

export function clearQueue() {
  return {
    type: CLEAR_QUEUE
  };
}

export function itemAddError(ids) {
  return {
    type: ITEM_ADD_ERROR,
    ids
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ITEM_ADD_ERRORS
  };
}

export const addRecipeToCart = (recipe, isFlexbag, productRefs, recipeRef) => {
  return {
    type: RECIPE_ADDED,
    recipe,
    isFlexbag,
    productRefs,
    recipeRef
  };
};

export function setRecipeHandle(recipesEAN, handle) {
  return {
    type: SET_HANDLE,
    recipesEAN,
    handle
  };
}

export function removeFromCart(id) {
  return {
    type: ITEM_REMOVED,
    id
  };
}

export function removeItemsFromCart(ids, recipes) {
  return {
    type: ITEMS_REMOVED,
    ids,
    recipes
  };
}

export function removeRecipeFromCart(recipe) {
  return {
    type: RECIPE_REMOVED,
    recipe
  };
}
export function removeAllRecipesFromCart(recipeEAN) {
  return {
    type: RECIPE_REMOVED,
    recipeEAN,
    removeAll: true
  };
}

export function removeBagFromCart(id, bag, itemNo) {
  return {
    type: BAG_REMOVED,
    id,
    bag,
    itemNo
  };
}

export function removeCateredMealFromCart(id, cateredmeal) {
  return {
    type: CATERING_REMOVED,
    id,
    cateredmeal
  };
}

export function removeBagsFromCart(ids) {
  return {
    type: BAGS_REMOVED,
    ids
  };
}

export function removeBagAndRecipesFromCart(id, recipeEAN) {
  return {
    type: BAG_REMOVED,
    id,
    recipeEAN
  };
}

export function updateBagSubcription(id, subscriptionType) {
  return {
    type: BAGSUBSCRIPTION_UPDATED,
    id,
    subscriptionType
  };
}

export function increaseItemQuantity(id, quantity = 1) {
  return {
    type: QUANTITY_INCREASED,
    id,
    quantity,
    meta: {
      debounce: {
        time: 800,
        key: 'k-' + id,
        trailing: true
      }
    }
  };
}

export function decreaseItemQuantity(id, quantity = 1) {
  return {
    type: QUANTITY_DECREASED,
    id,
    quantity,
    meta: {
      debounce: {
        time: 800,
        key: 'k-' + id,
        trailing: true
      }
    }
  };
}

export function increaseRecipeQuantity(id, recipeEAN, quantity = 1) {
  return {
    type: RECIPE_QUANTITY_INCREASED,
    id,
    recipeEAN,
    quantity
  };
}

export function decreaseRecipeQuantity(id, recipeEAN, quantity = 1) {
  return {
    type: RECIPE_QUANTITY_DECREASED,
    id,
    recipeEAN,
    quantity
  };
}

export function clearCart(id) {
  return {
    type: CLEAR_ITEMS,
    id
  };
}

export function deleteUsableCarts() {
  return {
    type: DELETE_USABLE_CARTS
  };
}

export function receiveCart(cart) {
  return {
    type: CART_RECEIVED,
    payload: {
      cart
    }
  };
}

export function syncCart(cart) {
  return {
    type: CART_SYNC,
    payload: {
      cart
    }
  };
}

export function cartError(err) {
  return {
    type: CART_ERROR,
    err
  };
}

export function cartRefresh(cartId, orderId) {
  return {
    type: REFRESH_CART,
    payload: {
      cartId,
      orderId
    }
  };
}

export function requestCart() {
  return {
    type: CART_REQUESTED
  };
}

export function setRecipeRefs(recipes) {
  return {
    type: SET_RECIPE_REFS,
    recipes
  };
}

export function setProductRefs(products) {
  return {
    type: SET_PRODUCT_REFS,
    products
  };
}
export function setCateredMealsRefs(cateredMeals) {
  return {
    type: SET_CATERED_MEALS_REFS,
    cateredMeals
  };
}

export function setBagRefs(bags) {
  return {
    type: SET_BAG_REFS,
    bags
  };
}

export function setReplacementMode(isReplacement) {
  return {
    type: isReplacement ? REPLACEMENT_MODE_ENTERED : REPLACEMENT_MODE_EXITED
  };
}

export function replaceCart(cart) {
  return {
    type: CART_REPLACE,
    payload: {
      cart
    }
  };
}

export const setSelectedSlotId = selectedSlotId => {
  return {
    type: SET_SELECTED_SLOT_ID,
    selectedSlotId
  };
};

export const discountReset = () => ({
  type: DISCOUNT_RESET
});
export const discountApplying = () => ({
  type: DISCOUNT_APPLY
});

export const discountFailed = error => ({
  type: DISCOUNT_FAILED,
  payload: error
});

export const discountSuccess = () => ({
  type: DISCOUNT_SUCCESS
});

export const removeDiscount = () => ({
  type: DISCOUNT_REMOVE
});

export const updatePickingFee = (
  lowestPickingFee,
  highestPickingFee,
  lowestPickingFeeLimit,
  originalPickingFee
) => {
  return {
    type: UPDATE_LOWEST_PICKING_FEE,
    lowestPickingFee,
    highestPickingFee,
    lowestPickingFeeLimit,
    originalPickingFee
  };
};
