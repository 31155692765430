import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProductsByIds } from '../../api/endpoints/product';
import { fetchRecipeVariantPrice } from '../../api/endpoints/recipe';
import { ticketNotifierByAddingToCard } from '../../api/endpoints/notify';
import { viewRecipe } from '../../store/middleware/analytics';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { determineBase, recipeMarkings } from '../../lib/recipe';
import { pick } from '../../lib/utils';
import { RECIPE_FIELDS } from '../../lib/recipeCartItem';
import RecipeHead from './RecipeHead';
import RecipeDetails from './RecipeDetails';
import RecipeDirections from './RecipeDirections';
import RecipeIngredients from './RecipeIngredients';
import HomeList from '../../containers/HomeList';
import RichText from '../../containers/RichText';
import TipsImage from '../../components/assets/tips-fran-oss.svg';
import ProgressivePicture, {
  ImageFitTypes
} from '../../components/ProgressivePicture';
import { CenteredSpinner } from '../../components/Spinner/Spinner';
import queryString from 'query-string';
import ErrorInfo from '../../components/ErrorInfo';
import { RecipeSchemaScript } from '../../components/SchemaMeta';
import { ProductOpenGraph } from '../../components/OpenGraph';
import './RecipeSingle.scss';
import { PICTURE_BASE_URL } from '../../config/config';
import {
  toggleModalActive,
  toggleInfoModalActive
} from '../../actions/assortments';
import { determineDeliveryOptions } from '../../selectors/assortments';
import { Button } from '../../components/Buttons';
import Link from '../../components/Link';
import { openDeliveryPicker } from '../../actions/sideModal';

class RecipeSingle extends Component {
  constructor(props) {
    super(props);
    const { location } = props;

    const parsed = location.search ? queryString.parse(location.search) : {};
    const portions =
      parsed.portions && parsed.portions >= 2 && parsed.portions <= 12
        ? parsed.portions
        : this.props.recipe.quantity.value;

    this.state = {
      adding: false,
      homeList: [],
      ingredients: [],
      loadingHomelist: false,
      loadingHomelistFailed: false,
      loadingIngredients: false,
      loadingIngredientsFailed: false,
      numberOfMeals: portions,
      selectedTags: [],
      defaultSelectedTags: [],
      validVariants: [],
      selectedIngredients: [],
      unavailableArticles: [],
      unavailableHomeList: []
    };
  }

  addRecipeToCartConditional = () => {
    const { deliveryOptions, toggleInfoModalActive } = this.props;
    if (!deliveryOptions.canAddProductsOrRecipes) {
      toggleInfoModalActive(true);
    } else {
      this.addRecipeToCart();
    }
  };
  addRecipeToCart = () => {
    const {
      selectedIngredients,
      numberOfMeals,
      productRefs,
      selectedTags
    } = this.state;
    const { addRecipeToCart, recipe, openModal } = this.props;

    ticketNotifierByAddingToCard(recipe.ticket);

    const recipeToAdd = {
      name: recipe.name,
      recipeId: recipe.id,
      variantId: recipe.id,
      numberOfMeals: numberOfMeals,
      organic: selectedTags.includes('organic'),
      lactoseFree: selectedTags.includes('lactoseFree'),
      glutenFree: selectedTags.includes('glutenFree'),
      vegetarian: selectedTags.includes('vegetarian'),
      vegan: selectedTags.includes('vegan'),
      items: selectedIngredients.map(ingredient => ({
        name: ingredient.articleName,
        gtin: ingredient.gtin,
        itemNo: ingredient.articleNumber,
        quantity: {
          value: ingredient.quantity.value,
          unit: 'Piece'
        }
      }))
    };

    this.setState({
      adding: true
    });

    addRecipeToCart(recipeToAdd, false, productRefs, recipe)
      .then(() => {
        this.setState({
          success: true
        });
      })
      .catch(err => {
        this.setState({
          success: false
        });
        openModal({
          title: 'Hoppsan, något gick fel!',
          body:
            'Tyvärr kan vi inte lägga till receptet i din varukorg just nu. Kontakta kundservice om problemet kvarstår.',
          confirmLabel: 'Stäng',
          cancelLabel: null,
          onConfirmClick: () => {},
          error: err
        });
        console.error(err);
      })
      .finally(() => {
        this.setState({
          adding: false
        });

        setTimeout(() => {
          this.setState({
            success: false
          });
        }, 1000);
      });
  };

  handleOnPortionChange = val => {
    this.setState({ numberOfMeals: parseInt(val, 10) }, () => {
      if (this.props.storeId) {
        this.getCalculatedRecipe();
      }
    });
  };

  handleOnArticleCheckedToggle = event => {
    const { id } = event.target;

    this.setState(({ selectedIngredients, ingredients }) => {
      const newSelectedIngredients = selectedIngredients.filter(
        ingredient => ingredient.articleNumber !== id
      );
      if (newSelectedIngredients.length !== selectedIngredients.length) {
        return {
          selectedIngredients: newSelectedIngredients
        };
      } else {
        return {
          selectedIngredients: selectedIngredients.concat(
            ingredients.find(ingredient => ingredient.articleNumber === id)
          )
        };
      }
    });
  };

  getCalculatedRecipe = tags => {
    const { storeId, recipe } = this.props;
    const { selectedTags, numberOfMeals } = this.state;
    if (!recipe.sellable) {
      return;
    }

    this.setState({
      loadingIngredients: true,
      loadingIngredientsFailed: false,
      ingredients: []
    });

    const properTags = tags || selectedTags;
    // Refactor this, we already fetch all recipes in batch
    fetchRecipeVariantPrice(
      recipe.id,
      numberOfMeals,
      properTags.includes('organic'),
      properTags.includes('lactoseFree'),
      properTags.includes('glutenFree'),
      properTags.includes('vegetarian'),
      properTags.includes('vegan'),
      storeId
    )
      .then(({ data }) => {
        return data;
      })
      .then(data => {
        const articleGroup = data.results[0].ingredientGroups.find(
          x => x.type === 'City Gross'
        );
        const homeArticleGroup = data.results[0].ingredientGroups.find(
          x => x.type === 'Hemma'
        );

        let articleListItems = [];
        let homeListItems = [];

        for (let i = 0; i < articleGroup.ingredients.length; i++) {
          const articles = articleGroup.ingredients[i].articles;
          if (!articleGroup.ingredients[i].satisfied) {
            articleListItems.push({
              id: null,
              quantity: null,
              name: articleGroup.ingredients[i].name,
              satisfied: articleGroup.ingredients[i].satisfied
            });
          } else {
            for (const [key, value] of Object.entries(articles)) {
              articleListItems.push({
                id: key,
                quantity: value,
                name: articleGroup.ingredients[i].name,
                satisfied: articleGroup.ingredients[i].satisfied
              });
            }
          }
        }

        for (let i = 0; i < homeArticleGroup.ingredients.length; i++) {
          const articles = homeArticleGroup.ingredients[i].articles;
          if (!homeArticleGroup.ingredients[i].satisfied) {
            homeListItems.push({
              id: null,
              quantity: null,
              name: homeArticleGroup.ingredients[i].name,
              satisfied: homeArticleGroup.ingredients[i].satisfied
            });
          } else {
            for (const [key, value] of Object.entries(articles)) {
              homeListItems.push({
                id: key,
                quantity: value,
                name: homeArticleGroup.ingredients[i].name,
                satisfied: homeArticleGroup.ingredients[i].satisfied
              });
            }
          }
        }
        try {
          const products = homeListItems.filter(x => x.id).map(x => x.id);
          fetchProductsByIds(products, undefined, products.length).then(
            ({ data }) => {
              this.setState({
                homeList: data,
                unavailableHomeList: homeListItems
                  .filter(x => !x.satisfied)
                  .map(x => x.name)
              });
            }
          );
        } catch (e) {
          console.error('Could not fetch homelist: ', e);
        }

        return fetchProductsByIds(
          articleListItems.filter(x => x.id).map(x => x.id),
          null,
          articleListItems.length
        ).then(({ data }) => {
          const unavailableArticles = [];
          const articles = [];

          articleListItems.forEach(article => {
            const metaMatch = data.find(meta => meta.id === article.id);
            if (metaMatch) {
              const { price, unit } = metaMatch.prices?.find(
                x => x.storeNumber === storeId
              )?.currentPrice;
              const unitPrice =
                unit === 'KGM'
                  ? Number((price * metaMatch.netContent.value) / 1000)
                  : Number(price);

              articles.push({
                articleName: metaMatch.name,
                articleNumber: article.id,
                gtin: metaMatch.gtin,
                brand: metaMatch.brand || metaMatch.country,
                image:
                  metaMatch.images && metaMatch.images.length > 0
                    ? `${PICTURE_BASE_URL}/products/${metaMatch.images[0].url}`
                    : null,
                url: metaMatch.url,
                satisfied: article.satisfied,
                pricePerUnit: unitPrice,
                priceTotal: Number(unitPrice * Number(article.quantity)),
                // recipeQuantity: article.articleGroupQuantity,
                descriptiveSize: metaMatch.descriptiveSize,
                quantity: {
                  value: article.quantity,
                  unit: 'st'
                },
                country: metaMatch.country,
                countryOfOrigin: metaMatch.countryOfOrigin
              });
            } else {
              unavailableArticles.push(article);
            }
          });

          this.setState({
            ingredients: articles,
            selectedIngredients: articles,
            unavailableArticles: unavailableArticles,
            productRefs: data
          });
        });
      })
      .catch(err => {
        console.error(err); // eslint-disable-line
        this.setState({
          loadingIngredientsFailed: true
        });
      })
      .finally(() => {
        this.setState({
          loadingIngredients: false
        });
      });
  };

  componentDidUpdate(prevProps) {
    const { storeId } = this.props;

    if (prevProps.storeId !== storeId) {
      this.getCalculatedRecipe();
    }
  }

  componentDidMount() {
    const defaultSelectedTags = Object.keys(this.props.recipe.baseVariant)
      .filter(x => this.props.recipe.baseVariant[x] === 'JA')
      .map(key => key);

    this.setState({
      defaultSelectedTags: defaultSelectedTags,
      selectedTags: defaultSelectedTags
    });
    if (this.props.storeId) {
      this.getCalculatedRecipe(defaultSelectedTags);
    }

    viewRecipe(this.props.recipe, this.state.numberOfMeals);
  }
  render() {
    const {
      isAuthenticated,
      recipe,
      redirectToLogin,
      removeFavorite,
      setRecipeFavorite,
      storeId,
      deliveryOptions,
      openDeliveryPicker
    } = this.props;

    const {
      adding,
      homeList,
      ingredients,
      loadingHomelist,
      loadingHomelistFailed,
      loadingIngredients,
      loadingIngredientsFailed,
      selectedTags,
      defaultSelectedTags,
      success,
      selectedIngredients,
      unavailableArticles
    } = this.state;

    const addToCartFn = isAuthenticated
      ? this.addRecipeToCartConditional
      : redirectToLogin;

    const setRecipeFavoriteFn = isAuthenticated
      ? setRecipeFavorite
      : redirectToLogin;

    const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

    const multiplyFactor = this.state.numberOfMeals / recipe.quantity.value;

    const selectedIngredientsTotalSum =
      selectedIngredients &&
      selectedIngredients.reduce((acc, item) => acc + item.priceTotal, 0);

    const validTags = recipeMarkings.filter(
      x =>
        recipe.preview?.variants.some(y => y === x.value) &&
        recipe.baseVariant[x.value] !== 'NEJ'
    );

    return (
      <div className="c-recipe">
        <ProductOpenGraph
          name={recipe.name}
          url={recipe.url}
          imageUrl={recipe.image.url}
          brand="Citygross - recept"
          category="recept"
          price={recipe.price}
          id={recipe.id}
        />
        <RecipeSchemaScript
          name={recipe.name}
          imageUrl={recipe.image.url}
          recipeInstructions={recipe.cookingInstructions}
          cookingTime={recipe.cookingTime}
          recipeYield={this.state.numberOfMeals}
          ingredients={recipe.ingredients}
          url={recipe.url}
          // @TODO: we need a proper description filed
          description={recipe.cookingAdviceAdult}
        />

        {recipe.image && (
          <div className="c-recipe__image">
            <ProgressivePicture
              largeImage={{
                alt: recipe.image.alt || recipe.name,
                height: 300,
                width: 900,
                url: recipe.image.url
              }}
              smallImage={{
                height: 513,
                width: 900,
                url: recipe.image.url
              }}
              fitType={ImageFitTypes.Cover}
              maxWidth={{
                large: 1312,
                small: 767
              }}
            />
          </div>
        )}

        <RecipeHead
          adding={adding}
          cookingTime={recipe.cookingTime}
          favorite={recipe.favorite}
          handleTagToggle={(value, avoid) =>
            this.setState(
              {
                ...this.state,
                selectedTags: !avoid
                  ? this.state.selectedTags.some(x => x === value)
                    ? this.state.selectedTags.filter(x => x !== value)
                    : [...this.state.selectedTags, value]
                  : this.state.selectedTags
              },
              this.getCalculatedRecipe
            )
          }
          id={recipe.id}
          defaultSelectedTags={defaultSelectedTags}
          image={recipe.image.url}
          isFavoriteLoading={recipe.isFavoriteLoading}
          isValid={
            ingredients &&
            ingredients.length > 0 &&
            !unavailableArticles.length > 0
          }
          name={recipe.name}
          weightWatchersBluePoints={recipe.weightWatchersBluePoints}
          weightWatchersGreenPoints={recipe.weightWatchersGreenPoints}
          weightWatchersPurplePoints={recipe.weightWatchersPurplePoints}
          weightWatchersPoints={recipe.weightWatchersPoints}
          addToCartEnabled={selectedIngredients.length <= 0}
          onClick={addToCartFn}
          toggleAssortmentPicker={openDeliveryPicker}
          onPortionChange={this.handleOnPortionChange}
          price={recipe.price}
          selectedArticlePrice={selectedIngredientsTotalSum}
          portions={this.state.numberOfMeals}
          removeFavorite={removeFavoriteFn}
          selectedTags={selectedTags}
          sellable={recipe.sellable}
          setRecipeFavorite={setRecipeFavoriteFn}
          source={recipe.source}
          storeId={storeId}
          success={success}
          tags={validTags}
          variants={this.state.validVariants}
          quantity={recipe.quantity}
          loadingIngredientsFailed={loadingIngredientsFailed}
          deliveryOptions={deliveryOptions}
          weightWatchersLink={recipe.weightWatchersLink}
        />

        <div className="c-recipe__body">
          <div className="c-recipe__section-wrapper">
            <RecipeDetails
              ingredients={recipe.ingredients}
              multiplyFactor={multiplyFactor}
            />
            <RecipeDirections recipeDirections={recipe.cookingInstructions} />
          </div>
        </div>
        <div className="c-recipe__ingredients">
          {loadingIngredients && (
            <CenteredSpinner
              dark
              style={{ marginTop: '2rem', marginBottom: '2rem' }}
            />
          )}
          {loadingIngredientsFailed && (
            <ErrorInfo
              title="Hoppsan, något gick fel!"
              text="Det gick inte att hämta artiklarna för detta recept."
              retryButtonFn={this.getCalculatedRecipe}
            />
          )}
          {!deliveryOptions.canAddProductsOrRecipes ? (
            <div className="c-recipe__assortmentinfo">
              <h2>Varor från ingredienslistan</h2>
              <div className="c-recipe__assortmentinfoinner">
                <h3>Vårt leveranserbjudande</h3>
                <p>
                  Vid hemleverans går det inte att kombinera matkasse med lösa
                  matvaror.
                </p>
                <div className="c-recipe__assortmentinfoaction">
                  <Button
                    lg
                    primary
                    onClick={() => {
                      openDeliveryPicker();
                    }}
                  >
                    Byt leveranssätt
                  </Button>
                  <Link to={'/kundservice'}>Mer info & villkor</Link>
                </div>
              </div>
            </div>
          ) : storeId ? (
            <>
              {recipe.sellable &&
                ingredients &&
                (ingredients.length > 0 || unavailableArticles.length > 0) && (
                  <RecipeIngredients
                    adding={adding}
                    success={success}
                    ingredients={ingredients}
                    selectedIngredients={selectedIngredients}
                    unavailableArticles={unavailableArticles}
                    addToCart={addToCartFn}
                    onCheckboxChange={this.handleOnArticleCheckedToggle}
                    selectedAmount={selectedIngredients.length}
                    selectedTotalSum={selectedIngredientsTotalSum}
                  />
                )}
            </>
          ) : (
            <div className="c-recipe__assortmentinfo">
              <h2>Varor från ingredienslistan</h2>
              <div className="c-recipe__assortmentinfoinner">
                <h3>Välj butik för att handla från ditt lokala sortiment</h3>
                <p>Tänk på att sortimentet kan variera mellan butikerna</p>
                <div className="c-recipe__assortmentinfoaction">
                  <Button
                    lg
                    primary
                    onClick={() => {
                      /* toggleInfoModalActive(true)} */

                      openDeliveryPicker();
                    }}
                  >
                    Välj butik
                  </Button>
                  <Link to={'/kundservice'}>Mer info & villkor</Link>
                </div>
              </div>
            </div>
          )}
        </div>

        {loadingHomelist && (
          <CenteredSpinner
            dark
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
          />
        )}
        {loadingHomelistFailed && (
          <ErrorInfo
            title="Hoppsan, något gick fel!"
            text="Det gick inte att hämta hemmalistan för detta recept."
            // retryButtonFn={this.getHomeList}
          />
        )}
        {recipe.cookingAdviceAdult && (
          <div className="c-recipe__tip u-bg-color-white full-width">
            <div className="inner-wrapper">
              <img
                className="image"
                src={TipsImage}
                width="171"
                height="79"
                alt="Tips från oss"
              />
              <div className="c-recipe__tiptext">
                <RichText text={recipe.cookingAdviceAdult} />

                {recipe.cookingAdviceChild && (
                  <RichText text={recipe.cookingAdviceChild} />
                )}
              </div>
            </div>
          </div>
        )}

        {!deliveryOptions.canAddProductsOrRecipes ? (
          <div className="c-recipe__assortmentinfo">
            <h2>Varor från ingredienslistan</h2>
            <div className="c-recipe__assortmentinfoinner">
              <h3>Vårt leveranserbjudande</h3>
              <p>
                Vid hemleverans går det inte att kombinera matkasse med lösa
                matvaror.
              </p>
              <div className="c-recipe__assortmentinfoaction">
                <Button
                  lg
                  primary
                  onClick={() => {
                    openDeliveryPicker();
                  }}
                >
                  Byt leveranssätt
                </Button>
                <Link to={'/kundservice'}>Mer info & villkor</Link>
              </div>
            </div>
          </div>
        ) : (
          <HomeList
            products={homeList}
            nonBoundProducts={this.state.unavailableHomeList}
            storeId={storeId}
            noStoreAction={() => openDeliveryPicker()}
          />
        )}
      </div>
    );
  }
}

RecipeSingle.propTypes = {
  addRecipeToCart: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  deliveryType: PropTypes.string,
  hasBags: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  openModal: PropTypes.func,
  pageLayout: PropTypes.string,
  recipe: PropTypes.shape({
    cookingAdviceAdult: PropTypes.string,
    cookingAdviceChild: PropTypes.string,
    cookingInstructions: PropTypes.string,
    cookingTime: PropTypes.number,
    favorite: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        createdDateTime: PropTypes.string,
        customerId: PropTypes.number,
        favoriteTypeId: PropTypes.number,
        id: PropTypes.number,
        itemNo: PropTypes.string
      })
    ]),
    hasPriceForStore: PropTypes.bool,
    id: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      type: PropTypes.number,
      url: PropTypes.string
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
        type: PropTypes.number,
        url: PropTypes.string
      })
    ),
    ingredients: PropTypes.array,
    isFavoriteLoading: PropTypes.bool,
    marking: PropTypes.array,
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sellable: PropTypes.bool,
    source: PropTypes.string,
    tags: PropTypes.array,
    url: PropTypes.string,
    variants: PropTypes.array,
    visible: PropTypes.bool
  }),
  redirectToLogin: PropTypes.func,
  removeFavorite: PropTypes.func,
  setRecipeFavorite: PropTypes.func,
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changedDeliveryOfferCopy: PropTypes.string
};

const mapStateToProps = state => ({
  storeId: selectCurrentAssortment(state),
  deliveryOptions: determineDeliveryOptions(state),
  deliveryType:
    state.auth.user &&
    state.auth.user.settings &&
    state.auth.user.settings.userDeliveryMethod,
  hasBags: state.cart.bags.bags.length ? true : false,
  changedDeliveryOfferCopy: state.app.settings?.changedDeliveryOfferCopy
});

const mapDispatchToProps = {
  toggleAssortmentPicker: toggleModalActive,
  toggleInfoModalActive,
  openDeliveryPicker
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeSingle);
