import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import { LinkPropType } from '../../prop-types';

// If your url can be null, use <WithLink /> instead.
const Link = ({ link, to, children, ...rest }) => {
  if (to) {
    return (
      <ReactLink to={to} {...rest}>
        {children}
      </ReactLink>
    );
  }

  const { internal = true } = link;
  const url = link.url || '';
  if (internal) {
    return (
      <ReactLink to={to ? to : url} {...rest}>
        {children}
      </ReactLink>
    );
  }
  console.log(link)
  return (
    <a href={url} {...rest} rel="noreferrer noopener">
      {children}
    </a>
  );
};

Link.propTypes = {
  link: LinkPropType,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Link;
