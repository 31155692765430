import styled, { css } from 'styled-components';
import { H2 } from '@citygross/typography';
import { Input } from '@citygross/components';
import { Link } from 'react-router-dom';

export const LoginNewsBoxTitle = styled(H2)`
  margin-top: 0;
  margin-bottom: 8px;
`;

export const LoginTitle = styled(H2)`
  margin: 0;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const LoginWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 0 8px 32px;
`;

export const LoginInput = styled(Input)`
  width: 100%;
`;

export const LoginInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
`;

export const LoginInputLabel = styled.label`
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LoginEmailPreview = styled.span`
  word-break: break-word;
`;

export const LoginInputGroup = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LoginEmailContainer = styled(LoginInputGroup)`
  gap: 0;
`;

export const LoginEmailLabel = styled(LoginInputLabel)`
  margin-bottom: 8px;
`;

export const LoginTextCenter = styled.div`
  text-align: center;
`;

export const LoginLinkStyles = styled(css)`
  font-weight: 500;
  font-size: 15px;
  color: #0069ae;
  text-decoration-line: underline;
`;

export const LoginLink = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: #0069ae;
  margin: 0;
  cursor: pointer;
  text-decoration-line: underline;
`;

export const ChangeLink = styled(LoginLink)`
  margin-left: auto;
`;

export const LoginStyledLink = styled.p`
  ${LoginLinkStyles}
`;

export const ErrorText = styled.p`
  color: #e02721;
  font-size: 15px;
`;

type TSideModalHeaderWrapper = {
  leftElementExists: boolean;
  titleExists: boolean;
  marginBottom?: number;
};

export const SideModalHeaderWrapper = styled.div<TSideModalHeaderWrapper>`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom || marginBottom === 0 ? marginBottom : 22}px;
  grid-template-columns: ${props =>
    props.titleExists && props.leftElementExists ? '1fr auto 1fr' : '1fr'};
  height: 48px;
`;

export const CloseButtonWrapper = styled.span`
  position: absolute;
  right: 16px;
`;

export const SideModalHeaderTitle = styled(H2)`
  margin: 0;
  text-align: center;
`;

export const SideModalHeaderLogo = styled.div`
  justify-self: start;
`;

export const HiddenInputContainer = styled.div`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const EmailText = styled.p`
  word-break: break-word;
`;
