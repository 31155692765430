import pathToRegexp from 'path-to-regexp';

export const ROUTE_PRODUCT_PATTERN = '/(.*)/(.*)/(.*)-p:productId(\\d+[\\w-]*)';
export const ROUTE_CATERING_PATTERN =
  '/(.*)/(.*)/(.*)-c:cateringName(\\d+[\\w-]*)';
export const ROUTE_RECIPE_PATTERN = '/(.*)/(.*)-r:recipeId(\\d+)';
export const ROUTE_DELIVERY_CITIES_PATTERN = '/leveransorter/:city';

export const mapPageTypeToRouteType = {
  DeliveryDistrictListPage: {
    conditionalPattern: ROUTE_DELIVERY_CITIES_PATTERN,
    hasSidebar: false
  },
  ProductFolderPage: {
    conditionalPattern: ROUTE_PRODUCT_PATTERN,
    hasSidebar: true
  },
  RecipeFolderPage: {
    conditionalPattern: ROUTE_RECIPE_PATTERN,
    hasSidebar: false
  },
  CateringFolderPage: {
    conditionalPattern: ROUTE_CATERING_PATTERN,
    hasSidebar: true
  }
};

const ignored = [
  pathToRegexp(ROUTE_PRODUCT_PATTERN),
  pathToRegexp(ROUTE_CATERING_PATTERN),
  pathToRegexp(ROUTE_RECIPE_PATTERN),
  pathToRegexp(ROUTE_DELIVERY_CITIES_PATTERN),
  new RegExp(/\/registrera|\/login|\/checkout|\/glomt-losenord|\/leveransinfo/)
];

export const isMatch = (route: string) =>
  ignored.some(path => path.test(route));
