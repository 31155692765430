import { createSelector } from 'reselect';
import { CartItem } from '../../types/cart/CartItem';
import { Product } from '../../types/xim/product';
import { determineAvailable } from '../../lib/product';
import { Bag } from '../../types/xim/bag';
import { MAGIC } from '../../types/reducers/cart';

const getCartItemsRefs = state => state.cart.items.productRefs;
const getAllCartItems = state => state.cart.items.items;
const getAllBags = state => state.cart.bags.bags;
const getAllCateringItems = state => state.cart.cateredMeals.cateredMeals
const getAllCateringRefs = state => state.cart.cateredMeals.cateredMealsRefs
const getAllBagRefs = state => state.cart.bags.bagRefs;
const getRecipeContainer = state => state.cart.recipes[MAGIC.recipe];

const getPassedInStoreNo = (state, storeNo) => storeNo;

export const getCartItems = createSelector([getAllCartItems], (items: CartItem[]) =>
  items.filter(item => item.type.toLowerCase() === 'item')
);

type ProductCartRefs = { [key: string]: Product }
type BagCartRefs = { [key: string]: Bag }

export const getAllCartItemsSelector = createSelector(
  [getCartItems, getCartItemsRefs, getAllCateringItems, getAllCateringRefs],
  (cartItems: CartItem[], refs: ProductCartRefs, cateringItems: CartItem[], cateringRefs: ProductCartRefs[]) => {
    const groceryItems = cartItems
      .map(item => {
        const product = refs[item.itemNo];

        return {
          ...item,
          product
        };
      })
      .reverse()
    const cateredMeals = cateringItems?.map(cateredMeal => {
      const product = cateringRefs[cateredMeal.itemNo];

      return {
        ...cateredMeal,
        product
      };
    }
    ).reverse()

    return [...groceryItems, ...cateredMeals]
  }
);

export const getRecipeCartReferenceId = createSelector(
  [getRecipeContainer],
  (recipe) => recipe?.id || null
);

export const getAllCartBagsSelector = createSelector(
  [getAllBags, getCartItemsRefs],
  (cartBags: CartItem[], refs: BagCartRefs) =>
    cartBags
      .map(item => {
        const product = refs[item.itemNo];
        return {
          ...item,
          product
        };
      })
      .reverse()
);

interface MergedCartItem extends CartItem {
  product: Product
}

export const getAllUnavailableCartItemsSelector = createSelector(
  [getCartItems, getCartItemsRefs, getPassedInStoreNo],
  (cartItems: CartItem[], refs: ProductCartRefs, storeNo) => {

    let unavailableCartItems: MergedCartItem[] = [];

    cartItems.forEach(item => {
      const product = refs[item.itemNo];
      const isAvailable = product ? determineAvailable((product.availability || []), storeNo) : false;

      if (!isAvailable) {
        unavailableCartItems.push({
          ...item,
          product
        });
      }
    })

    return unavailableCartItems;
  });

export const hasGroceriesInCart = createSelector(
  [getAllCartItems],
  (groceries) => {
    return groceries && Boolean(groceries.length > 0)
  }
)

export const hasBagsInCart = createSelector(
  [getAllBags],
  (bags) => {
    return bags && Boolean(bags.length > 0)
  }
)
