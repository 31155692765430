import React from 'react';
import { Button } from '../../Buttons';
import { ticketNotifierByClick } from '../../../api/endpoints/notify';
import Link from '../../Link';
import Image from '../../Image';
import PriceTag from '../../PriceTag';
import './BagItem.scss';
import { Bag } from '../../../types/xim/bag';
import { EpiserverLink } from '../../../types/episerver/link';
import { minMaxVariants } from '../../../selectors/bag/bagSelectors';
import { getBagPriceByStore, setImage } from '../../../lib/bag';
import { useSelector } from 'react-redux';
import { selectCurrentAssortment } from '../../../selectors/assortments';

interface IProps {
  bag: Bag;
  onBagItemSelect?: () => void;
}

const BagItem: React.FC<IProps> = ({ bag, onBagItemSelect }) => {
  const validVariants = bag.variants.filter(x => {
    const today = new Date();
    const fromCond = x.fromDate ? new Date(x.fromDate) <= today : true;
    const toCond = x.toDate ? new Date(x.toDate) >= today : true;

    const isSellable = x.visible && x.sellable && fromCond && toCond;
    return isSellable;
  });
  const minMaxValues = minMaxVariants(validVariants || null);
  const { minMeals, maxMeals, minPortions, maxPortions } = minMaxValues;
  const image = setImage(bag.images, 0);

  const moddedUrl: EpiserverLink = {
    url: bag.url,
    internal: true
  };
  const isFruitBag = bag.id === 'Fruktkorg';

  const storeNumber = useSelector(selectCurrentAssortment);

  return (
    <Link
      className="c-bagitem"
      link={moddedUrl}
      onClick={() => {
        try {
          if (onBagItemSelect) onBagItemSelect();
        } catch (error) {
          console.error(error);
        }
        ticketNotifierByClick(bag.ticket);
      }}
    >
      <div data-productid={bag.variants && bag.variants[0]?.id}>
        <Image src={image.url} width={259} height={340} alt="" />

        <div className={'c-bagitem-info-container'}>
          <div className={'c-bagitem-info-container__left'}>
            <div className="name">{bag.name}</div>
            <div className="descr">{bag.description}</div>
            {minMaxValues && !isFruitBag && (
              <div className="size">
                {minMeals === maxMeals ? minMeals : `${minMeals} - ${maxMeals}`}{' '}
                middagar &nbsp;• &nbsp;
                {minPortions === maxPortions
                  ? minPortions
                  : `${minPortions} - ${maxPortions}`}{' '}
                portioner
              </div>
            )}
          </div>
          <div className={'c-bagitem-info-container__right'}>
            <div className="shop">
              <div className="price">
                {!isFruitBag && <div className="fromprice">Pris från</div>}
                <PriceTag plainPrice={getBagPriceByStore(bag, storeNumber)} />
              </div>
              <div className="continue">
                <Button primary>Läs mer</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BagItem;
