import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { comparisonTypes } from '../../../types/comparisonTypes';
import { FavoriteButton } from '../../../components/Buttons';
import PriceTag from '../../../components/PriceTag';
import Image from '../../../components/Image';
import { Marking } from '../../../components/Marking';
import BuyButton from '../../../components/BuyButton';
import Availability from '../../../components/Availability';
import { PurchaseRule } from '../../../components/PurchaseRule';
import { MixCampaign } from '../../../components/MixCampaign';
import { capitalizeWord } from '../../../lib/utils';
import './ProductSingleDetails.scss';
import { getProductDescription, setImage } from '../../../lib/product';

import { IExtendedProduct } from '../../../types/storefront/product';
import {
  PriceRuleHelper,
  calculateSellingUnitOfMeasure,
  formatPriceWithDecimalsReduced
} from '../../../lib/price';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../../../reducers';
import {
  productFavoritesSelector,
  favoriteProductsLoadingSelector
} from '../../../selectors/product/productSelectors';
import { removeFavorite, setProductFavorite } from '../../../actions/auth';
import WarningLabel from '../../../components/WarningLabel';
import { isLimitedStock } from '../../../lib/limitedStock';
import { LIMITED_DEAL_TAG } from '../../../components/ProductCard/ProductCard';
import { selectCurrentAssortment } from '../../../selectors/assortments';

interface IMeta {
  product: IExtendedProduct;
}

export const Meta: React.FC<IMeta> = ({ product }) => (
  <div className="meta">
    <h1>{product.name}</h1>
    <div className="subname">{getProductDescription(product)}</div>
  </div>
);

interface IProductSingleDetails {
  esalesClickTriggerOnAddingToCart: Function;
  isAuthenticated: boolean;
  product: IExtendedProduct;
}

const ProductSingleDetails: React.FC<IProductSingleDetails> = ({
  esalesClickTriggerOnAddingToCart,
  product
}) => {
  const dispatch = useDispatch();
  const favorite = useSelector((state: AppState) =>
    productFavoritesSelector(state).find(x => x.itemNo === product.id)
  );
  const isFavoriteLoading = useSelector((state: AppState) =>
    Boolean(favoriteProductsLoadingSelector(state).find(x => x === product.id))
  );
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  const [showMax, setShowMax] = useState<boolean>(false);

  const priceValidUntil = date.toLocaleDateString('sv-SE');
  const image = setImage(product.images, 0);

  const formattedPrice = PriceRuleHelper(product.price);
  const isBulkProduct = product.sellingUnitOfMeasure === 2;

  const isLimited = isLimitedStock(product);

  const hasReturnablePackageDeposit =
    product.returnablePackageDepositInformation &&
    product.returnablePackageDepositInformation.description === 'Pant';
  const hasLimitedDeal = !!product?.tags?.find(
    tag =>
      tag.name === LIMITED_DEAL_TAG.name &&
      tag.namespace === LIMITED_DEAL_TAG.namespace
  );

  return (
    <div
      className="details-block"
      data-product-id={product.gtin}
      itemType={'http://schema.org/Product'}
      itemScope
    >
      <meta itemProp="productID" content={product.gtin} />
      <meta itemProp="sku" content={product.gtin} />
      <meta itemProp="brand" content={product.brand || ''} />
      <meta itemProp="url" content={'https://www.citygross.se' + product.url} />
      {image && (
        <link itemProp="image" href={'https://www.citygross.se' + image.url} />
      )}
      <meta itemProp="name" content={product.name} />
      <meta
        itemProp="description"
        content={product.description || product.brand || product.name}
      />

      <div className={'left'}>
        <Meta product={product} />
        <div
          className="shop"
          itemProp="offers"
          itemType="http://schema.org/Offer"
          itemScope
        >
          <meta
            itemProp="availability"
            content={
              !product.availability ||
              product.availability.length === 0 ||
              !product.price
                ? 'https://schema.org/OutOfStock'
                : 'https://schema.org/InStock'
            }
          />
          <meta itemProp="priceValidUntil" content={priceValidUntil} />
          <meta
            itemProp="price"
            content={`${product.defaultPrice?.ordinaryPrice.price || 0}`}
          />

          <meta itemProp="priceCurrency" content="SEK" />

          <div className={'shop__price-wrapper'}>
            {product.price?.promotions2 &&
              product.price?.promotions2[0]?.minAmount && (
                <MixCampaign
                  detailPage
                  promotion={{
                    label: `Handla andra varor för ${product.price.promotions2[0].minAmount}:-*`
                  }}
                />
              )}
            {product.price && (
              <PriceTag
                forceLeftAlign={true}
                hasLimitedDeal={hasLimitedDeal}
                price={product.price}
                unit={product.unit}
                noPadding={false}
                pant={
                  product.returnablePackageDepositInformation?.description ===
                  'Pant'
                }
              />
            )}
          </div>

          {isLimited && (
            <div className={'mb-10'}>
              <WarningLabel
                label={'Begr. tillgänglighet'}
                extraMargin={Boolean(
                  product.price?.hasDiscount && formattedPrice?.saveLabel
                )}
              />
            </div>
          )}
          <div className={'push-to-bottom'}>
            <div className={'price-comparison'}>
              {product.price?.hasDiscount && formattedPrice?.validPromotion && (
                <p className={'bold ordinal-price mb-5'}>
                  {`Ord. pris ${formattedPrice?.formattedOrdinaryPrice?.value +
                    ',' +
                    formattedPrice?.formattedOrdinaryPrice?.decimal} kr${
                    hasReturnablePackageDeposit
                      ? product.unit
                      : `/${product.unit}`
                  }`}
                </p>
              )}
              {product.price?.hasDiscount &&
                formattedPrice?.validPromotion &&
                formattedPrice?.ordinaryPrice?.comparativePrice &&
                formattedPrice?.ordinaryPrice?.comparativePrice > 0 && (
                  <p className={'grey ordinal-compare-price mb-5'}>
                    {`${'Ord. jfr pris'} ${
                      formattedPrice.ordinaryComparisonPrice
                    } kr/${formattedPrice?.ordinaryPrice
                      ?.comparativePriceUnit &&
                      comparisonTypes(
                        formattedPrice?.ordinaryPrice?.comparativePriceUnit
                      )}`}
                  </p>
                )}

              {formattedPrice?.currentPrice?.comparativePrice &&
                formattedPrice?.currentPrice?.comparativePrice > 0 && (
                  <span className={'grey compare-price'}>
                    {`${'Jfr pris'} ${
                      formatPriceWithDecimalsReduced(
                        formattedPrice?.currentPrice?.comparativePrice
                      )?.fullValue
                    } kr/${formattedPrice?.currentPrice?.comparativePriceUnit &&
                      comparisonTypes(
                        formattedPrice?.currentPrice?.comparativePriceUnit
                      )}`}
                  </span>
                )}
              {product?.price?.displayLowestPriceLast30Days &&
                product?.price?.lowestPriceLast30Days != null && (
                  <div className="mt-5">
                    <span>
                      Tidigare lägsta pris{' '}
                      {
                        formatPriceWithDecimalsReduced(
                          product?.price?.lowestPriceLast30Days
                        )?.fullValue
                      }
                      kr/
                      {comparisonTypes(
                        product?.price?.ordinaryPrice.unit ?? ''
                      )}
                    </span>
                  </div>
                )}
            </div>
            {product.price?.promotions2 &&
            product?.price?.promotions2[0]?.maxAppliedPerReceipt &&
            formattedPrice?.validPromotion &&
            Number(product.price.promotions2[0].maxAppliedPerReceipt) > 0 ? (
              <PurchaseRule>
                {`Max ${product.price.promotions2[0].maxAppliedPerReceipt} ${
                  product.unit === 'kg' ? 'kg' : 'köp'
                } per kund`}
              </PurchaseRule>
            ) : null}
            <span className="max-amount" style={{ opacity: showMax ? 1 : 0 }}>
              Max antal uppnått
            </span>

            <div className="actions mt-10">
              <div>
                <BuyButton
                  product={product}
                  fullWidth={false}
                  primaryButton={true}
                  noMarginQty={true}
                  setShowMax={setShowMax}
                  esalesClickTriggerOnAddingToCart={
                    esalesClickTriggerOnAddingToCart
                  }
                />
              </div>
              <div>
                <FavoriteButton
                  light={true}
                  active={Boolean(favorite)}
                  loading={Boolean(isFavoriteLoading)}
                  handleClick={
                    favorite
                      ? () => dispatch(removeFavorite(favorite))
                      : () =>
                          dispatch(
                            setProductFavorite(product.id, {
                              name: product.name,
                              value: Number(product.price?.currentPrice?.price)
                            })
                          )
                  }
                />
              </div>
            </div>
            {storeNumber && (
              <div className="availability">
                <Availability availability={product.availability || []} />
              </div>
            )}
            {product.price?.promotions2 &&
              (parseInt(product.price?.promotions2[0]?.type || '0') === 1 ||
                parseInt(product.price?.promotions2[0]?.type || '0') === 5) &&
              product.price?.promotions2[0]?.minAmount && (
                <div className={'shop__disclaimer'}>
                  <p>
                    *Gäller ej spel, tidningar, tobak, läkemedel, presentkort,
                    pant och alkoholhaltiga drycker eller i samband med andra
                    rabatter.
                  </p>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className={'right'}>
        <div className="markings">
          {product.mappedMarkings?.slice(0, 3).map(
            marking =>
              marking.image && (
                <Marking
                  altText={marking.altText}
                  code={marking.code}
                  key={marking.id}
                  imageUrl={marking.image.url}
                  // imageAlt={marking.altText}
                  tooltipAlign={'left'}
                />
              )
          )}
        </div>
        <div className="image">
          <Image src={image.url + '?w=300'} alt={image.alt} title={image.alt} />
        </div>
      </div>
    </div>
  );
};

export default ProductSingleDetails;
