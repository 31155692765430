import {
  authenticatedRequest,
  requestRaw,
  authenticatedRequestToast,
  requestRawToast
} from '../../lib/request';
import queryParams from '../../lib/queryParams';
import { dateAsFormat } from '../../lib/delivery';

export const getOrderById = id =>
  authenticatedRequest(`/orders/${id}`, {}, requestRaw);

export const deleteOrderById = orderId => {
  return authenticatedRequestToast(
    `/orders/${orderId}`,
    {
      method: 'DELETE',
      body: JSON.stringify({ orderId })
    },
    requestRawToast
  );
};

export const getOrderForUser = (
  from = `${dateAsFormat(new Date(), 'yyyy-MM-dd')}T00:00:00`
) => {
  return authenticatedRequest(
    `/orders?${queryParams({ afterDeliveryDate: from })}`,
    null,
    requestRaw
  );
};

export const moveToAxfood = orderId =>
  authenticatedRequestToast(
    `/orders/${orderId}/moveToAxfood`,
    {
      method: 'POST'
    },
    requestRawToast
  );
