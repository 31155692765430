import React, { Component } from 'react';
import cs from 'classnames';
import { connect } from 'react-redux';
import {
  increaseItemQuantity,
  decreaseItemQuantity,
  removeRecipeFromCart,
  removeBagFromCart,
  removeBagAndRecipesFromCart,
  removeFromCart,
  removeCateredMealFromCart
} from '../../actions/cart';
import { toggleModalActive } from '../../actions/assortments';
import { openInFlyout } from '../../actions/flyout';
import { toggleActiveRecipe } from '../../actions/shoppingOrder';
import {
  cartTotalValueSelector,
  cartTotalDiscountSelector,
  cartItemExtendedInfoSelector,
  cartBagTotalValue,
  cartItemTotalValue,
  cartRecipeTotalValue,
  extendedRecipes,
  extendedBags,
  cartCharges,
  extendedCateredMeal,
  cateredMealsTotalValue
} from '../../selectors/cart/cartSelector';
import {
  userSelector,
  authenticatedSelector,
  userSubscriptionsSelector
} from '../../selectors/user';
import { push } from 'connected-react-router';
import { responsiveWrapper } from 'react-responsive-redux';
import { upcomingOrders, nextCartSelector } from '../../selectors/order';
import { TABS } from '../../types/reducers/shoppingOrder';
import CartList from '../../components/CartList';
import CSSTransition from 'react-transition-group/CSSTransition';
import { appSettingsSelector } from '../../selectors/app';
import { selectedStore, assortmentsLoading } from '../../selectors/assortments';
import {
  hasNotificationStatusSelector,
  hasNotificationHeightSelector
} from '../../selectors/navigation';
import { domSafe } from '../../lib/document';
import { openDeliveryPicker } from '../../actions/sideModal';
import { TOPNAV_HEIGHT } from '../../components/Header/Header';

const MediaQuery = responsiveWrapper({ minWidth: 1360 });
class ShoppingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false
    };
  }
  componentDidMount() {
    this.setState({
      isMounted: true,
      currentTab: this.props.activeTab
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeTab !== prevProps.activeTab &&
      this.props.activeTab !== null
    ) {
      this.setState({
        currentTab: this.props.activeTab
      });
    }
  }

  render() {
    const { isMounted } = this.state;
    const {
      notificationStatus,
      notificationHeight,
      oldCateringCartCount
    } = this.props;

    const headerHeight = domSafe() ? document.getElementById('header') : null;
    const topHeight = notificationHeight + TOPNAV_HEIGHT;
    return isMounted ? (
      <MediaQuery>
        {matches => {
          const visible =
            matches && !this.props.toggleable ? true : this.props.visible;
          const shoppingCs = cs(
            'b-shopping',
            { active: visible },
            { noticeHidden: !notificationStatus },
            { cartLimitHeight: oldCateringCartCount > 0 }
          );
          return (
            <CSSTransition in={visible} timeout={200} classNames="b-shopping">
              <aside
                className={shoppingCs}
                style={{
                  top: topHeight,
                  height: `calc(100dvh - ${topHeight}px)`
                }}
              >
                <CartList {...this.props} />
              </aside>
            </CSSTransition>
          );
        }}
      </MediaQuery>
    ) : null;
  }
}

const mapStateToProps = state => {
  const total = cartTotalValueSelector(state);
  const totalBagPrice = cartBagTotalValue(state);
  const totalCateredPrice = cateredMealsTotalValue(state);
  const totalItemPrice = cartItemTotalValue(state);
  const totalRecipePrice = cartRecipeTotalValue(state);
  const totalDiscount = cartTotalDiscountSelector(state);
  const items = cartItemExtendedInfoSelector(state);
  const bags = extendedBags(state);
  const recipes = extendedRecipes(state);
  const cateredMeals = extendedCateredMeal(state);
  const orders = upcomingOrders(state);
  const nextCart = nextCartSelector(state);
  const charges = cartCharges(state);
  const user = userSelector(state);
  const userSubscriptions = userSubscriptionsSelector(state);
  const appSettings = appSettingsSelector(state);
  const notificationStatus = hasNotificationStatusSelector(state);
  const notificationHeight = hasNotificationHeightSelector(state);
  return {
    isAuthenticated: authenticatedSelector(state),
    isLoggedInAsCustomer: state.auth.isLoggedInAsCustomer,
    isReplacement: state.cart.meta.isReplacement,
    activeStore: selectedStore(state),
    assortmentsLoading: assortmentsLoading(state),
    nextCart,
    ...state.shoppingOrder,
    cart: {
      ...state.cart.meta,
      recipes,
      items,
      bags,
      cateredMeals
    },
    summary: {
      charges,
      totalPrice: total,
      totalDiscount,
      totalBagPrice,
      totalItemPrice,
      totalRecipePrice,
      totalCateredPrice
    },
    orders,
    user,
    messageStatus: appSettings?.message.status,
    cartText: appSettings?.cart,
    userSubscriptions,
    notificationStatus,
    notificationHeight,

    oldCateringCartCount: state.catering ? state.catering.itemCount : 0
  };
};

const mapDispatchToProps = {
  increaseItemQuantity,
  decreaseItemQuantity,
  removeRecipeFromCart,
  toggleActiveRecipe,
  removeFromCart,
  removeBagFromCart,
  removeBagAndRecipesFromCart,
  removeCateredMealFromCart,
  openInFlyout,
  push,
  toggleModalActive,
  openDeliveryPicker
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingContainer);
