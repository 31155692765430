import { authenticatedRequest, requestRaw } from '../../lib/request';

export const createCart = storeNumber =>
  authenticatedRequest(
    '/carts',
    {
      method: 'POST',
      body: JSON.stringify({ storeNumber })
    },
    requestRaw
  );

export const moveCartToNewStoreId = (cartId, storeId, keepCart = false) => {
  return authenticatedRequest(
    `/carts/${cartId}/move`,
    {
      method: 'POST',
      body: JSON.stringify({ storeNumber: storeId, keepCart: keepCart }),
     
    },
    requestRaw
  );
};

// itemNo -> productId
export const addItemToCart = (id, gtin, itemNo, quantity, netContent, name) =>
  authenticatedRequest(
    `/carts/${id}/items`,
    {
      method: 'POST',
      body: JSON.stringify({ gtin, itemNo, quantity, netContent, name })
    },
    requestRaw
  );

export const addItemsToCart = (id, items) =>
  authenticatedRequest(
    `/carts/${id}/additems`,
    {
      method: 'POST',
      body: JSON.stringify({ items })
      //{ items: [{ gtin, itemNo, quantity, netContent, name }, { gtin, itemNo, quantity, netContent, name }] })
    },
    requestRaw
  );

export const addBagToCart = (id, gtin, itemNo, quantity, name) =>
  authenticatedRequest(
    `/carts/${id}/groceryBags`,
    {
      method: 'POST',
      body: JSON.stringify({ gtin, itemNo, quantity, name })
    },
    requestRaw
  );
export const addCateredMealToCart = (id, gtin, itemNo, quantity, name, sideId) =>
  authenticatedRequest(
    `/carts/${id}/cateredMeals`,
    {
      method: 'POST',
      body: JSON.stringify({ gtin, itemNo, quantity:{value: quantity.quantityFrom, unit: 'Piece', orignalUnit:'Piece'}, name , sideId})
    },
    requestRaw
  );
export const deleteItemFromCart = (id, itemId) =>
  authenticatedRequest(
    `/carts/${id}/items/${itemId}`,
    {
      method: 'DELETE'
    },
    requestRaw
  );

export const removeItemsAndSetRecipes = (id, itemIds, recipe) => {
  return authenticatedRequest(
    `/carts/${id}/removeItemsAndSetRecipes`,
    {
      method: 'POST',
      body: JSON.stringify({
        cartItemIds: itemIds,
        recipes: recipe?.recipesEAN ? [recipe] : null
      })
    },
    requestRaw
  );
};

export const updateBagSubscriptionFrequency = (cartId, frequency) => {
  return authenticatedRequest(
    `/carts/${cartId}/setSubscriptionFrequency`,
    {
      method: 'POST',
      body: JSON.stringify({ frequency })
    },
    requestRaw
  );
};

export const updateBagSubscriptions = (id, productId, subscriptionType) => {
  return authenticatedRequest(
    `/carts/${id}/items/${productId}/setSubscription`,
    {
      method: 'POST',
      body: JSON.stringify({ subscriptionType })
    },
    requestRaw
  );
  // if needed to modify multiple in future
  // const modifiedBody = {
  //   subscriptions: bags.map(bag => {
  //     return {
  //       cartItemId: bag.id,
  //       subscriptionType: bag.subscriptionType
  //     }
  //   })
  // }
  // return authenticatedRequestToast(
  //   `/carts/${id}/setSubscriptions`,
  //   {
  //     method: 'POST',
  //     body: JSON.stringify(modifiedBody)
  //   },
  //   requestRawToast
  // );
};

export const updateItem = (id, productId, quantity, subscriptionType) => {
  return authenticatedRequest(
    `/carts/${id}/items/${productId}`,
    {
      method: 'PUT',
      body: JSON.stringify({ quantity })
    },
    requestRaw
  );
};

export const getCart = id =>
  authenticatedRequest(
    `/carts/${id}`,
    {
      method: 'GET'
    },
    requestRaw
  );

export const clearCart = id =>
  authenticatedRequest(
    `/carts/${id}`,
    {
      method: 'DELETE'
    },
    requestRaw
  );

export const deleteUsableCarts = () =>
  authenticatedRequest(
    `/carts/deleteUsableCarts`,
    {
      method: 'DELETE'
    },
    requestRaw
  );

export const addRecipeToCart = (recipe, cartId) =>
  authenticatedRequest(
    `/carts/${cartId}/editableRecipes`,
    {
      method: 'POST',
      body: JSON.stringify(recipe)
    },
    requestRaw
  );

export const removeEditableRecipe = (cartId, cartItemId, recipeIndex) =>
  authenticatedRequest(
    `/carts/${cartId}/editableRecipes/${cartItemId}/${recipeIndex}`,
    {
      method: 'DELETE'
    },
    requestRaw
  );

export const removeRecipe = (cartId, recipesEAN, recipes, handle) => {
  return authenticatedRequest(
    `/carts/${cartId}/recipes`,
    {
      method: 'POST',
      body: JSON.stringify({
        cartItemId: handle,
        recipesEAN,
        recipes
      })
    },
    requestRaw
  );
};

export const removeAllRecipes = cartId => {
  return authenticatedRequest(
    `/carts/${cartId}/recipes`,
    {
      method: 'DELETE'
    },
    requestRaw
  );
};

export const createReplacement = (orderId, storeNo) => {
  return authenticatedRequest(
    `/orders/${orderId}/createReplacementCart`,
    {
      method: 'POST',
      body: JSON.stringify({ storeNo })
    },
    requestRaw
  );
};

export const applyDiscount = (cartId, code, name, relatedGtin) => {
  return authenticatedRequest(
    `/carts/${cartId}/discountCodes`,
    {
      method: 'POST',
      body: JSON.stringify({
        gtin: code,
        name: name,
        relatedGtin: relatedGtin
      })
    },
    requestRaw
  );
};

export const removeDiscount = (cartId, code) => {
  return authenticatedRequest(
    `/carts/${cartId}/discountCodes`,
    {
      method: 'DELETE',
      body: JSON.stringify({
        gtin: code
      })
    },
    requestRaw
  );
};


export const refreshCartCalculations = (cartId, orderId) =>{
  return authenticatedRequest(
    `/carts/${cartId}/refreshCalculations`,
    {
      method: 'POST',
      body: JSON.stringify({
        OrderId: orderId
      })
    },
    requestRaw
  )
}