import React, { Fragment, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SearchMetaCompletions from './SearchMetaCompletions';
import SearchSuggestions from './SearchSuggestions';
import ProductSuggestion from './ProductSuggestion';
import Suggestions from './Suggestions';
import { Button } from '../../components/Buttons';
import { CloseButton } from '../../components/Buttons';
import Clock from '../../components/assets/clock.svg';
import { CenteredSpinner } from '../../components/Spinner/Spinner';
import './SearchResult.scss';
import { domSafe } from '../../lib/document';
import { fetchTopSellers } from '../../api/endpoints/product';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';
import { fireCallbackOnKeyCode } from '../../lib/utils';
import { useDispatch } from 'react-redux';
import { setNeedle, setOpenSearch } from '../../slices/searchSlice';
import { productDefaults } from '../../selectors/product/productSelectors';

export const SearchResult = props => {
  const {
    addToCart,
    bag,
    bagPage,
    cateredmeal,
    cmscontent,
    decreaseItemQuantity,
    increaseItemQuantity,
    isAuthenticated,
    meta,
    onClose,
    onShowAll,
    product,
    query,
    recipe,
    redirectToLogin,
    searchNode,
    cateringCategoryPage,
    productCategoryPage,
    recipeCategoryPage,
    storePage,
    loading,
    storeId,
    notificationStatus,
    notificationHeight,
    maxHeight,
    setKeepOpen
  } = props;
  const { totalCount } = meta;
  const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;
  const recentSearches =
    !query && meta.recentSearches ? meta.recentSearches : [];

  const hasResults =
    (product.items && Boolean(product.items.length)) ||
    (recipe.items && Boolean(recipe.items.length)) ||
    (cmscontent.items && Boolean(cmscontent.items.length)) ||
    (bag.items && Boolean(bag.items.length)) ||
    (bagPage.items && Boolean(bagPage.items.length)) ||
    (cateringCategoryPage.items &&
      Boolean(cateringCategoryPage.items.length)) ||
    (productCategoryPage.items && Boolean(productCategoryPage.items.length)) ||
    (recipeCategoryPage.items && Boolean(recipeCategoryPage.items.length)) ||
    (storePage.items && Boolean(storePage.items.length));

  const hasCorrections =
    !hasResults && meta.corrections && Boolean(meta.corrections.length);
  const hasSuggestions = meta.completions && Boolean(meta.completions.length);

  const handleRevisited = () => {};

  const headerHeight = domSafe() ? document.getElementById('header') : null;
  const topHeight = notificationHeight + headerHeight?.offsetHeight;
  const [topsellers, setTopSellers] = useState([]);
  const dispatch = useDispatch()
  const currentTopSellerStoreRefs = useRef(null)

  useEffect(() => {
  if(topsellers?.length && currentTopSellerStoreRefs?.current === storeId) return
    if (
      !hasResults &&
      !hasCorrections 
    ) {
      fetchTopSellers({
        store: storeId,
        size: 5,
        page: 0
      })
        .then(({ data }) => {
          if (data.data && data.data.length > 0) {
            const topSellers = data?.data?.map((product => productDefaults(product, storeId))) ;
            setTopSellers(topSellers);
            currentTopSellerStoreRefs.current = storeId
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [hasResults, hasCorrections, storeId, currentTopSellerStoreRefs?.current]);


  useEffect(() => {
    const keydown = (e) =>{
      if(e.keyCode === 27){
        if(props?.needle?.length > 0){
          dispatch(setNeedle(''))
        }else{
          dispatch(setOpenSearch(false));
        }
      }
    }
    if(props.searchIsOpen){

      window.addEventListener('keydown',keydown)
    }else{
      window.removeEventListener('keydown', keydown)
    }
    return () =>   window.removeEventListener('keydown', keydown)
  },[props.searchIsOpen, props.needle])

  return (
    <Fragment>
      {query === '' && !hasResults ? (
        <div
          className={cs('b-searchsuggest', {
            noticeHidden: !notificationStatus
          })}
          style={{ top: topHeight, maxHeight: maxHeight }}
        >
       
          <Fragment>
            <div className={'b-searchsuggest__result'}>
              {topsellers.length > 0 && (
                <Suggestions
                  count={topsellers.length}
                  onShowAll={onShowAll}
                  query={query}
                  title={'Mest köpta varor i din butik'}
                  type="product"
                  onClose={onClose}
                  stopLink={true}
                >
                  {topsellers.slice(0, 5).map(item => (
                    <ProductSuggestion
                      product={item}
                      key={item.id}
                      noLazy={false}
                      setKeepOpen={setKeepOpen}
                      addToCart={() => addToCartFn(item)}
                      increaseItemQuantity={quantity =>
                        increaseItemQuantity(item.cartItemId, quantity)
                      }
                      decreaseItemQuantity={quantity =>
                        decreaseItemQuantity(item.cartItemId, quantity)
                      }
                      esalesClickTrigger={
                        item.ticket &&
                        (() => ticketNotifierByClick(item.ticket))
                      }
                      esalesClickTriggerOnAddingToCart={
                        item.ticket &&
                        (() => ticketNotifierByAddingToCard(item.ticket))
                      }
                    />
                  ))}
                </Suggestions>
              )}
            </div>
          </Fragment>
          {recentSearches?.length ?
          <div className={'b-searchsuggest__empty'}>
            {meta && (
              <ul className={'b-searchsuggest__recent-popular'}>
                {recentSearches.map((item, index) => (
                  <li key={item.phrase + index}>
                    <Link
                      to={`/sok?Q=${item.phrase}`}
                      onClick={() => {
                        ticketNotifierByClick(item.ticket);
                        onClose();
                      }}
                    >
                      <span className={'u-icon u-icon-clock'}>
                        <img src={Clock} width="20" height="20" alt="Clock" />
                      </span>
                      <span className={'u-text u-full-height'}>
                        {item.phrase}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          : null
          }
        </div>
      ) : (
        <div
          className={cs('b-searchsuggest', {
            noticeHidden: !notificationStatus
          })}
          style={{ top: topHeight }}
        >
          <div className="closepanel u-screen-md-hide u-screen-lg-hide">
            <CloseButton onClick={onClose} />
          </div>
          {loading && (
            <div style={{ padding: '20px' }}>
              <CenteredSpinner />
            </div>
          )}

          <div className={'b-searchsuggest__result'}>
            {loading ? null : hasResults || hasCorrections ? (
              <Fragment>
                {(hasCorrections || hasSuggestions) && (
                  <div className={'b-searchsuggest__completions'}>
                    {hasCorrections && meta.corrections.length > 0 && (
                      <p style={{ marginTop: '20px' }}>
                        Din sökning <b>&quot;{query}&quot;</b>, gav tyvärr inga
                        träffar
                      </p>
                    )}

                    <div className="b-searchsuggest__test">
                      {hasCorrections && meta.corrections.length > 0 && (
                        <span style={{ marginRight: '15px' }}>Menade du:</span>
                      )}
                      {hasCorrections ? (
                        <SearchMetaCompletions
                          completions={meta.corrections}
                          query={handleRevisited}
                          searchNode={searchNode}
                        />
                      ) : null}

                      {hasSuggestions ? (
                        <SearchMetaCompletions
                          completions={meta.completions}
                          query={handleRevisited}
                          searchNode={searchNode}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
                <SearchSuggestions
                  addToCart={addToCart}
                  bags={bag}
                  bagPage={bagPage}
                  cateredmeal={cateredmeal}
                  cmscontent={cmscontent}
                  decreaseItemQuantity={decreaseItemQuantity}
                  increaseItemQuantity={increaseItemQuantity}
                  isAuthenticated={isAuthenticated}
                  onClose={onClose}
                  onShowAll={onShowAll}
                  products={product}
                  query={query}
                  setKeepOpen={setKeepOpen}
                  recipes={recipe}
                  redirectToLogin={redirectToLogin}
                  resultCount={meta.filters.type.options}
                  cateringCategoryPage={cateringCategoryPage}
                  productCategoryPage={productCategoryPage}
                  recipeCategoryPage={recipeCategoryPage}
                  storePage={storePage}
                  totalCount={totalCount}
                  storeId={storeId}
                />

                {hasResults && (
                  <div
                    className="c-search-result__view-all  c-search-view-all__desktop"
                    style={{ height: topHeight }}
                  >
                    <Link
                      to={`/sok?Q=${encodeURIComponent(
                        query
                      )}&store=${storeId ? storeId : ''}&page=0`}
                    >
                      <Button
                        disabled={!totalCount > 0}
                        onClick={onClose}
                        minWidth={'170px'}
                      >
                        {' '}
                        Visa fler sökresultat
                      </Button>
                    </Link>
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                {!hasResults && !hasCorrections && totalCount === 0 && (
                  <Fragment>
                    <div className="b-searchsuggest__test">
                      <p style={{ marginTop: '20px' }}>
                        Din sökning <b>&quot;{query}&quot;</b> gav tyvärr inga
                        träffar
                      </p>
                    </div>
                    {topsellers.length > 0 && (
                      <Suggestions
                        count={topsellers.length}
                        onShowAll={onShowAll}
                        query={query}
                        title={'Mest köpta varor i din butik'}
                        type="product"
                        onClose={onClose}
                        stopLink={true}
                      >
                        {topsellers.slice(0, 5).map(item => (
                          <ProductSuggestion
                            product={item}
                            key={item.id}
                            noLazy={false}
                            setKeepOpen={setKeepOpen}
                            addToCart={() => addToCartFn(item)}
                            increaseItemQuantity={quantity =>
                              increaseItemQuantity(item.cartItemId, quantity)
                            }
                            decreaseItemQuantity={quantity =>
                              decreaseItemQuantity(item.cartItemId, quantity)
                            }
                            esalesClickTrigger={
                              item.ticket &&
                              (() => ticketNotifierByClick(item.ticket))
                            }
                            esalesClickTriggerOnAddingToCart={
                              item.ticket &&
                              (() => ticketNotifierByAddingToCard(item.ticket))
                            }
                          />
                        ))}
                      </Suggestions>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

SearchResult.propTypes = {
  addToCart: PropTypes.func,
  loading: PropTypes.bool,
  bag: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  notificationStatus: PropTypes.bool,
  bagPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  cateredmeal: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  cateringCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  clearOverlay: PropTypes.func,
  cmscontent: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  increaseItemQuantity: PropTypes.func,
  decreaseItemQuantity: PropTypes.func,
  isActive: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any
  }),
  meta: PropTypes.shape({
    completions: PropTypes.arrayOf(PropTypes.object),
    corrections: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.shape({
      type: PropTypes.shape({
        id: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            count: PropTypes.string
          })
        )
      })
    }),
    pageCount: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    recentSearches: PropTypes.array,
    revisited: PropTypes.shape({
      emptySearchWord: PropTypes.string,
      showingResultsFor: PropTypes.string
    }),
    sort: PropTypes.any,
    totalCount: PropTypes.number
  }),
  onClose: PropTypes.func,
  onShowAll: PropTypes.any,
  product: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  productCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  push: PropTypes.func,
  query: PropTypes.string,
  recipe: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  recipeCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  redirectToLogin: PropTypes.func,
  search: PropTypes.func,
  searchNode: PropTypes.object,
  setVisibility: PropTypes.func,
  storePage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  toggleOverlay: PropTypes.func,
  toggleSearch: PropTypes.func
};
