import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  LazyProgressivePicture,
  ImageFitTypes
} from '../../components/ProgressivePicture';
import ProductPlaceholder from '../../components/assets/produkt-fallback.svg';
import { Checkbox } from '../../components/Form/FormItem/FormItems';
import Select from '../../components/Select';
import { Button } from '../../components/Buttons';
import { formatPriceWithDecimalsReduced } from '../../lib/price';
import {
  makeQuantityLabel,
  makeSidesLabel
} from '../../components/CateringCard/catering-helpers';
import ShrinkedOpacityText from '../../components/ShrinkedOpacityText';
import { InformationText } from '../../constants/InformationText';

import './CateringSinglePage.scss';
import { SchemaMeta } from '../../components/SchemaMeta';

import Availability from '../../components/Availability';
import { getNextAvailabilityDateForStore } from '../../lib/availability';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { useDispatch, useSelector } from 'react-redux';
import * as ga4 from '@citygross/analytics';
import { addCateredMealToCart } from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import cs from 'classnames';

const ImageHolder = ({ productImageUrl }) => (
  <div className={'c-catering-details__image'}>
    <LazyProgressivePicture
      largeImage={{
        url: productImageUrl,
        width: 300,
        height: 300
      }}
      fitType={ImageFitTypes.Contain}
      inheritHeight
      showThumb={false}
      maxWidth={{
        large: 300,
        small: 300
      }}
      placeholder={{
        image: ProductPlaceholder,
        bgColor: 'transparent'
      }}
    />
  </div>
);

ImageHolder.propTypes = {
  productImageUrl: PropTypes.string
};

const CateringSingleDetailPage = ({
  defaultVariant,
  isBuffe,
  hasSides,
  setVariant,
  variants,
  minOrder,
  maxOrder,
  sides,
  title,
  description,
  cateringImageUrl,
  sendCateringItemAddTracking,
  url,
  label,
  singularLabel,
  isAuthenticated,
  id,
  availability,
  isFlyout
}) => {
  const dispatch = useDispatch();
  const storeNumber = useSelector(state => selectCurrentAssortment(state));

  const [{ choosenKey, choosenVariant }, setChoosenVariant] = useState({
    choosenKey: defaultVariant,
    choosenVariant: variants[defaultVariant][0].id
  });

  useEffect(() => {
    setChoosenVariant({
      choosenKey: defaultVariant,
      choosenVariant: variants[defaultVariant][0].id
    });
  }, [defaultVariant]);

  const matchedVariant =
    variants?.[choosenKey]?.find(x => x.id === choosenVariant) ||
    variants?.[choosenKey]?.[0];

  const [selectedVariant, setSelectedVariant] = useState(
    makeQuantityLabel(variants[defaultVariant])[0],
    label
  );

  const price = useMemo(() => {
    if (selectedVariant && selectedVariant.prices.storeNumber === storeNumber) {
      return formatPriceWithDecimalsReduced(
        selectedVariant.prices.currentPrice
      );
    } else {
      return formatPriceWithDecimalsReduced(
        selectedVariant?.defaultPrice?.currentPrice?.price
      );
    }
  }, [storeNumber, selectedVariant, variants, defaultVariant]);
  const sortQuantities = (a, b) => (a.quantityFrom > b.quantityFrom ? 1 : -1);
  const sorted = variants[defaultVariant].sort(sortQuantities);

  const [quantity, setQuantity] = useState(
    isBuffe
      ? {
          quantityFrom: minOrder
        }
      : {
          quantityFrom: sorted[0].quantityFrom,
          quantityTo: sorted[0].quantityTo
        }
  );
  const [choosenSide, setSide] = useState(null);

  const [sidesHasError, setSideHasError] = useState({
    showMessage: false,
    highlightError: false
  });

  const [hasValidationError, setValidationError] = useState({
    show: false,
    message: ''
  });

  const nextAvailabiltyDate =
    storeNumber &&
    getNextAvailabilityDateForStore(
      selectedVariant.availability || [],
      storeNumber
    );
  const shouldShowAvailability = nextAvailabiltyDate;
  const hasAvailability =
    selectedVariant.availability?.length &&
    selectedVariant?.availability?.find?.(x => x.storeNumber === storeNumber);
  function useMakeOrderLine() {
    return {
      plu: selectedVariant.gtin.substr(4, 4),
      gtin: selectedVariant.gtin,
      id: selectedVariant.id,
      parentId: id,
      variant: selectedVariant.name,
      quantity,
      name: title,
      originalPrice: selectedVariant.prices.currentPrice,
      totalPrice:
        minOrder || maxOrder
          ? selectedVariant.prices.currentPrice * quantity.quantityFrom
          : selectedVariant.prices.currentPrice,
      price: formatPriceWithDecimalsReduced(
        minOrder || maxOrder
          ? selectedVariant.prices.currentPrice * quantity.quantityFrom
          : selectedVariant.prices.currentPrice
      ),
      side: hasSides ? choosenSide?.label : '',
      sideId: hasSides ? choosenSide?.value : '',
      sides: sides,
      productImageUrl: cateringImageUrl,
      image: cateringImageUrl,
      url: url
    };
  }
  const productCategory =
    url?.substring(0, url.lastIndexOf('/') + 1) || undefined;
  const sendViewItemDetailsTracking = () => {
    const isCake = productCategory?.includes('tartor');
    ga4.viewItemDetails({
      value: matchedVariant?.prices?.currentPrice,
      currency: 'SEK',
      items: [
        {
          item_id: selectedVariant.gtin || id,
          item_name:
            title +
            `${
              isCake && selectedVariant
                ? ` - ${selectedVariant?.quantityFrom} portioner`
                : ''
            }`,
          price: matchedVariant?.prices?.ordinaryPrice,
          quantity: isCake ? 1 : quantity.quantityFrom,
          item_brand: 'City Gross',
          item_category: productCategory,
          item_variant: matchedVariant?.name,
          discount:
            matchedVariant?.prices?.ordinaryPrice -
              matchedVariant?.prices?.currentPrice || 0
        }
      ]
    });
  };
  // Similar to componentDidMount and componentDidUpdate:

  useEffect(() => {
    try {
      sendViewItemDetailsTracking();
    } catch (error) {
      console.error(error);
    }
  }, [selectedVariant]);

  return (
    <div
      className={cs('c-catering-details ', {
        'u-px-4': isFlyout
      })}
    >
      <SchemaMeta
        name={title}
        url={url}
        imageUrl={cateringImageUrl}
        description={
          description || matchedVariant?.contents.map(content => content + '. ')
        }
        id={id}
        price={matchedVariant?.prices.currentPrice}
        brand={'Citygross - catering'}
      />

      <div className={cs({ 'l-column-60': isFlyout })}>
        <div className={'c-catering-details__info-box'}>
          <div className={'c-catering-details__inner'}>
            <div className={'c-catering-card__title'}>
              <h1>{title}</h1>
            </div>

            <div className={'c-catering-card__price'}>
              <p>
                {`${price?.value},${price?.decimal}`} kr
                {isBuffe ? `/${singularLabel}` : ''}
              </p>
            </div>

            {description && (
              <div className={'c-catering-card__description mb-5'}>
                <p>{description}</p>
              </div>
            )}

            {variants && (
              <div className={'c-catering-card__specials mt-10'}>
                {Object.keys(variants).map(variantKey => {
                  const variantHasChoosen = variants[variantKey]?.some(
                    variant => variant?.id === choosenVariant
                  );
                  return Object.keys(variants).length === 1 &&
                    variantKey.toLowerCase() === 'standard' ? null : (
                    <Checkbox
                      key={variants[variantKey][0].id}
                      isChecked={variantHasChoosen}
                      id={variants[variantKey][0].id}
                      label={variants[variantKey][0].name}
                      className={'mt-10'}
                      onChange={() => {
                        if (variantHasChoosen) {
                          return;
                        }
                        const newSelectedVariant = variants[variantKey]?.find(
                          chosen =>
                            chosen.quantityFrom === matchedVariant?.quantityFrom
                        );
                        setChoosenVariant({
                          choosenKey: variantKey,
                          choosenVariant:
                            newSelectedVariant?.id ?? variants[variantKey][0].id
                        });
                        setSelectedVariant(() => {
                          const quantityLabels = makeQuantityLabel(
                            variants[variantKey]
                          );
                          return (
                            quantityLabels?.find(
                              variant =>
                                variant.quantityFrom ===
                                matchedVariant?.quantityFrom
                            ) ?? quantityLabels[0]
                          );
                        });

                        setVariant(variantKey, selectedVariant.id);
                        if (!isBuffe) {
                          setQuantity(
                            newSelectedVariant ??
                              variants[variantKey].sort(sortQuantities)[0]
                          );
                        }
                      }}
                    />
                  );
                })}
              </div>
            )}

            <div className={'c-catering-card__lower-section push-to-bottom '}>
              {hasValidationError.show && (
                <div className={'c-catering-card__error-message'}>
                  <p>{hasValidationError.message}</p>
                </div>
              )}
              <div className={'mb-20 u-my-20'}>
                {hasSides && (
                  <div className={'c-catering-card__sides'}>
                    {sidesHasError.showMessage && (
                      <div className={'c-catering-card__error-message'}>
                        <p>Vänligen välj ett tillbehör</p>
                      </div>
                    )}
                    <Select
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      highlight={sidesHasError?.highlightError}
                      onChange={sideId => {
                        if (sideId.toLowerCase() !== 'välj tillbehör') {
                          setSide(() => {
                            const choosenSide = makeSidesLabel(sides).find(
                              side => side.value === sideId
                            );
                            return choosenSide;
                          });
                          setSideHasError({
                            showMessage: false,
                            highlightError: false
                          });
                        } else {
                          setSide(null);
                          setSideHasError({
                            showMessage: false,
                            highlightError: false
                          });
                        }
                      }}
                      placeholder={'Välj tillbehör'}
                      options={makeSidesLabel(sides)}
                    />
                  </div>
                )}

                <div className={'c-catering-card__select-amount-single'}>
                  {isBuffe ? (
                    <>
                      <input
                        className={'c-catering-card__amount-input'}
                        type={'number'}
                        value={quantity.quantityFrom}
                        min={minOrder}
                        max={maxOrder}
                        onFocus={() =>
                          setValidationError({
                            show: false,
                            message: `Minsta beställning ${minOrder} port`
                          })
                        }
                        onBlur={({ target }) => {
                          if (
                            !target.value ||
                            target.value < minOrder ||
                            target.value > maxOrder
                          ) {
                            setValidationError(
                              target < minOrder
                                ? {
                                    show: true,
                                    message: `Minsta beställning är ${minOrder} port`
                                  }
                                : {
                                    show: true,
                                    message: `Störta beställning är ${maxOrder} port`
                                  }
                            );
                            setQuantity(() => {
                              setValidationError(false);
                              return { quantityFrom: minOrder };
                            });
                            return;
                          } else {
                            sendViewItemDetailsTracking();
                          }
                        }}
                        onChange={({ target }) => {
                          if (
                            !target.value ||
                            target.value < minOrder ||
                            target.value > maxOrder
                          ) {
                            setValidationError(
                              !target.value || target < minOrder
                                ? {
                                    show: true,
                                    message: `Minsta beställning är ${minOrder} port`
                                  }
                                : {
                                    show: true,
                                    message: `Största beställning är ${maxOrder} port`
                                  }
                            );
                          } else {
                            setValidationError({ show: false, message: '' });
                          }

                          setQuantity({
                            quantityFrom: parseInt(event.target.value)
                          });
                        }}
                      />{' '}
                      <span className={'c-catering-card__portions'}>
                        {quantity.quantityFrom > 1 || !quantity.quantityFrom
                          ? label
                          : singularLabel}
                      </span>
                    </>
                  ) : (
                    <Select
                      defaultValue={selectedVariant.id}
                      onChange={productId =>
                        setSelectedVariant(() => {
                          const choosenVariant = makeQuantityLabel(
                            variants[choosenKey],
                            label
                          ).filter(product => product.id === productId);

                          setChoosenVariant({
                            choosenKey,
                            choosenVariant: productId
                          });
                          setQuantity({
                            quantityFrom: choosenVariant[0].quantityFrom,
                            quantityTo: choosenVariant[0].quantityTo
                          });

                          setVariant(choosenKey, choosenVariant[0].id);
                          return choosenVariant[0];
                        })
                      }
                      options={makeQuantityLabel(variants[choosenKey], label)}
                    />
                  )}
                </div>
              </div>
              <div>
                {shouldShowAvailability && (
                  <div>
                    <Availability
                      availability={selectedVariant.availability || []}
                    />
                  </div>
                )}
              </div>
              <div className={'c-catering-card__add-to-form-section'}>
                <Button
                  fullwidth
                  primary={true}
                  disabled={!hasAvailability || Boolean(shouldShowAvailability)}
                  onClick={() => {
                    if (quantity < minOrder) {
                      setValidationError({
                        show: true,
                        message: `Minsta beställning är ${minOrder} ${label}`
                      });
                      return;
                    }
                    if (quantity > maxOrder) {
                      setValidationError({
                        show: true,
                        message: `Störta beställning är ${maxOrder} ${label}`
                      });
                      return;
                    }
                    // no side selected
                    if (hasSides && !choosenSide) {
                      setSideHasError({
                        showMessage: true,
                        highlightError: true
                      });
                      return;
                    }
                    if (isAuthenticated) {
                      const orderLines = useMakeOrderLine();
                      dispatch(addCateredMealToCart(orderLines));
                      sendCateringItemAddTracking(orderLines);
                    } else {
                      dispatch(redirectToLogin());
                    }
                  }}
                >
                  {price?.value
                    ? hasSides && !choosenSide
                      ? 'Välj tillbehör'
                      : 'Lägg till'
                    : 'Inte tillgänglig'}
                </Button>
              </div>
            </div>
          </div>

          <ImageHolder productImageUrl={cateringImageUrl} />
        </div>
      </div>

      <div
        className={cs({
          'l-column-60': isFlyout,
          'c-catering-details__details-box': !isFlyout
        })}
      >
        <div className={'c-catering-details__richtext'}>
          <ShrinkedOpacityText>
            {variants?.[choosenKey]?.length > 0 &&
              matchedVariant.contents &&
              matchedVariant.contents.length > 0 && (
                <div className={'c-catering-details__contents'}>
                  <h3>Beskrivning</h3>
                  <ul className={'c-catering-details__contents-list'}>
                    {matchedVariant.contents.map(content => (
                      <li
                        key={content}
                        className={'c-catering-details__contents-list-item'}
                      >
                        {content}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            {matchedVariant &&
              matchedVariant.freshnessGuarantee &&
              matchedVariant.storageInformation && (
                <>
                  <div className={'c-catering-details__content-information'}>
                    <h3>Garanterad hållbarhet</h3>
                    <p>{matchedVariant.freshnessGuarantee}</p>
                  </div>

                  <div className={'c-catering-details__content-information'}>
                    <h3>Förvaring</h3>
                    <p>{matchedVariant.storageInformation}</p>
                  </div>
                </>
              )}

            {matchedVariant && matchedVariant.contentInformation && (
              <div className={'c-catering-details__content-information'}>
                <h3>Råvaruinformation</h3>
                <p>{matchedVariant.contentInformation}</p>
              </div>
            )}
            {matchedVariant && matchedVariant.allergenInformation && (
              <div className={'c-catering-details__content-information'}>
                <h3>Allergiinformation</h3>
                <p>{matchedVariant.allergenInformation}</p>
              </div>
            )}

            {matchedVariant && matchedVariant.otherInformation && (
              <div className={'c-catering-details__other-information'}>
                <h3>Övrig information</h3>
                <p>{matchedVariant.otherInformation}</p>
              </div>
            )}
            {matchedVariant && matchedVariant.ingredientStatement && (
              <div className={'c-catering-details__ingredient-information'}>
                <h3>Ingrediensförteckning</h3>
                <p>{matchedVariant.ingredientStatement}</p>
              </div>
            )}

            {choosenSide &&
              choosenSide.label.toLowerCase !== 'välj tillbehör' &&
              hasSides && (
                <>
                  <h3>Information om valt tillbehör</h3>
                  {variants?.[choosenKey]?.length > 0 &&
                    choosenSide.contents &&
                    choosenSide.contents.length > 0 && (
                      <div className={'c-catering-details__contents'}>
                        <h3>Innehåll</h3>
                        <ul className={'c-catering-details__contents-list'}>
                          {choosenSide.contents.map(content => (
                            <li
                              key={content}
                              className={
                                'c-catering-details__contents-list-item'
                              }
                            >
                              {content}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                  {choosenSide &&
                    choosenSide.freshnessGuarantee &&
                    choosenSide.storageInformation && (
                      <>
                        <div
                          className={'c-catering-details__content-information'}
                        >
                          <h3>Garanterad hållbarhet</h3>
                          <p>{choosenSide.freshnessGuarantee}</p>
                        </div>

                        <div
                          className={'c-catering-details__content-information'}
                        >
                          <h3>Förvaring</h3>
                          <p>{choosenSide.storageInformation}</p>
                        </div>
                      </>
                    )}

                  {choosenSide && choosenSide.contentInformation && (
                    <div className={'c-catering-details__content-information'}>
                      <h3>Råvaruinformation</h3>
                      <p>{choosenSide.contentInformation}</p>
                    </div>
                  )}
                  {choosenSide && choosenSide.allergenInformation && (
                    <div className={'c-catering-details__content-information'}>
                      <h3>Allergiinformation</h3>
                      <p>{choosenSide.allergenInformation}</p>
                    </div>
                  )}

                  {choosenSide && choosenSide.otherInformation && (
                    <div className={'c-catering-details__other-information'}>
                      <h3>Övrig information</h3>
                      <p>{choosenSide.otherInformation}</p>
                    </div>
                  )}
                  {choosenSide && choosenSide.ingredientStatement && (
                    <div
                      className={'c-catering-details__ingredient-information'}
                    >
                      <h3>Ingrediensförteckning</h3>
                      <p>{choosenSide.ingredientStatement}</p>
                    </div>
                  )}
                </>
              )}
            <div className={'c-catering-details__contents'}>
              <h3>Bra att veta</h3>
              <div className={'c-catering-details__content-information'}>
                <p>{InformationText}</p>
              </div>
            </div>
          </ShrinkedOpacityText>
        </div>
      </div>
    </div>
  );
};

CateringSingleDetailPage.defaultProps = {
  variants: {}
};

CateringSingleDetailPage.propTypes = {
  defaultVariant: PropTypes.string,
  isBuffe: PropTypes.bool,
  hasSides: PropTypes.bool,
  setVariant: PropTypes.func,
  variants: PropTypes.object,
  minOrder: PropTypes.number,
  maxOrder: PropTypes.number,
  singularLabel: PropTypes.string,
  sides: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  cateringImageUrl: PropTypes.string,
  id: PropTypes.string
};

export default CateringSingleDetailPage;
