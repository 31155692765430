import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart, addBagToCart } from '../../actions/cart';
import { open as openModal } from '../../actions/confirmModal';
import * as pageActions from '../../actions/page';
import { toggleInfoModalActive } from '../../actions/assortments';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';
import {
  getAllBags,
  getBagVariantWeek,
  getBagVariantCurrWeek,
  getBagById
} from '../../actions/bag';
import { makeBlockRecipeEnhancerSelector } from '../../selectors/recipe/recipeSelectors';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { hasCartFlexkasse } from '../../selectors/cart/cartSelector';
import { makeBagEnhancerSelector } from '../../selectors/bag/bagSelectors';
import { authenticatedSelector } from '../../selectors/user';
import { redirectToLogin } from '../../actions/app';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import {
  selectCurrentAssortment,
  determineDeliveryOptions
} from '../../selectors/assortments';

import LoadBlock from '../../components/LoadingBlock';
import Foodbag from '../../modules/Foodbag/Foodbag';
import ErrorInfo from '../../components/ErrorInfo';

class BagPage extends Component {
  state = {
    errorBag: false,
    errorPage: false
  };

  componentDidMount() {
    const { page, bag, id } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }

    if (!bag) {
      this.getBagContent();
    }
  }

  getBagContent = () => {
    const { getBagById, bagId } = this.props;
    this.setState({
      errorBag: false
    });
    getBagById(bagId).catch(err => {
      console.error('getBagById failed', err); // eslint-disable-line
      this.setState({
        errorBag: true
      });
    });
  };

  getPageContent = () => {
    const { id, getPageById } = this.props;
    this.setState({
      errorPage: false
    });
    getPageById(id).catch(err => {
      console.error('getPageById failed', err); // eslint-disable-line
      this.setState({
        errorPage: true
      });
    });
  };

  getPageAndBagContent = () => {
    this.getPageContent();
    this.getBagContent();
  };

  render() {
    const {
      page,
      title,
      bag,
      bagVariant,
      getBagVariantWeek,
      addBagToCart,
      isAuthenticated,
      setRecipeFavorite,
      removeFavorite,
      redirectToLogin,
      openModal,
      pageLayout,
      storeId,
      deliveryOptions,
      toggleInfoModalActive
    } = this.props;
    const { area1, area2, area3, topImage } = page;
    const { errorBag, errorPage } = this.state;

    if (errorBag || errorPage) {
      return (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={this.getPageAndBagContent}
        />
      );
    }

    return bag ? (
      <Foodbag
        bag={bag}
        bagVariant={bagVariant}
        title={title}
        addToCart={addBagToCart}
        contentArea1={area1}
        contentArea2={area2}
        contentArea3={area3}
        topImage={topImage}
        getBagVariantWeek={getBagVariantWeek}
        setRecipeFavorite={setRecipeFavorite}
        removeFavorite={removeFavorite}
        isAuthenticated={isAuthenticated}
        redirectToLogin={redirectToLogin}
        isFruitBag={bag.id === 'Fruktkorg'}
        openModal={openModal}
        pageLayout={pageLayout}
        storeId={storeId}
        deliveryOptions={deliveryOptions}
        toggleInfoModalActive={toggleInfoModalActive}
      />
    ) : (
      <LoadBlock height={80} />
    );
  }
}

BagPage.required = [
  (state, params, noCache) => {
    return pageActions.getPageById(
      params.id,
      state.router.location.search,
      noCache,
      params.userSiteId
    );
  },
  () => getAllBags()
];

const mapStateToProps = (state, props) => {
  const currentPage = state.navigation.pathLookup[props.match.path];
  const wantedBagId = currentPage.props.bagId;
  const bagSelector = makeBagEnhancerSelector();
  const bag = bagSelector(state).find(b => b.id === wantedBagId);

  return {
    title: breadCrumbSelector(state, props),
    storeId: selectCurrentAssortment(state),
    deliveryOptions: determineDeliveryOptions(state),
    page: pageSelector(state, props),
    bagId: wantedBagId,
    bag,
    bagVariant: {
      ...state.bag.bagVariant,
      recipes: makeBlockRecipeEnhancerSelector(
        state,
        state.bag.bagVariant.recipes || []
      )
    },
    currentWeekBagVariant: {
      ...state.bag.currWeekBagVariant,
      recipes: makeBlockRecipeEnhancerSelector(
        state,
        state.bag.currWeekBagVariant.recipes || []
      )
    },
    hasCartFlexkasse: hasCartFlexkasse(state),
    id: pageIdSelector(state, props),
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {
  getPageById: pageActions.getPageById,
  getAllBags,
  addBagToCart,
  getBagVariantWeek,
  getBagVariantCurrWeek,
  addToCart,
  getBagById,
  setRecipeFavorite,
  removeFavorite,
  redirectToLogin,
  openModal,
  toggleInfoModalActive
};

BagPage.propTypes = {
  page: PropTypes.object,
  title: PropTypes.string,
  bag: PropTypes.object,
  bagVariant: PropTypes.object,
  getAllBags: PropTypes.func,
  getBagVariantWeek: PropTypes.func,
  addBagToCart: PropTypes.func,
  getPageById: PropTypes.func,
  id: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  setRecipeFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  redirectToLogin: PropTypes.func,
  openModal: PropTypes.func,
  pageLayout: PropTypes.string
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BagPage)
);
