import { Box, Button } from '@citygross/components';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import * as styles from './SideModalRegister.styles';
import { BodyText } from '@citygross/typography';
import { validateFormItem } from '../../Form/lib';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import LoginInput from '../../LoginInput/LoginInput';
import { connect, useDispatch } from 'react-redux';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal/SideModal';
import { registerSteps } from './SideModalRegister';
import { Icons } from '@citygross/icons';
import { getCustomerById } from '../../../actions/customer';
import { setCurrentRegisterStep } from '../../../slices/loginSlice';
import RegisterError from './RegisterError';
import { fireCallbackOnEnter } from '../../../lib/utils';
import { ga4VerifyPnrRegister } from '../../../lib/analytics/analytics';

type TLoginEmail = {
  pnr: string;
  setPnr: React.Dispatch<React.SetStateAction<string>>;
} & IAnimatedStepsChildProps;

const REGISTER_ERROR = {
  age: 'not_of_age',
  par: 'not_found_in_par',
  pnr: 'personal_number_in_use',
  bad: 'bad_request'
};

const RegisterId = ({ pnr, setPnr, ...props }: TLoginEmail) => {
  const { goToStep, stepKey, animating } = props;
  const theme = useTheme();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const errorHelper = (err: string) => {
    setError(err);
    setLoading(false);
    ga4VerifyPnrRegister(false, err);
  };

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await validateFormItem(pnr, {
        type: 'personalNumber',
        label: pnr
      });
      const newCustomer: any = await dispatch(getCustomerById(pnr));
      if (!newCustomer?.over18) {
        // error not over 18
        errorHelper(REGISTER_ERROR.age);
      }

      if (!newCustomer?.hasParInfo) {
        errorHelper(REGISTER_ERROR.par);
        // not found in par
      }

      if (newCustomer?.hasLogin) {
        // already exist
        errorHelper(REGISTER_ERROR.pnr);
      }
      if (
        newCustomer?.over18 &&
        newCustomer?.hasParInfo &&
        !newCustomer?.hasLogin
      ) {
        setLoading(false);
        ga4VerifyPnrRegister(true);

        goToStep &&
          (stepKey || stepKey === 0) &&
          goToStep(registerSteps.REGISTER_VERIFY, stepKey);
        dispatch(
          setCurrentRegisterStep({
            currentStep: registerSteps.REGISTER_VERIFY,
            previousStep: stepKey
          })
        );
      }
    } catch (e) {
      errorHelper(REGISTER_ERROR.bad);
    }
  };

  return (
    <styles.RegisterWrapper>
      <styles.RegisterInputContainer onSubmit={e => submit(e)}>
        {error ? (
          <RegisterError onClear={() => setError(null)} error={error} />
        ) : (
          <>
            <styles.RegisterTitle>
              Skapa konto/bli Prio-kund
            </styles.RegisterTitle>
            <styles.RegisterForm>
              <styles.RegisterInputGroup>
                <styles.RegisterInputLabel>
                  Personnummer*
                </styles.RegisterInputLabel>
                <LoginInput
                  placeholder="YYMMDD-XXXX"
                  value={pnr}
                  onChange={e => setPnr(e.target.value)}
                  name={'user id'}
                  loading={animating}
                />
                <BodyText color="#979797">
                  Vi behöver din folkbokföringsadress, för att du ska få rätt
                  service.
                </BodyText>
                <BodyText fontWeight={'semiBold'}>
                  Inte svenskt personnummer?
                </BodyText>

                <styles.RegisterLink
                  //   @TODO where does this go
                  to="/kundservice"
                >
                  Läs mer
                </styles.RegisterLink>
              </styles.RegisterInputGroup>
              <Button
                color={'secondary'}
                fullWidth
                onClick={submit}
                isDisabled={pnr?.length < 10}
                loading={loading}
              >
                Hämta uppgifter
              </Button>
            </styles.RegisterForm>
          </>
        )}
        <styles.RegisterTextCenter>
          <BodyText textAlign="center">
            redan medlem?{' '}
            <styles.RegisterLinkButton
              tabIndex={0}
              onClick={() => dispatch(openSideModal(SideModalTabs.LOGIN))}
              onKeyDown={fireCallbackOnEnter(() => {
                dispatch(openSideModal(SideModalTabs.LOGIN));
              })}
            >
              Logga in
            </styles.RegisterLinkButton>
          </BodyText>
        </styles.RegisterTextCenter>
        {!error && (
          <Box
            background={theme.palette?.lightest}
            rounded={false}
            padding={theme.spacings?.md}
          >
            <styles.RegisterNewsBoxTitle>
              Fördelar som Prio-kund
            </styles.RegisterNewsBoxTitle>
            <styles.ListBox>
              <styles.ListItem>
                <styles.ListItemIcon>
                  <Icons.CheckMark width={20} height={20} color="#0069AE" />
                </styles.ListItemIcon>
                <BodyText>
                  Samla bonuspoäng och få personliga erbjudanden. Exempelvis
                  10%-rabatt
                </BodyText>
              </styles.ListItem>
              <styles.ListItem>
                <styles.ListItemIcon>
                  <Icons.CheckMark width={20} height={20} color="#0069AE" />
                </styles.ListItemIcon>{' '}
                <BodyText>
                  Unika erbjudanden från våra sammarbetspartners
                </BodyText>
              </styles.ListItem>
              <styles.ListItem>
                <styles.ListItemIcon>
                  <Icons.CheckMark width={20} height={20} color="#0069AE" />
                </styles.ListItemIcon>{' '}
                <BodyText>
                  Recept och inspiration direkt till din e-post
                </BodyText>
              </styles.ListItem>
              <styles.ListItem>
                <styles.ListItemIcon>
                  <Icons.CheckMark width={20} height={20} color="#0069AE" />
                </styles.ListItemIcon>{' '}
                <BodyText>Möjlighet att självscanna i våra butiker.</BodyText>
              </styles.ListItem>
              <styles.ListItem>
                <styles.ListItemIcon>
                  <Icons.CheckMark width={20} height={20} color="#0069AE" />
                </styles.ListItemIcon>{' '}
                <BodyText>Inbjudningar till Priodagar i våra butiker</BodyText>
              </styles.ListItem>
            </styles.ListBox>
          </Box>
        )}
      </styles.RegisterInputContainer>
    </styles.RegisterWrapper>
  );
};
function mapStateToProps(state) {
  return {
    ...state.customer
  };
}
export default connect(mapStateToProps)(RegisterId);
