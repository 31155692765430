import React from 'react';
import { identity } from '../../lib/utils';

export const withSelectable = WrappedComponent => {
  class Selectable extends React.Component {
    constructor(props) {
      super(props);
      this.state = { selected: props.defaultSelected || null };
    }

    handleOnChange = selected => {
      this.setState({ selected });
      this.props.onSelect(selected);
    };

    render() {
      const { defaultSelected, ...props } = this.props;
      return (
        <WrappedComponent
          selected={this.state.selected}
          onChange={this.handleOnChange}
          {...props}
        />
      );
    }
  }

  Selectable.defaultProps = { onSelect: identity };

  return Selectable;
};
