import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from '../Link';
import Image from '../Image';
import cs from 'classnames';
import PhoneIcon from '../assets/phone.svg';
import MailIcon from '../assets/mail.svg';
import FaqIcon from '../assets/faq.svg';
import FacebookIcon from '../assets/facebook.svg';
import TwitterIcon from '../assets/twitter.svg';
import YoutubeIcon from '../assets/youtube.svg';
import InstagramIcon from '../assets/instagram.svg';
import LogoSancoInternet from '../assets/LogoSancoInternet.svg';
import LogoKlarna from '../assets/LogoKlarna.png';
import TryggEhandel from '../assets/TryggEhandel.svg';
import classNames from 'classnames';
import { fireCallbackOnEnter } from '../../lib/utils';
import { EFromLinkPosition } from '@citygross/analytics';
import { ga4FavoriteOrPurchaseLinkClick } from '../../lib/analytics/analytics.ts';
import './Footer.scss';

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: -1,
      isMobile: false
    };
  }

  componentDidMount() {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator?.userAgent;
    const mobile = Boolean(
      userAgent?.match(
        /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
      ) || window.screen.width < 1024
    );
    if (mobile) {
      this.setState({ ...this.state, isMobile: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Didn't take into account when footer nav is updated
    if (
      nextState.activeKey !== this.state.activeKey ||
      nextProps.footerNav !== this.props.footerNav ||
      nextState.isMobile !== this.state.isMobile
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleItem = (event, key) => {
    event.preventDefault();
    this.setState({ activeKey: this.state.activeKey === key ? -1 : key });
  };

  render() {
    const {
      footerNav,
      customerService,
      facebookUrl,
      twitterUrl,
      youtubeUrl,
      instagramUrl,
      narrowFooter = false,
      thinFooter = false
    } = this.props;
    const { activeKey } = this.state;
    const securityLogos = {
      klarna: (
        <Fragment>
          <img src={LogoKlarna} alt="Klarna" />
        </Fragment>
      ),
      lakemedelsverket: (
        <Fragment>
          <a
            href="https://lakemedelsverket.se/ovriga-sidor/eu-logotyp-vidimering/#Z00362"
            target="_blank"
            rel="noopener noreferrer"
            title="Klicka här för att kontrollera om webbplatsen är laglig."
          >
            <img src={LogoSancoInternet} alt="Läkemedelsverket" />
          </a>
        </Fragment>
      ),
      tryggEhandel: <div className="teh-certificate"></div>
    };

    return (
      <footer
        className={cs('b-footer', {
          ['thin-footer']: thinFooter,
          ['narrow-footer']: narrowFooter
        })}
      >
        <div className="contact">
          <ul className="pocs">
            <li className="poc phone">
              <a href="tel:0771232425">
                <Image src={PhoneIcon} width={20} height={20} alt="" />
                0771 – 23 24 25
              </a>
            </li>

            <li className="poc mail">
              <a href="mailto:kundservice@citygross.se">
                <Image src={MailIcon} width={20} height={20} alt="" />
                kundservice@citygross.se
              </a>
            </li>

            {customerService && customerService.link && (
              <li className="poc chat">
                <Link link={customerService.link}>
                  <Image src={FaqIcon} width={20} height={20} alt="" />
                  Frågor &amp; svar
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="links">
          <div className="groups">
            {footerNav.slice(0, 5).map((navGroup, key) => (
              <div
                className={classNames(
                  'group',
                  key === activeKey ? 'active' : ''
                )}
                key={key}
              >
                <Link className="groupname" link={navGroup.link}>
                  {navGroup.name}
                  <span
                    className={classNames(
                      'c-plusminus',
                      key === activeKey ? 'active' : ''
                    )}
                    onClick={event => this.toggleItem(event, key)}
                    onKeyDown={fireCallbackOnEnter(event =>
                      this.toggleItem(event, key)
                    )}
                    role={'button'}
                    tabIndex={0}
                  />
                </Link>
                <ul>
                  {navGroup.children.map((child, key) => (
                    <li key={key}>
                      <Link
                        link={child.link}
                        onClick={() => {
                          ga4FavoriteOrPurchaseLinkClick(
                            child?.link?.url,
                            EFromLinkPosition.FOOTER
                          );
                        }}
                      >
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="logos">
          <div className="logos-inner">
            <ul className="logos-left">
              <li className="logo">{securityLogos.lakemedelsverket}</li>
              <li className="logo">{securityLogos.tryggEhandel}</li>
              <li className="logo">{securityLogos.klarna}</li>
            </ul>
          </div>
        </div>
        <div className={cs('info', this.state.isMobile && 'mobile-footer')}>
          <div className="legal">
            <Link to="/Kundservice/allmanna-villkor" className="conditions">
              Allmänna villkor
            </Link>
            {/* <span className="copyright">
            City Gross Sverige AB | Org. nr 556597-2451 | Industrigatan 22 | Box 14, 281 21 Hässleholm
            </span> */}
          </div>
          <div className="copyright">
            <div className="copyright-info">
              <p>City Gross Sverige AB </p>
              <span className="copyright-divider">|</span>
              <p> Org. nr 556597-2451 </p>
              <span className="copyright-divider">|</span>
              <p>
                {' '}
                Industrigatan 22 <span className="copyright-divider">
                  |
                </span>{' '}
                Box 14{' '}
              </p>
              <span className="copyright-divider">|</span>
              <p>281 21 Hässleholm</p>
            </div>
            <span>Copyright City Gross {new Date().getFullYear()}</span>
          </div>
          <div className="social">
            {facebookUrl && (
              <a
                href={facebookUrl}
                target="_blank"
                rel="noreferrer noopener"
                alt="Facebooklänk"
              >
                <Image
                  src={FacebookIcon}
                  width={30}
                  height={30}
                  alt="Facebookikon"
                />
              </a>
            )}
            {twitterUrl && (
              <a
                href={twitterUrl}
                target="_blank"
                rel="noreferrer noopener"
                alt="Twitterlänk"
              >
                <Image
                  src={TwitterIcon}
                  width={30}
                  height={30}
                  alt="Twitterikon"
                />
              </a>
            )}
            {youtubeUrl && (
              <a
                href={youtubeUrl}
                target="_blank"
                rel="noreferrer noopener"
                alt="Youtubelänk"
              >
                <Image
                  src={YoutubeIcon}
                  width={30}
                  height={30}
                  alt="Youtubeikon"
                />
              </a>
            )}
            {instagramUrl && (
              <a
                href={instagramUrl}
                target="_blank"
                rel="noreferrer noopener"
                alt="Instagramlänk"
              >
                <Image
                  src={InstagramIcon}
                  width={30}
                  height={30}
                  alt="Instagramikon"
                />
              </a>
            )}
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  footerNav: PropTypes.array,
  customerService: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.number,
    link: PropTypes.shape({
      internal: PropTypes.bool,
      url: PropTypes.string
    }),
    name: PropTypes.string,
    type: PropTypes.string,
    visible: PropTypes.bool
  }),
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  thinFooter: PropTypes.bool
};

Footer.defaultProps = {
  thinFooter: false
};

export default Footer;
