import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { DEFAULT_PRODUCTPAGE_SIZE } from '../../api/endpoints/product';
import * as productActions from '../../actions/product';
import * as pageActions from '../../actions/page';
import { sortedCartItemsCategoryProductCombinationSelector } from '../../selectors/cart/cartSelector';
import {
  selectedFiltersSelector,
  filtersCombinationSelector
} from '../../selectors/product/productSelectors';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { authenticatedSelector } from '../../selectors/user';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import ProductCategory from '../../modules/ProductCategory/ProductCategory';
import { isNumber } from '../../lib/number';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';

export let SORT_OPTS = [
  {
    value: '',
    label: 'Popularitet'
  },
  {
    value: 'name',
    label: 'Namn A till Ö'
  },
  {
    value: 'nameDesc',
    label: 'Namn Ö till A'
  },
  {
    value: 'price',
    label: 'Lägsta pris'
  },
  {
    value: 'priceDesc',
    label: 'Högsta pris'
  },
  {
    value: 'comparisonPrice',
    label: 'Jämförelsepris'
  },
  {
    value: 'brand',
    label: 'Varumärke A-Ö'
  },
  {
    value: 'brandDesc',
    label: 'Varumärke Ö-A'
  },
  {
    value: 'bfcategorycode',
    label: 'Varugrupp'
  }
];
const impressionListName = 'Product Category';
class ProductCategoryPage extends PureComponent {
  state = {
    pageError: false,
    loading: true,
    currentStoreId: null,
    ga4ImpressionTrackingSent: false
  };

  componentDidMount() {
    // TODO This should be fetched if we dont already have the products
    // for this category.
    const { getProductsByCategory, page, id, storeId, location } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }
    let requestQueries = qs.parse(location.search);
    const resumePageIndex =
      requestQueries.page && parseInt(requestQueries.page - 1, 10);
    if (isNumber(resumePageIndex)) {
      const fetchSize = DEFAULT_PRODUCTPAGE_SIZE;

      requestQueries = { ...requestQueries, size: fetchSize, page: resumePageIndex };
    }
    // TODO: we need to persist sorting in redux state to pickup when changing category
    if (storeId) {
      requestQueries = {
        ...requestQueries,
        store: storeId
      };
    }

    const prodSort = document.cookie.match(/sortArticles=([^;]+)/);
    if (
      !requestQueries.Sort &&
      prodSort &&
      SORT_OPTS.find(opt => opt.value === prodSort[1])
    ) {
      requestQueries = {
        ...requestQueries,
        Sort: prodSort[1]
      };
    }
    getProductsByCategory(id, requestQueries)
      .then(() => {
        this.setState({ loading: false, currentStoreId: storeId });
      })
      .catch(err => {
        this.setState({ loading: false });
        console.error('Retrieving products failed', err);
      });
  }

  getPageContent = () => {
    const { id, getPageById } = this.props;

    this.setState({
      pageError: false
    });
    getPageById(id).catch(err => {
      this.setState({
        pageError: true
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (this.state.loading) return;
    const {
      id,
      location,
      storeId,
      getProductsByCategory,
      products
    } = this.props;
    if (this.state.currentStoreId !== storeId && !this.state.loading) {
      this.setState({ loading: true });
      let requestQueries = qs.parse(location.search);
      requestQueries = {
        ...requestQueries,
        store: storeId
      };

      const resumePageIndex =
        requestQueries.page && parseInt(requestQueries.page, 10);
      if (isNumber(resumePageIndex)) {
        const fetchSize = (resumePageIndex + 1) * DEFAULT_PRODUCTPAGE_SIZE;

        requestQueries = { ...requestQueries, size: DEFAULT_PRODUCTPAGE_SIZE, page: requestQueries.page - 1 };
      }

      getProductsByCategory(id, requestQueries)
        .then(() => {
          this.setState({ loading: false, currentStoreId: storeId });
        })
        .catch(err => {
          this.setState({ loading: false, currentStoreId: storeId });
          console.error('Retrieving products failed', err);
        });

    }
    if (!this.state.ga4ImpressionTrackingSent) {
      try {
        const ga4ImpressionItems = products.map((prod, index) => {
          const priceInfo =
            prod.prices?.find(x => x.storeNumber === this.props.storeId) ||
            prod.prices[0];

          const unitPrice = priceInfo.ordinaryPrice.price || 0;
          let quantity = 1;
          let discount = 0;

          if (priceInfo.hasPromotion) {
            const promo = priceInfo.promotions2[0];
            quantity = promo?.minQuantity || 1;
            discount =
              quantity > 1
                ? unitPrice * quantity - (promo.value || 0)
                : unitPrice - (promo.value || 0);
          }

          return {
            item_id: prod.gtin,
            item_name: prod.name,
            item_brand: prod.brand || 'City Gross',
            item_category:
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined,
            item_variant: prod.variant || undefined,

            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: index + 1,

            price: unitPrice,
            quantity: quantity,
            discount: discount
          };
        });


        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });

        this.setState({
          ...this.state,
          ga4ImpressionTrackingSent: true
        });
      } catch (error) {
        console.error(error);
      }

    }

    if (
      prevProps.products.length !== products.length &&
      this.state.ga4ImpressionTrackingSent
    ) {
      this.setState({
        ...this.state,
        ga4ImpressionTrackingSent: false
      });
    }
  }

  render() {
    const {
      // Don't pass these props
      getPageById,
      getProductsByCategory,
      match,
      products,
      // ---
      ...passOnProps
    } = this.props;

    const { pageError } = this.state;

    return (
      <ProductCategory
        {...passOnProps}
        products={products}
        pageError={pageError}
        getPageContent={this.getPageContent}
        sortOptions={SORT_OPTS}
        impressionListName={impressionListName}
      />
    );
  }
}

ProductCategoryPage.required = [
  (state, params) => {
    return productActions.getProductsByCategory(params.id);
  },
  (state, params, noCache) => {
    return pageActions.getPageById(
      params.id,
      state.router.location.search,
      noCache,
      params.userSiteId
    );
  }
];

const mapStateToProps = (state, props) => {
  const location = props.location;
  return {
    storeId: selectCurrentAssortment(state),
    products: sortedCartItemsCategoryProductCombinationSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    filters: filtersCombinationSelector(state),
    pagination: state.product.pagination,
    title: breadCrumbSelector(state, props),
    location,
    isAuthenticated: authenticatedSelector(state),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.product.fetching
  };
};

const mapDispatchToProps = {
  getProductsByCategory: productActions.getProductsByCategory,
  getPageById: pageActions.getPageById,
  requestMoreProducts: productActions.requestMoreProducts,
  sortProducts: productActions.sortProducts,
  filterProducts: productActions.filterProducts,
  increaseItemQuantity,
  decreaseItemQuantity,
  redirectToLogin,
  addToCart,
  setProductFavorite,
  removeFavorite
};

ProductCategoryPage.propTypes = {
  getProductsByCategory: PropTypes.func.isRequired,
  page: PropTypes.object,
  id: PropTypes.number,
  storeId: PropTypes.number,
  location: PropTypes.object,
  getPageById: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCategoryPage)
);
