import { Bag, BagVariant } from '../xim/bag';
const NAME = 'bag';

export const FETCH_BAG_REQUESTED = `${NAME}/BAG_REQUESTED`;
export const FETCH_BAG_RECEIVED = `${NAME}/BAG_RECEIVED`;
export const FETCH_BAG_FAILED = `${NAME}/BAG_FAILED`;

export const FETCH_BAGS_REQUESTED = `${NAME}/BAGS_REQUESTED`;
export const FETCH_BAGS_RECEIVED = `${NAME}/BAGS_RECEIVED`;
export const FETCH_BAGS_FAILED = `${NAME}/BAGS_FAILED`;

export const FETCH_BAGVARIANTWEEK_REQUESTED = `${NAME}/BAGVARIANTWEEK_REQUESTED`;
export const FETCH_BAGVARIANTWEEK_RECEIVED = `${NAME}/BAGVARIANTWEEK_RECEIVED`;
export const FETCH_BAGVARIANTRECIPES_RECEIVED = `${NAME}/BAGVARIANTRECIPES_RECEIVED`;
export const FETCH_BAGVARIANTWEEK_FAILED = `${NAME}/BAGVARIANTWEEK_FAILED`;

export const FETCH_BAGVARIANTCURRENTWEEK_REQUESTED = `${NAME}/FETCH_BAGVARIANTCURRENTWEEK_REQUESTED`;
export const FETCH_BAGVARIANTCURRENTWEEK_RECEIVED = `${NAME}/FETCH_BAGVARIANTCURRENTWEEK_RECEIVED`;
export const FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED = `${NAME}/FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED`;
export const FETCH_BAGVARIANTCURRENTWEEK_FAILED = `${NAME}/FETCH_BAGVARIANTCURRENTWEEK_FAILED`;

export interface BagsState {
    fetching: boolean,
    bags: Bag[],
    bagVariant: {
        variant: BagVariant | null,
        recipes: string[],
        homeList: string[],
        homeListIngredients: string[],
        fetching: boolean,
        error: string | null
    },
    currWeekBagVariant: {
        variant: BagVariant | null,
        recipes: string[],
        homeList: string[],
        homeListIngredients: string[],
        fetching: boolean,
        error: string | null
    },
    error: string | null
}
