import {
  FETCH_SETTINGS_RECEIVED,
  FETCH_SETTINGS_FAILED,
  TOGGLE_MOBILE_NAV,
  TOGGLE_SEARCH,
  TOGGLE_MODAL,
  TOGGLE_PORTAL_OVERLAY,
  TOGGLE_OVERLAY,
  CLEAR_OVERLAY_CBS,
  ISettingsReceivedAction,
  ISettingsFailedAction,
  IAppToggleMobileNavAction,
  IAppToggleSearchAction,
  IAppToggleOverlayAction,
  IAppClearOverlayAction,
  IAppTogglePortalOverlay,
  IAppToggleModalAction
} from '../../types/reducers/app';

import { SettingsPage } from '../../types/episerver/settingsPage/SettingsPage'; 

export function receiveSettings(payload: SettingsPage): ISettingsReceivedAction {
  return {
    type: FETCH_SETTINGS_RECEIVED,
    payload: payload
  };
}

export function fetchSettingsError(error: string) : ISettingsFailedAction {
  return {
    type: FETCH_SETTINGS_FAILED,
    error
  };
}

export function toggleMobileNav() : IAppToggleMobileNavAction {
  return {
    type: TOGGLE_MOBILE_NAV
  };
}

export function toggleSearch(active: boolean) : IAppToggleSearchAction{
  return {
    type: TOGGLE_SEARCH,
    payload: { active }
  };
}

export const toggleOverlay = (active: boolean, includeHeader: boolean, cb: Function) : IAppToggleOverlayAction => {
  return {
    type: TOGGLE_OVERLAY,
    payload: {
      active,
      includeHeader,
      cb
    }
  };
};

export const clearOverlay = () : IAppClearOverlayAction => {
  return {
    type: CLEAR_OVERLAY_CBS
  };
};

export const togglePortalOverlay = (active: boolean) : IAppTogglePortalOverlay => {
  return {
    type: TOGGLE_PORTAL_OVERLAY,
    payload: {
      active
    }
  };
};

export const toggleModal = (active: boolean) : IAppToggleModalAction => {
  return {
    type: TOGGLE_MODAL,
    payload: {
      active
    }
  };
};
