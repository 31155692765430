/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Summary from '../../components/CartList/CartSummary';
import { isEmpty } from '../../lib/cart';
import RecipeGroup from './RecipeGroup';
import CateringGroup from './CateringGroup';
import ProductsGroup from './ProductsGroup';
import { ConfirmModalPortal } from '../../components/ConfirmModal';
import {
  calculateTotalWithLowestPickingFee,
  lowestPickingFeeExists
} from '../../lib/cart';
import './CartList.scss';
import './CartSummary/CartSummary.scss';
import { CartSubTotal, CgButton, Link as CgLink } from '@citygross/components';
import * as ga4 from '@citygross/analytics';
import { connect } from 'react-redux';
import { closeSideModal } from '../../slices/sideModalSlice';
import { clearCartGetNext } from '../../actions/cart/async';
import { cartTotalItemCountSelector } from '../../selectors/cart/cartSelector';
import CartExtraInfo from './CartExtraInfo/CartExtraInfo.tsx';
import Observer from 'react-intersection-observer';
import { generateGA4FromCartItems } from '../../lib/analytics/analytics.ts';
import CartCoupons from './CartCoupons';
import CartLoading from './CartLoading';
import { Icons } from '@citygross/icons';
import { formatPriceWithDecimalsReduced } from '../../lib/price.ts';
import CartScrollArea from './CartScrollArea';
import CartEmptyState from './CartEmptyState';

const CART_FOOTER_PLACEHOLDER_HEIGHT = 113;

export const CartContainer = props => {
  const {
    isAuthenticated,
    isReplacement,
    cart,
    confirmModalRef,
    summary,
    nextCart,
    cartText,
    style,
    removeRecipeFromCart,
    shoppingOrder,
    isInModal,
    orderLoading,
    initialOrderLoad,
    cartCount
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    title: 'Töm varukorgen',
    body: 'Är du säker på att du vill tömma varukorgen?'
  });
  const [hasScrolled, setHasScrolled] = useState(false);
  const footerRef = useRef();
  const cartTotalRef = useRef();
  const checkoutBtnRef = useRef(null);

  const scrollRef = useRef();
  const [summaryIsInView, setSummaryIsInView] = useState(false);

  const [hasScroll, setHasScroll] = useState(false);
  const [footerHeight, setFooterHeight] = useState(false);

  const toggleModal = (event, newModalProps) => {
    event?.persist();
    setShowModal(!showModal);
    setModalProps({
      ...modalProps,
      ...newModalProps
    });
  };
  const clearList = (cart, nextCart) => {
    const { clearCartGetNext } = props;
    clearCartGetNext(cart, nextCart);
    setShowModal(false);
  };
  const onClearList = (event, cart, nextCart) => {
    toggleModal(event, {
      onConfirmClick: () => {
        clearList(cart, nextCart);

        try {
          const {
            bagProducts,
            items,
            recipes,
            cateredMeals
          } = generateGA4FromCartItems(cart);
          const ga4Cart = {
            value: cart.totalAmount,
            currency: 'SEK',
            items: [...bagProducts, ...items, ...recipes, ...cateredMeals]
          };

          ga4.removeFromCart(ga4Cart);
        } catch (error) {
          console.error(error);
        }
      },
      title: isEmpty(cart) && cart.id ? 'Ta bort varukorg' : 'Töm varukorgen',
      body: 'Är du säker på att du vill tömma varukorgen?',
      confirmLabel: 'Töm varukorgen',
      cancelLabel: 'Avbryt'
    });
  };

  let totalValue = summary.totalPrice;
  if (
    totalValue > 0 &&
    lowestPickingFeeExists(cart?.lowestPickingFee) &&
    cart?.items?.length
  ) {
    totalValue = calculateTotalWithLowestPickingFee(
      totalValue,
      summary.charges,
      cart?.lowestPickingFee
    );
  }

  useEffect(() => {
    setHasScroll(
      scrollRef?.current?.scrollHeight > scrollRef?.current?.clientHeight
    );
    setFooterHeight(
      cartTotalRef?.current?.clientHeight || CART_FOOTER_PLACEHOLDER_HEIGHT
    );
  }, [
    cartTotalRef?.current?.clientHeight,
    hasScroll,
    scrollRef?.current,
    totalValue
  ]);

  const summaryTotal = formatPriceWithDecimalsReduced(summary.totalDiscount);

  return (
    <div className="cart" style={style}>
      {cart?.loading ||
        (cart?.refreshing && (
          <CartLoading loading={cart?.loading || cart?.refreshing} />
        ))}
      {initialOrderLoad && isAuthenticated && <CartLoading defaultShow />}
      <CartScrollArea
        scrollRef={scrollRef}
        isInModal={isInModal}
        hasScrolled={hasScrolled}
        bottomOffset={footerHeight}
        onScroll={ref => {
          if (ref?.current?.scrollTop > 0) {
            setHasScrolled(true);
          } else {
            setHasScrolled(false);
          }
        }}
      >
        {cart.totalAmount > 0 ? (
          <Fragment>
            <div className="cart-list">
              <div>
                <div className="cart-list__items">
                  <CartExtraInfo
                    summary={summary}
                    cart={cart}
                    isAuthenticated={isAuthenticated}
                    isReplacement={isReplacement}
                    shoppingOrder={shoppingOrder}
                    nextCart={nextCart}
                  />
                  <ProductsGroup {...props} />
                  <RecipeGroup
                    recipes={cart.recipes}
                    removeRecipeFromCart={removeRecipeFromCart}
                  />
                  <CateringGroup {...props} />
                  <CartCoupons
                    cart={props?.cart}
                    checkoutBtnRef={checkoutBtnRef}
                  />
                </div>
                {((cart.id && !isReplacement) || isAuthenticated) && (
                  <div className="c-clear-cart">
                    <CgLink
                      asButton
                      color="black"
                      textDecoration="underline"
                      onClick={event => {
                        onClearList(event, cart, nextCart);
                      }}
                    >
                      <p className="c-clear-cart__label">
                        {isEmpty(cart) && cart.id
                          ? 'Ta bort varukorg'
                          : 'Töm varukorgen'}
                      </p>
                    </CgLink>
                  </div>
                )}
              </div>
              <div className="cart-summary__outer-container">
                <Observer
                  onChange={inView => {
                    setSummaryIsInView(inView);
                  }}
                >
                  <div className="cart-summary__container">
                    <Summary
                      {...summary}
                      cart={cart}
                      cartText={cartText}
                      isAuthenticated={isAuthenticated}
                      hideShadow={!summaryIsInView}
                    />
                  </div>
                </Observer>
              </div>
            </div>

            {modalProps.body && (
              <ConfirmModalPortal
                confirmModalRef={confirmModalRef}
                show={showModal}
                onCancelClick={toggleModal}
                {...modalProps}
              />
            )}
          </Fragment>
        ) : isAuthenticated ? (
          !initialOrderLoad ? (
            <CartEmptyState />
          ) : null
        ) : (
          <CartEmptyState />
        )}
      </CartScrollArea>
      <footer className={'cart-footer'} ref={footerRef}>
        <div
          className={cs('cart-footer__container', {
            [`cart-footer__scrolled`]: summaryIsInView
          })}
          style={{
            maxWidth: scrollRef?.current?.clientWidth
              ? `${scrollRef.current?.clientWidth}px`
              : 'auto'
          }}
        >
          {summaryIsInView && Boolean(totalValue) && (
            <div className="c-divider" />
          )}
          <div className="cart-footer-summary-total" ref={cartTotalRef}>
            {cartCount !== 0 && (
              <CartSubTotal type="items" value={totalValue}>
                <p className="c-total">
                  Totalt{' '}
                  <span className="c-total-count">({cartCount} varor)</span>
                </p>
              </CartSubTotal>
            )}

            {Boolean(summary?.totalDiscount) && (
              <div className="c-discount">
                <p>Varav rabatt</p>
                <p className="c-discount-price">
                  {summaryTotal.value},{summaryTotal.decimal} kr
                </p>
              </div>
            )}

            <CgButton
              buttonRef={checkoutBtnRef}
              onClick={() => {
                window.onbeforeunload = () => undefined;
                window.location.replace('/checkout');
              }}
              disabled={isEmpty(cart)}
              flexReverse
            >
              <div className="c-checkout-btn__label">
                Gå till kassan{' '}
                <Icons.ChevronRight height={20} width={20} color="#fff" />
              </div>
            </CgButton>
          </div>
        </div>
      </footer>
    </div>
  );
};

const mapStateToProps = state => ({
  shoppingOrder: state.shoppingOrder,
  cartCount: cartTotalItemCountSelector(state),
  orderLoading: state.order.loading,
  initialOrderLoad: state.order.initialLoad
});

const mapDispatchToProps = dispatch => ({
  closeSideModal: () => dispatch(closeSideModal()),
  clearCartGetNext: (cart, nextCart) =>
    dispatch(clearCartGetNext(cart, nextCart))
});
export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
