import React from 'react';
import BagList from '../../../components/BagList';
import { LoadMoreToolbar } from '../../../components/LoadMoreToolbar';
import './ContentView.scss';

const BagView = ({ items, requestMoreBags, pagination }) => {
  return (
    <div className="c-searchresultcontent">
      <BagList bags={items} listName={'Searchresult bags'} />
      <LoadMoreToolbar
        // fetching={fetching}
        requestMore={() =>
          requestMoreBags({
            page: pagination.pageIndex + 1
          })
        }
        entity={'artiklar'}
        count={items.length}
        totalCount={pagination.totalCount}
      />
    </div>
  );
};
export default BagView;
