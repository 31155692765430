import { esalesRequest } from '../../lib/request';

export const fetchBagById = bagId => esalesRequest(`/esales/bag/${bagId}`);

export const fetchBagsByIds = bagIds => {
  if (!bagIds || !bagIds.length > 0) {
    return;
  }
  return esalesRequest(`/esales/bag/${bagIds}`);
};

export const fetchAllBags = () => esalesRequest('/esales/bags?size=100');

export const fetchBagsByVariantIds = bagIds => {
  if (!bagIds || !bagIds.length > 0) {
    return;
  }
  return esalesRequest(`/esales/bagbyvariant/${bagIds}`);
};

export const fetchBagVariantWeek = (bagId, year, week) =>
  esalesRequest(
    `/esales/bagvariantweek?bagId=${bagId}&year=${year}&week=${week}`
  );
