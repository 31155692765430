/* eslint-disable import/namespace */
import React, { Component } from 'react';

import { formatPriceWithDecimalsReduced } from '../../lib/price';
import './Cart.scss';
import {
  calculateTotalWithLowestPickingFee,
  lowestPickingFeeExists
} from '../../lib/cart';
// eslint-disable-next-line import/named
import CartButton from './CartButton';
import { BodyText, TextTypes } from '@citygross/typography';
import { isNumber } from '../../lib/number';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pulse: false,
      fetchingPickingFees: false
    };
  }

  componentDidUpdate(prevProps) {
    const resetFetchingPickingFees = () => {
      this.setState({
        ...this.state,
        fetchingPickingFees: false
      });
    };

    if (
      isNumber(this.props.cartCount) &&
      (prevProps.cartCount !== this.props.cartCount ||
        prevProps.totalValue !== this.props.totalValue)
    ) {
      this.setState({
        ...this.state,
        pulse: true
      });
      this.props.setPickingFeeRange(this.props.meta, resetFetchingPickingFees);
    }
    if (prevProps.isReplacement !== this.props.isReplacement) {
      this.props.handleClick();
    }
    if (
      (this.props.bags.bags.length > 0 ||
        this.props.cateredMeals.cateredMeals.length > 0) &&
      this.props.meta?.lowestPickingFee !== 0
    )
      this.props.setPickingFee(0);

    if (
      this.props.bags.bags.length === 0 &&
      ((this.props.items.items?.length > 0 &&
        this.props.meta?.id.length > 0 &&
        !lowestPickingFeeExists(this.props.meta?.lowestPickingFee) &&
        !this.state.fetchingPickingFees) ||
        prevProps.bags.bags.length > 0)
    ) {
      this.setState({
        ...this.state,
        fetchingPickingFees: true
      });
    }
  }

  render() {
    const {
      totalValue = 0,
      cartCount,
      handleClick,
      isReplacement,
      meta,
      items
    } = this.props;
    const label = 'Varukorg';
    let value = totalValue;

    if (
      value > 0 &&
      meta &&
      meta.lowestPickingFee &&
      lowestPickingFeeExists(meta.lowestPickingFee) &&
      items?.items?.length
    ) {
      value = calculateTotalWithLowestPickingFee(
        value,
        meta.charges,
        meta.lowestPickingFee
      );
    }

    const formattedTotalValue = formatPriceWithDecimalsReduced(value);

    return (
      <CartButton
        buttonBadge={cartCount || cartCount === 0 ? cartCount?.toString() : '0'}
        handleClick={handleClick}
      >
        {formattedTotalValue && cartCount ? (
          <BodyText size={TextTypes.TextSize.SMALL} fontWeight={'semiBold'}>
            {formattedTotalValue.value},{formattedTotalValue.decimal}
            <span className="unit"> kr</span>
          </BodyText>
        ) : (
          <BodyText size={TextTypes.TextSize.SMALL} fontWeight={'medium'}>
            Handla
          </BodyText>
        )}
      </CartButton>
    );
  }
}

export default Cart;
