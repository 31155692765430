import React from 'react';
import CartGroup from '../CartGroup';
import ProductCartCard from './ProductCartCard';
import { CSSTransition } from 'react-transition-group';

const ProductsGroup = ({ cart }) => {
  return cart.items.length < 1 ? null : (
    <CartGroup name={'Varor'}>
      {cart.items.map((item, idx, arr) => {
        const { product, quantity, amount, totalDiscountAmount } = item;
        const isDiscountPrice = totalDiscountAmount > 0;
        return (
          <CSSTransition key={item.id} timeout={1000} classNames="slidein">
            <ProductCartCard
              {...product}
              totalPrice={amount}
              isDiscountPrice={isDiscountPrice}
              quantity={quantity.value}
              cartItemId={item.id}
              isLast={idx === arr?.length - 1}
            />
          </CSSTransition>
        );
      })}
    </CartGroup>
  );
};

export default ProductsGroup;
