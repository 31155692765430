import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Product } from '../../types/xim/product';
import ProductCarousel from '../../components/ProductCarousel';
import { ProdCard as ProdCardSkeleton } from '../../components/Skeletons/ProdCard';
import ProductCard from '../../components/ProductCard';
import { formatPrice } from '../../store/middleware/analytics';
import * as ga4 from '@citygross/analytics';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';

import { IconButton } from '@citygross/components';
import { Icons } from '@citygross/icons';
import ProductArticleSkeleton from '../../components/ProductArticleSkeleton';

type TProductCardList = {
  products: Product[];
  maxItems: number;
  cardFuncs?: any;
  allRows?: boolean;
  loading: boolean;
  colStructure: string;
  impressionListName?: string;
  storeId?: number;
  isCarousel?: boolean;
};

const ProductCardList: React.FC<TProductCardList> = ({
  products,
  maxItems,
  cardFuncs,
  allRows,
  loading,
  colStructure,
  impressionListName,
  storeId,
  isCarousel
}) => {
  const [prods, setProds] = useState<Product[]>([]);
  useEffect(() => {
    setProds(allRows ? products : products.slice(0, maxItems));
  }, [products]);
  const maxItemRef = useRef(0);

  useEffect(() => {
    if (prods.length > 0 && maxItems !== maxItemRef.current) {
      maxItemRef.current = maxItems;

      try {
        const ga4ImpressionItems = prods.map((prod, index) => {
          const priceInfo = prod.prices?.find(x => x.storeNumber === storeId);

          const unitPrice = priceInfo?.ordinaryPrice?.price || 0;
          let quantity = 1;
          let discount = 0;

          if (priceInfo?.hasPromotion) {
            const promo = priceInfo.promotions2?.[0];
            quantity = promo?.minQuantity || 1;
            discount =
              quantity > 1
                ? unitPrice * quantity - (promo?.value || 0)
                : unitPrice - (promo?.value || 0);
          }

          return {
            item_id: prod.gtin,
            item_name: prod.name,
            item_brand: prod.brand || 'City Gross',
            item_category:
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              'undefined',
            item_list_name: impressionListName ?? '',
            item_list_id: impressionListName ?? '',
            index: index + 1,

            price: Number(formatPrice(unitPrice)),
            discount: Number(formatPrice(discount)),
            quantity: quantity
          };
        });

        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [prods]);

  const prodCard = (item, index, onCarousel) => (
    <ProductCard
      onCarousel={onCarousel}
      key={item.id}
      product={item}
      colStructure={colStructure}
      setProductFavorite={(id, item) => cardFuncs.setProductFavorite(id, item)}
      removeFavorite={item => cardFuncs.removeFavorite(item)}
      esalesClickTrigger={
        item.ticket ? () => ticketNotifierByClick(item.ticket) : () => {}
      }
      esalesClickTriggerOnAddingToCart={
        item.ticket ? () => ticketNotifierByAddingToCard(item.ticket) : () => {}
      }
      onClose={() => {}}
      impressionListName={impressionListName || ''}
      impressionListPosition={index + 1}
      buttonText={'Lägg till'}
    />
  );

  const ProdCardList = useMemo(() => {
    return prods.map((item, index) => prodCard(item, index, isCarousel));
  }, [isCarousel, prods]);

  const Carousel = useMemo(() => {
    return (
      <ProductCarousel productsLength={prods.length}>
        {ProdCardList}
      </ProductCarousel>
    );
  }, [isCarousel, ProdCardList]);
  if (products.length === 0 || loading) {
    return (
      <ProductArticleSkeleton
        loading={loading}
        productsLength={products.length}
        maxItems={maxItems}
      />
    );
  }

  return isCarousel ? <>{Carousel}</> : <>{ProdCardList}</>;
};

export default ProductCardList;
