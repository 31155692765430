import {
  ITEM_ADDED,
  ITEM_REMOVED,
  ITEMS_REMOVED,
  ITEM_ADDED_WITH_DEBOUNCE,
  APPLY_QUEUED_ACTIONS,
  BAG_ADDED,
  BAG_REMOVED,
  BAGSUBSCRIPTION_UPDATED,
  BAGSUBSCRIPTIONS_UPDATED,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED,
  CLEAR_ITEMS,
  CART_RECEIVED,
  RECIPE_ADDED,
  RECIPE_REMOVED,
  RECIPE_QUANTITY_INCREASED,
  RECIPE_QUANTITY_DECREASED,
  BAGS_REMOVED,
  REFRESH_CART,
  CATERING_ADDED,
  CATERING_REMOVED,
  CATERINGS_REMOVED
} from '../../types/reducers/cart';
import CartItem from '../../lib/cartItem';

import {
  applyQueuedActions,
  debounceStart,
  addToQueue,
  setDebounce,
  queueReady,
  pendingFirstAdd,
  addToImediateQueue,
  addQueueToCart,
  clearQueue,
  clearCartAsync,
  syncCart,
  updateItemAsync,
  updateBagSubscription,
  deleteFromCartAsync,
  cartError,
  itemAddError,
  createCartAsync,
  handleStoreIdChange,
  cartLoading,
  refreshCalculations,
  setCateredMealsRefs
} from '../../actions/cart';
import { getUserOrders } from '../../actions/order';
import { open as openModal } from '../../actions/confirmModal';
import {
  addItemsToCart,
  addBagToCart,
  addRecipeToCart,
  removeItemsAndSetRecipes,
  removeRecipe,
  removeEditableRecipe,
  addCateredMealToCart
} from '../../api/endpoints';
import { MAGIC } from '../../types/reducers/cart';
import {
  toggleModalActive,
  changeDeliveryMethod
} from '../../actions/assortments';
import { prepareMoveCart, openNewCartModal } from '../../actions/newCartPrep';
import { isNumber } from '../../lib/number';
const requiresSync = [
  ITEM_ADDED,
  BAG_ADDED,
  BAG_REMOVED,
  ITEM_ADDED_WITH_DEBOUNCE,
  APPLY_QUEUED_ACTIONS,
  BAGSUBSCRIPTION_UPDATED,
  BAGSUBSCRIPTIONS_UPDATED,
  ITEM_REMOVED,
  ITEMS_REMOVED,
  BAGS_REMOVED,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED,
  CLEAR_ITEMS,
  RECIPE_ADDED,
  RECIPE_REMOVED,
  RECIPE_QUANTITY_INCREASED,
  RECIPE_QUANTITY_DECREASED,
  CART_RECEIVED,
  REFRESH_CART,
  CATERING_ADDED,
  CATERINGS_REMOVED,
  CATERING_REMOVED
];

const debounce = (fn, time = 500, ...args) => {
  const thunk = fn(...args);

  thunk.meta = {
    debounce: {
      time,
      key: 'CART_SYNC'
    }
  };

  return thunk;
};

class CartEnsurer {
  singletonPromise;

  clearPromise = () => {
    this.singletonPromise = null;
  };

  getCart = (state, dispatch) => {
    // Meta contains a cart already
    if (state.meta.id && state.meta.id.length > 0) {
      return Promise.resolve(state);
    }

    if (!state.storeNo) {
      dispatch(toggleModalActive(true));
    }

    // We are already creating a new cart, wait for it
    if (this.singletonPromise) {
      return this.singletonPromise;
    }

    // Nothing in queue
    this.singletonPromise = dispatch(getUserOrders())
      .then(cart => {
        if (cart) {
          return cart;
        } else {
          return dispatch(createCartAsync(state.storeNo));
        }
      })
      .then(res => {
        this.clearPromise();
        return res;
      });

    return this.singletonPromise;
  };
}

const ensureCart = new CartEnsurer();

const removeItemsAndSetRecipesFromCart = (
  state,
  dispatch,
  cartId,
  recipes,
  itemIds
) => {
  const activeHandler =
    state.recipes[
      state.recipes[MAGIC.recipe].recipes.length > 0 ? MAGIC.recipe : MAGIC.flex
    ];
  const idsToRemove = recipes?.map(({ variantId }) => variantId);
  const recipesToKeep = activeHandler.recipes.filter(
    ar => !idsToRemove.includes(ar.variantId)
  );
  const recipeBody = {
    recipes: recipesToKeep,
    cartItemId: activeHandler.id,
    recipesEAN: activeHandler.gtin,
    recipesItemNo: activeHandler.itemNo
  };
  return removeItemsAndSetRecipes(cartId, itemIds, recipeBody).then(cart =>
    dispatch(syncCart(cart.data))
  );
};

const bagActions = {
  [BAG_ADDED]: (state, action, dispatch, next) => {
    const { quantity, itemNo, name } = action.item;
    return ensureCart
      .getCart(state, dispatch)
      .then(res =>
        addBagToCart(
          res.id || res.meta.id,
          action.item.gtin,
          itemNo,
          quantity,
          name
        )
      )
      .then(({ data }) => {
        next(action);
        dispatch(syncCart(data));
      });
  },

  [BAG_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(deleteFromCartAsync(cart.meta.id, action.id));

    return next(action);
  },
  [BAGS_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(cartLoading(true));
    removeItemsAndSetRecipesFromCart(
      cart,
      dispatch,
      cart.meta.id,
      undefined,
      action.ids
    ).then(res => dispatch(cartLoading(false)));

    return next(action);
  }
};
const recipeActions = {
  [RECIPE_ADDED]: (state, action, dispatch, next) => {
    next(action);
    const { isFlexbag, productRefs, recipeRef, recipe } = action;

    // This is currently hardcoded to either a recipe bag or flex bag.
    // Might be more dynamic in the future.
    const recipesItemNo = isFlexbag ? MAGIC.flex : MAGIC.recipe;

    return ensureCart
      .getCart(state, dispatch)
      .then(cart => {
        // if we have a recipe bag in cart already we need to reuse
        // it's gtin to prevent multiple bags being created.
        let recipesEAN = null;
        if (
          cart.recipes &&
          cart.recipes[recipesItemNo] &&
          cart.recipes[recipesItemNo].gtin
        ) {
          recipesEAN = cart.recipes[recipesItemNo].gtin;
        }
        let cartItemId = null;
        if (
          cart.recipes &&
          cart.recipes[recipesItemNo] &&
          cart.recipes[recipesItemNo].id
        ) {
          cartItemId = cart.recipes[recipesItemNo].id;
        }
        return addRecipeToCart(
          {
            Recipe: recipe,
            RecipesNames: 'Recept',
            RecipesItemNo: recipesItemNo,
            recipesEAN: recipesEAN,
            cartItemId: cartItemId
          },
          cart.id || cart.meta.id
        );
      })
      .then(({ data }) => {
        // dispatch(setProductRefs(productRefs));
        // dispatch(setRecipeRefs([recipeRef]));
        dispatch(syncCart(data));
      })
      .catch(err => {
        dispatch(cartError(err));
        next(action);
        return Promise.reject(err);
      });
  },
  [RECIPE_REMOVED]: ({ meta, recipes }, action, dispatch, next) => {
    next(action);

    const { recipe } = action;
    const { id: variantId, recipeEAN, type } = recipe;
    const recipeBag = recipes[recipeEAN];

    // New recipe type
    if (type === 'Editable') {
      const indexInBag = recipeBag.editableRecipes.findIndex(
        recipe => recipe.variantId === variantId
      );
      return removeEditableRecipe(
        meta.id,
        recipeBag.id,
        indexInBag
      ).then(({ data }) => dispatch(syncCart(data)));
    }

    // Old recipe type

    let newRecipes = [];
    recipeBag.recipes.filter(r => r.variantId !== variantId);

    return removeRecipe(
      meta.id,
      recipeEAN,
      newRecipes,
      recipeBag.id
    ).then(({ data }) => dispatch(syncCart(data)));
  }
};

const cateredMealActions = {
  [CATERING_ADDED]: (state, action, dispatch, next) => {
    const { quantity, id, name, gtin, sideId } = action.cateredMeal;
    const isPortions = 'quantityTo' in quantity && 'quantityFrom' in quantity;
    return ensureCart
      .getCart(state, dispatch)
      .then(res =>
        addCateredMealToCart(
          res?.id || res?.meta?.id,
          gtin,
          id,
          { quantityFrom: isPortions ? 1 : quantity.quantityFrom },
          name,
          sideId ?? ''
        )
      )
      .then(({ data }) => {
        next(action);
        dispatch(
          setCateredMealsRefs([
            {
              ...action.cateredMeal,
              name: action?.cateredMeal?.variant,

              quantityFrom: isPortions
                ? action.cateredMeal?.quantity?.quantityFrom
                : null,
              quantityTo: isPortions
                ? action.cateredMeal?.quantity?.quantityTo
                : null,

              hasPortionsVariant: isPortions
            }
          ])
        );
        dispatch(syncCart(data));
      });
  },

  [CATERING_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(deleteFromCartAsync(cart.meta.id, action.id));

    return next(action);
  },
  [CATERINGS_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(cartLoading(true));
    removeItemsAndSetRecipesFromCart(
      cart,
      dispatch,
      cart.meta.id,
      undefined,
      action.ids
    ).then(res => dispatch(cartLoading(false)));

    return next(action);
  }
};
// TODO the debounce time might be too high. Probably room for some tuning
const actions = {
  ...recipeActions,
  ...bagActions,
  ...cateredMealActions,
  [ITEM_ADDED]: (state, action, dispatch, next) => {
    const { items } = state;
    let queue =
      items.debounceQueue.queue.length > 0
        ? items.debounceQueue.queue
        : action.queue;
    queue = queue.map(item => {
      const { quantity, itemNo, product } = item;
      // netContent is needed for some products like ( cheese ) with different weight factors
      const { netContent, name } = product;
      return {
        gtin: item.gtin,
        itemNo,
        quantity,
        netContent,
        name
      };
    });

    const handleQueue = () => {
      ensureCart
        .getCart(state, dispatch)
        .then(res => addItemsToCart(res.id || res.meta.id, queue))
        .then(({ data }) => {
          next(action);
          dispatch(syncCart(data));
        })
        .catch(() => {
          dispatch(itemAddError(queue.map(item => item.itemNo)));
        });
    };

    // Business rule: Do no combine normal items with homeDelivery
    if (state.deliveryMethod === 'homeDelivery' && state.bags.bags > 0) {
      return dispatch(
        openModal({
          title: 'Leveranserbjudandet',
          body:
            'Hemkörning gäller enbart matkassar, vill du ändra till butiksupphämtning?',
          confirmLabel: 'Hämta i butik',
          cancelLabel: 'Avbryt',
          onCancelClick: () => {
            dispatch(clearQueue());
            dispatch(itemAddError(queue.map(item => item.itemNo)));
          },
          onConfirmClick: () => {
            // We set store pickup
            dispatch(changeDeliveryMethod('pickupAtStore'));
            handleQueue();
          }
        })
      );
    }

    handleQueue();
  },

  [ITEM_ADDED_WITH_DEBOUNCE]: ({ items }, action, dispatch, next) => {
    const { debounceQueue } = items;
    dispatch(addToImediateQueue({ payload: action.item?.itemNo }));

    debounceQueue.debounceId && clearTimeout(debounceQueue.debounceId);
    const timeoutId = setTimeout(() => {
      dispatch(queueReady());
      dispatch(applyQueuedActions());
    }, debounceQueue.debounceTimeout);
    dispatch(debounceStart(timeoutId));
    dispatch(addToQueue(action.item));

    return next(action);
  },

  [APPLY_QUEUED_ACTIONS]: ({ items }, action, dispatch, next) => {
    const { debounceQueue, pendingFirstAdd } = items;
    /* Only do addQueueToCart if there is something in the queue,
     *  there's no pending first add requests, and the queue is ready to be sent.
     */
    if (
      debounceQueue.queue.length > 0 &&
      !pendingFirstAdd &&
      debounceQueue.queueReady
    ) {
      dispatch(addQueueToCart(debounceQueue.queue));
      dispatch(clearQueue());
    }
    debounceQueue.queue.length <= 0 && dispatch(clearQueue());

    return next(action);
  },

  [ITEM_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(deleteFromCartAsync(cart.meta.id, action.id));

    return next(action);
  },
  [ITEMS_REMOVED]: (cart, action, dispatch, next) => {
    dispatch(cartLoading(true));
    removeItemsAndSetRecipesFromCart(
      cart,
      dispatch,
      cart.meta.id,
      action.recipes,
      action.ids
    ).then(res => dispatch(cartLoading(false)));
    return next(action);
  },

  [BAGSUBSCRIPTION_UPDATED]: ({ items, meta }, action, dispatch, next) => {
    const fn = debounce(
      updateBagSubscription,
      500,
      meta.id,
      action.id,
      action.subscriptionType
    );

    dispatch(fn);
    next(action);
  },

  [QUANTITY_INCREASED]: ({ items, meta }, action, dispatch, next) => {
    // const { quantity } = items.find(i => i.id === action.id) || { quantity: defaultQuantity };
    const cartItem = CartItem.findOrDefault(items.items, action.id);
    const { quantity } = CartItem.incrementQuantity(cartItem, action.quantity);

    const fn = debounce(updateItemAsync, 500, meta.id, action.id, quantity);

    next(action);
    return dispatch(fn);
  },
  [QUANTITY_DECREASED]: ({ items, meta }, action, dispatch, next) => {
    const cartItem = CartItem.findOrDefault(items.items, action.id);
    const newCartItem = CartItem.decrementQuantity(cartItem, action.quantity);
    const fn = newCartItem.quantity.value
      ? debounce(updateItemAsync, 500, meta.id, action.id, newCartItem.quantity)
      : deleteFromCartAsync(meta.id, action.id);
    next(action);
    return dispatch(fn);
  },
  [CLEAR_ITEMS]: ({ meta }, action, dispatch, next) => {
    dispatch(clearCartAsync(meta.id));
    return next(action);
  },
  [CART_RECEIVED]: (state, action, dispatch, next) => {
    if (
      isNumber(action.payload.cart.storeNo) &&
      isNumber(state.storeNo) &&
      Number(state.storeNo) !== Number(action.payload.cart.storeNo) &&
      Boolean(action.payload?.cart?.id)
    ) {
      // store and cart does not match, prepare to move cart to current store
      dispatch(
        prepareMoveCart(
          action.payload.cart.id,
          state.storeNo,
          action.payload.cart,
          true
        )
      )
        .then(({ isDifferent }) => {
          if (!isDifferent) {
            // nothing is different, move cart
            dispatch(
              handleStoreIdChange(action.payload.cart.id, state.storeNo)
            );
          } else {
            dispatch(openNewCartModal());
          }
        })
        .catch(e => {
          dispatch(toggleModalActive(true));
        });
    }

    next(action);
  },

  [REFRESH_CART]: ({ items, meta }, action, dispatch, next) => {
    dispatch(refreshCalculations(action.payload.cartId, null));
  }
};

export const cartSync = ({ dispatch, getState }) => next => action => {
  if (!requiresSync.includes(action.type)) {
    return next(action);
  }

  const { cart, assortments } = getState();
  const fn = actions[action.type];
  return fn({ ...cart, ...assortments }, action, dispatch, next);
};
