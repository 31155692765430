import { SettingsPage } from '../episerver/settingsPage/SettingsPage';

const NAME = 'app';

export const FETCH_SETTINGS_RECEIVED = `${NAME}/FETCH_SETTINGS_RECEIVED`;
export const FETCH_SETTINGS_FAILED = `${NAME}/FETCH_SETTINGS_FAILED`;

export const TOGGLE_MOBILE_NAV = `${NAME}/TOGGLE_MOBILE_NAV`;
export const TOGGLE_SEARCH = `${NAME}/TOGGLE_SEARCH`;
export const TOGGLE_OVERLAY = `${NAME}/TOGGLE_OVERLAY`;
export const CLEAR_OVERLAY_CBS = `${NAME}/CLEAR_OVERLAY_CBS`;
export const TOGGLE_PORTAL_OVERLAY = `${NAME}/TOGGLE_PORTAL_OVERLAY`;
export const TOGGLE_MODAL = `${NAME}/TOGGLE_MODAL`;

export interface ISettingsReceivedAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
    payload: SettingsPage
}

export interface ISettingsFailedAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
    error: string
}

export interface IAppToggleMobileNavAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
}

export interface IAppToggleSearchAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
    payload: {
        active: boolean
    }
}

export interface IAppToggleOverlayAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
    payload: {
        active: boolean,
        includeHeader: boolean,
        cb: Function
    }
}

export interface IAppClearOverlayAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
}

export interface IAppTogglePortalOverlay {
    type: typeof FETCH_SETTINGS_RECEIVED;
    payload: {
        active: boolean
    }
}

export interface IAppToggleModalAction {
    type: typeof FETCH_SETTINGS_RECEIVED;
    payload: {
        active: boolean
    }
}

export interface AppState {
    settings: SettingsPage | null,
    settingsLoading: Boolean,
    mobileNav: { 
        isToggled: boolean
    },
    search: { 
        isToggled: boolean 
    },
    serviceLayer: { 
        isToggled: boolean 
    },
    overlay: {
        isToggled: boolean,
        cbs: Function[],
        includeHeader: boolean
    },
    portalOverlay: { 
        isToggled: boolean 
    },
    modal: { 
        isToggled: boolean
    },
    error: string
}
