import React, { Fragment, useMemo } from 'react';
import {
  checkPromotionPrice,
  formatPriceWithDecimalsReduced
} from '../../lib/price';
import cs from 'classnames';

import './PriceTag.scss';
import { IExtendedProductPrice } from '../../types/storefront/product';

interface IPriceTag {
  forceLeftAlign?: boolean;
  price?: IExtendedProductPrice | null;
  unit?: string;
  numberOfItems?: number;
  effectAmount?: number;
  isOnlyForMembers?: boolean;
  noPadding?: boolean;
  mixType?: string;
  plainPrice?: number;
  pant?: boolean;
  hasLimitedDeal?: boolean;
  noMargin?: boolean;
}
const PriceTag: React.FC<IPriceTag> = ({
  forceLeftAlign,
  price,
  plainPrice,
  unit,
  noPadding,
  pant,
  noMargin,
  hasLimitedDeal
}) => {
  const {
    formattedPrice,
    isExtraPrice,
    isOnlyForMembers,
    mixObject,
    isMix,
    mixMinQuantity,
    validPromotion
  } = checkPromotionPrice(price);

  return (formattedPrice && formattedPrice.value !== null) || plainPrice ? (
    <div
      className={cs('c-pricetag', {
        isExtraPrice: validPromotion && isExtraPrice,
        isOnlyForMembers: validPromotion && isOnlyForMembers,
        'isExtraPrice--prio':
          isOnlyForMembers && isExtraPrice && !hasLimitedDeal,
        'isExtraPrice--klipp': isExtraPrice && hasLimitedDeal,
        'isExtraPrice--is-mix': isMix,
        'no-padding': noPadding,
        'c-pricetag--plain-regular-price':
          !!plainPrice && isMix && !price?.memberPrice && !isExtraPrice,
        'no-margin': noMargin
      })}
    >
      {isOnlyForMembers && !hasLimitedDeal && (
        <div className={cs('c-pricetag__prioCircle')}>
          <p>PRIO</p>
        </div>
      )}

      {isExtraPrice && hasLimitedDeal && isOnlyForMembers && (
        <div
          className={cs('c-pricetag__prioCircle', 'c-pricetag__klippCircle')}
        >
          <p>PRIO Klipp!</p>
        </div>
      )}
      {isMix && mixMinQuantity && (
        <p className={'c-pricetag__effect-type'}>{`${mixMinQuantity} för`}</p>
      )}

      <div
        className={cs('value', {
          'no-unit': !unit,
          'value__mix-campaign': mixObject,
          'end-end': !isExtraPrice && !forceLeftAlign,
          'center-center': isExtraPrice && !forceLeftAlign
        })}
      >
        {!unit ||
        (pant && formattedPrice?.decimal === '00') ||
        (unit === 'st' && formattedPrice?.decimal === '00') ||
        (unit === 'st' &&
          mixMinQuantity &&
          formattedPrice?.decimal === '00') ? (
          <div className={'value__integer-wrapper'}>
            <span className="integer">
              {formattedPrice?.value || plainPrice}
              <div className={'integer__column'}>
                <span className={'value__price-marker'}>{':-'}</span>
                {pant && isExtraPrice && (
                  <span className="value__pant-unit">{'+pant'}</span>
                )}
              </div>
            </span>
          </div>
        ) : (
          <Fragment>
            <div className={'value__integer-wrapper'}>
              <span className="integer">{formattedPrice?.value}</span>
            </div>
            <div className={'value__fraction-unit-wrapper'}>
              <span className="decimal">,</span>
              <span className="fractions">{formattedPrice?.decimal}</span>
              {formattedPrice?.value && (
                <span className="unit">
                  {pant ? (
                    '+pant'
                  ) : mixMinQuantity ? (
                    <span className="unit-placeholder" />
                  ) : (
                    `/${unit}`
                  )}
                </span>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  ) : (
    <div className="value">&nbsp;</div>
  );
};

export default PriceTag;
