import React from 'react';
import cs from 'classnames';
import RoundButton from '../../RoundButton';
import './CloseButton.scss';

interface IProps {
  onClick: Function,
  theme?: 'light' | 'transparent',
  small?: boolean,
  mini?: boolean,
  white?: boolean
}

export const CloseButton : React.FC<IProps> = ({ onClick, theme, small, mini, white }) => (
  <RoundButton
    className={cs("c-closebtn", { white })}
    theme={theme}
    small={small}
    mini={mini}
    onClick={onClick}
  />
);
