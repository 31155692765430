import React from 'react';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { getNextAvailabilityDateForStore } from '../../lib/availability';
import { dateAsFormat } from '../../lib/delivery';
import './Availability.scss';
import { ProductAvailability } from '../../types/xim/product';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';

const HighlightIcon = require('../assets/highlight-icon.svg');

interface IProps {
  availability: ProductAvailability[];
  text?: string;
}

const Availability: React.FC<IProps> = ({
  availability,
  text = 'Åter i lager'
}) => {
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const nextAvailabiltyDate = getNextAvailabilityDateForStore(
    availability,
    storeNumber
  );
  if (!nextAvailabiltyDate) {
    return null;
  }

  return (
    <div className="c-availability">
      <img className="c-availability__icon" src={HighlightIcon} alt="" />
      <span className="c-availability__text">
        {text} {dateAsFormat(new Date(nextAvailabiltyDate), 'd/M')}
      </span>
    </div>
  );
};

export default Availability;
