import React, { useCallback, useEffect, useMemo, useState } from 'react';
import withBaseBlock from '../../components/BaseBlock';
import { connect } from 'react-redux';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { pageLayoutSelector } from '../../selectors/page';
import { fetchCateringProductById } from '../../api/endpoints/cateredmeals';
import { sendCateringItemAddTracking } from '../../lib/analytics/analytics';
import { setVariant } from '../../lib/catering';
import { redirectToLogin } from '../../actions/app';
import { authenticatedSelector } from '../../selectors/user';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';
import CateringCard from '../../components/CateringCard';
import { TCateringProductCard } from '../../types/xim/catering';
import { mapCateredMealToProductCard } from '../../lib/catering.js';
import ProductCarousel from '../../components/ProductCarousel';
import { ProdCard as ProdCardSkeleton } from '../../components/Skeletons/ProdCard';
import ProductArticleSkeleton from '../../components/ProductArticleSkeleton';

type TCateringArticleBlock = {
  id: number;
  storeId: number;
  blockOptions?: {
    marginTopBottom?: string;
    useFlushContainer?: boolean;
    width?: string;
    backgroundColor?: string;
  };
  ispublished: boolean;
  link?: {
    internal: boolean;
    url: string;
  };
  linkText: string;
  fontColor: string;
  name: string;
  numRows: string;
  title: string;
  type: string;
  ximEntitiesList: string[];
  paginationEnabled: boolean;
  __type__: string;
  isCarousel: boolean;
  isAuthenticated: boolean;
  redirectToLogin: () => void;
  maxItems: number;
};

const CateringArticleBlock = ({
  storeId,
  ximEntitiesList,
  name,
  isAuthenticated,
  redirectToLogin,
  maxItems,
  title,
  blockOptions
}: TCateringArticleBlock) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingFailed, setFetchingFailed] = useState<boolean>(false);
  const [fetchedProducts, setFetchedProducts] = useState<
    TCateringProductCard[]
  >([]);

  const fetchProducts = useCallback(() => {
    if (ximEntitiesList && ximEntitiesList.length > 0) {
      setLoading(true);
      fetchCateringProductById(
        ximEntitiesList?.map(c => c.replace('c', ''))?.join(',')
      )
        .then(data => {
          if (data.data.length === 0) {
            setFetchingFailed(true);
            return;
          }
          setLoading(false);
          setFetchingFailed(false);
          setFetchedProducts(mapCateredMealToProductCard(data.data, storeId));
        })
        .catch(() => setFetchingFailed(true));
    }
  }, [ximEntitiesList, storeId]);
  useEffect(() => {
    fetchProducts();
  }, [storeId]);

  const CateringCards = useMemo(
    () =>
      fetchedProducts?.map((product, index) => {
        const hasSides = product.sides && product.sides.length > 0;
        const hasVariants =
          product.variants && Object.keys(product.variants).length > 0;

        const isBuffe = !!product.minOrder && !!product.maxOrder;
        const variants = Object.keys(product.variants);
        const isStandard = variants.findIndex(
          variant => variant.toLowerCase() === 'standard'
        );

        return hasVariants && product.sellable ? (
          <CateringCard
            index={index}
            id={product.id}
            key={product.id}
            title={product.name}
            label={product.label}
            singularLabel={product.singularLabel}
            description={product.description}
            storeId={storeId}
            unit={product.unit}
            variants={hasVariants && product.variants}
            hasVariants={hasVariants}
            hasSides={hasSides}
            sides={(hasSides && product.sides) || []}
            url={product.url}
            productImageUrl={product.image.url}
            isBuffe={isBuffe}
            minOrder={product.minOrder}
            maxOrder={product.maxOrder}
            sendCateringItemAddTracking={orderlines =>
              sendCateringItemAddTracking(product, orderlines, index + 1)
            }
            defaultVariant={variants[isStandard] || variants[0]}
            setVariant={(variantType, selectedVariant) =>
              setVariant(product.id, variantType, selectedVariant)
            }
            isAuthenticated={isAuthenticated}
            redirectToLogin={redirectToLogin}
            esalesClickTrigger={
              product.ticket
                ? () => ticketNotifierByClick(product.ticket)
                : () => {}
            }
            esalesClickTriggerOnAddingToCart={
              product.ticket
                ? () => ticketNotifierByAddingToCard(product.ticket)
                : () => {}
            }
            availability={product.availability}
            impressionListName={name}
            impressionListPosition={index + 1}
            onCarousel
          />
        ) : null;
      }),
    [fetchedProducts]
  );

  if (fetchingFailed) {
    return null;
  }

  if (fetchedProducts.length === 0 || loading) {
    return (
      <ProductArticleSkeleton
        loading={loading}
        productsLength={fetchedProducts.length}
        maxItems={maxItems}
      />
    );
  }
  const products = fetchedProducts?.filter(c => {
    const hasSides = c?.sides && c.sides?.length > 0;
    return hasSides && c.sellable;
  });
  return (
    <div style={{ backgroundColor: blockOptions?.backgroundColor }}>
      <h2>{title}</h2>
      <ProductCarousel productsLength={products?.length}>
        {CateringCards}
      </ProductCarousel>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    pageLayout: pageLayoutSelector(state, props),
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {
  redirectToLogin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(CateringArticleBlock));
