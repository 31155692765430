import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import getISOWeek from 'date-fns/getISOWeek';
import { Tabs } from '../Tabs';
import './WeekPagination.scss';

export const createWeekArray = (dateToday, nbrOfWeeks) => {
  const startDate = new Date(dateToday);
  startDate.setDate(startDate.getDate() - 14);

  const weeks = [];
  for (let i = 0; i < nbrOfWeeks; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + 7 * i);
    weeks.push(date);
  }

  return weeks;
};

export const WeekPagination = ({
  dateToday,
  selectedWeek,
  nbrOfWeeks,
  isMobile,
  onPaginate
}) => {
  const weeks = createWeekArray(dateToday, nbrOfWeeks);

  return (
    <Tabs
      tabs={weeks.map(date => {
        const week = getISOWeek(date);

        return {
          id: date,
          active: week === (selectedWeek || getISOWeek(dateToday)),
          disabled: false,
          label: isMobile ? `V${week}` : `Vecka ${week}`
        };
      })}
      onTabClick={onPaginate}
    />
  );
};

WeekPagination.propTypes = {
  dateToday: PropTypes.instanceOf(Date).isRequired,
  selectedWeek: PropTypes.number,
  nbrOfWeeks: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onPaginate: PropTypes.func.isRequired
};

WeekPagination.defaultProps = {
  dateToday: new Date(),
  nbrOfWeeks: 5,
  isMobile: false
};

const WeekPaginationMediaQueryWrapper = props => (
  <MediaQuery maxWidth={767}>
    {isMobile => <WeekPagination isMobile={isMobile} {...props} />}
  </MediaQuery>
);

export default WeekPaginationMediaQueryWrapper;
