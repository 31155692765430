import { sendTracking, sendCustomGaEvent } from '../../lib/analytics/analytics';
import { domSafe } from '../../lib/document';
import { ProductType, PurchaseDetailsType } from 'dynamicdog-analytics';
import {
  ITEM_ADDED,
  ITEM_ADDED_WITH_DEBOUNCE,
  ITEM_REMOVED,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED,
  REPLACEMENT_MODE_ENTERED,
  BAG_ADDED,
  BAG_REMOVED,
  RECIPE_ADDED,
  RECIPE_REMOVED,
  CATERING_REMOVED
  // CLEAR_ITEMS
} from '../../types/reducers/cart';
import { generateGA4ItemsFromEecProducts } from '../../lib/analytics/analytics';
import { fetchRecipeVariantPrice } from '../../api/endpoints/recipe';

import { TOGGLE_LAST_SELECTED } from '../../types/reducers/assortments';
import * as ga4 from '@citygross/analytics';
import Cookie from 'js-cookie';
import { fetchStoreNumberBySiteId } from '../../api/endpoints/delivery';

export function formatPrice(price) {
  return isNaN(price) ? 0 : parseFloat(price).toFixed(2);
}

export const analytics = ({ getState }) => next => action => {
  switch (action.type) {
    case REPLACEMENT_MODE_ENTERED: {
      sendTracking({
        event: 'edit_order'
      });
      break;
    }
    case TOGGLE_LAST_SELECTED: {
      if (action.active) {
        sendCustomGaEvent({
          label: 'delivery-banner',
          event: {
            bannerVisible: true
          }
        });
      }
      break;
    }
    case QUANTITY_DECREASED: // eslint-disable-line no-fallthrough
    case ITEM_REMOVED: {
      // TODO: Merge with addToCart logic below this..
      const state = getState();

      const combiningProdCart = () => {
        const cartRef = state.cart.items.items.find(
          item => item.id === action.id
        );
        const prodRef = state.cart.items.productRefs[cartRef?.itemNo];
        return {
          ...prodRef,
          ...cartRef
        };
      };

      const product = combiningProdCart();

      if (typeof product === 'undefined') {
        break;
      }

      const removedQty = product?.quantity?.value - action.quantity || 1;

      try {
        const productCategory =
          product.url?.substring(0, product.url.lastIndexOf('/') + 1) ||
          undefined;

        const ga4Item = {
          value: Number(formatPrice(product.netPrice * removedQty)),
          currency: 'SEK',
          items: [
            {
              item_id: product.gtin,
              item_name: product.name,
              quantity: removedQty,
              item_brand: product.brand || 'City Gross',
              item_category: productCategory,
              item_variant: product.variant || null,
              price: Number(
                formatPrice(product.price?.gross || product.normalPrice)
              ),
              discount: Number(formatPrice(product.totalDiscountAmount || 0))
            }
          ]
        };

        ga4.removeFromCart(ga4Item);
      } catch (error) {
        console.error(error);
      }

      break;
    }
    // case CLEAR_ITEMS: {
    //   const test = action;
    //   const state = getState();
    //   console.log(state)

    //   break;
    // }

    default:
      // Do nothing.
      break;
  }

  // These actions need to run after the cart state has changed, while the above code needs to run before.
  // Using setTimeout solves this problem for now.
  setTimeout(() => {
    switch (action.type) {
      case ITEM_ADDED: {
        try {
          const { cart } = getState();
          const productInfo = action.queue.map(item => {
            return {
              itemNo: item.itemNo,
              brand: item.product?.brand,
              url: item.product?.url,
              variant: item.product?.variant
            };
          });

          productInfo.forEach(product => {
            const cartItem = cart.items.items.find(
              item => item.itemNo === product.itemNo
            );
            const ga4Item = {
              value: Number(formatPrice(cartItem.amount)),
              currency: 'SEK',
              items: [
                {
                  item_id: cartItem.gtin,
                  item_name: cartItem.name,
                  quantity: cartItem.quantity?.value || 1,
                  item_brand: product.brand || 'City Gross',
                  item_category:
                    product.url?.substring(
                      0,
                      product.url.lastIndexOf('/') + 1
                    ) || undefined,
                  item_variant:
                    product.variant || cartItem.variant || undefined,
                  price: Number(
                    formatPrice(
                      cartItem.price?.gross ||
                        cartItem.netPrice ||
                        cartItem.normalPrice
                    )
                  ),
                  discount: cartItem.totalDiscountAmount || 0
                }
              ]
            };

            ga4.addToCart(ga4Item);
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }

      case QUANTITY_INCREASED:
      case ITEM_ADDED_WITH_DEBOUNCE: {
        const state = getState();
        // flytta till remove? uppe

        const combiningProdCart = () => {
          const cartRef = state.cart.items.items.find(
            item => item.id === action.id
          );
          const prodRef = state.cart.items.productRefs[cartRef?.itemNo];

          return {
            ...prodRef,
            ...cartRef
          };
        };
        const product =
          action.type === ITEM_ADDED_WITH_DEBOUNCE
            ? action.item.product
            : combiningProdCart();

        if (typeof product === 'undefined') {
          break;
        }

        try {
          const qty = action.quantity - product?.quantity?.value || 1;

          if (action.type === QUANTITY_INCREASED) {
            const ga4Item = {
              value: Number(
                formatPrice(product.price?.gross || product.normalPrice)
              ),
              currency: 'SEK',
              items: [
                {
                  item_id: product.gtin,
                  item_name: product.name,
                  quantity: qty,
                  item_brand: product.brand || 'City Gross',
                  item_category:
                    product.url?.substring(
                      0,
                      product.url.lastIndexOf('/') + 1
                    ) || undefined,
                  item_variant: product.variant || undefined,
                  price: Number(
                    formatPrice(product.price?.gross || product.normalPrice)
                  ),
                  discount: product.totalDiscountAmount || 0
                }
              ]
            };
            ga4.addToCart(ga4Item);
          }
        } catch (e) {
          console.error(e);
        }

        break;
      }
      case BAG_ADDED: {
        try {
          const bag = action.bag;
          const item = action.item;

          const bagVariant = bag.variants.find(x => x.id === item.itemNo);
          const prices = bagVariant.prices[0];
          const bagCategory =
            bag.url?.substring(0, bag.url.lastIndexOf('/') + 1) || undefined;

          const ga4BagItem = {
            value: prices?.currentPrice,
            currency: 'SEK',
            items: [
              {
                item_id: item.itemNo,
                item_name: bag.name,
                price: prices?.ordinaryPrice,
                discount: prices?.ordinaryPrice - prices?.currentPrice || 0,
                quantity: 1,
                item_brand: 'City Gross',
                item_category: bagCategory,
                item_variant: item.itemNo
              }
            ]
          };

          ga4.addToCart(ga4BagItem);
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case BAG_REMOVED: {
        try {
          const bag = action.bag;

          const bagVariant = bag.variants.find(x => x.id === action.itemNo);
          const prices = bagVariant.prices[0];
          const bagCategory =
            bag.url?.substring(0, bag.url.lastIndexOf('/') + 1) || undefined;

          const ga4BagItem = {
            value: prices?.currentPrice,
            currency: 'SEK',
            items: [
              {
                item_id: action.itemNo,
                item_name: bag.name,
                price: prices?.ordinaryPrice,
                discount: prices?.ordinaryPrice - prices?.currentPrice || 0,
                quantity: 1,
                item_brand: 'City Gross',
                item_category: bagCategory,
                item_variant: action.itemNo
              }
            ]
          };

          ga4.removeFromCart(ga4BagItem);
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RECIPE_ADDED: {
        try {
          const products = action.productRefs;
          const recipe = action.recipe;
          const recipeRef = action.recipeRef;

          const recipeCategory =
            '/recept' +
              recipeRef.url?.substring(0, recipeRef.url.lastIndexOf('/') + 1) ||
            undefined;

          const productData: ProductType[] = [
            {
              id: recipe.recipeId,
              name: recipe.name,
              category: recipeCategory,
              variant: recipe.variantId,
              dimension9: `${recipe.numberOfMeals} portioner`,
              quantity: 1,
              price: 0
            },
            ...products.map(product => {
              const quantity = recipe.items.find(x => x.itemNo === product.id)
                .quantity.value;
              return {
                id: product.gtin,
                name: product.name,
                category:
                  product.url?.substring(0, product.url.lastIndexOf('/') + 1) ||
                  undefined,
                variant: null,
                quantity: quantity || 1,
                price:
                  product.prices &&
                  product.prices.length > 0 &&
                  product.prices[0].currentPrice.price,
                dimension8:
                  product.prices && product.prices.length > 0
                    ? product.prices[0].currentPrice <
                      product.prices[0].ordinaryPrice.price
                      ? 'On sale'
                      : 'Not On Sale'
                    : 'Not On Sale',
                dimension10: recipe.name
              };
            })
          ];

          const { assortments } = getState();

          fetchRecipeVariantPrice(
            recipe.recipeId,
            recipe.numberOfMeals,
            recipe.organic,
            recipe.lactoseFree,
            recipe.glutenFree,
            recipe.vegetarian,
            recipe.vegan,
            assortments.storeNo
          )
            .then(response => {
              const recipe = response.data.results.find(
                result => result.id === recipeRef.id
              );
              const ingredientGroup = recipe.ingredientGroups.find(
                group => group.type === 'City Gross'
              );
              return ingredientGroup.ingredients;
            })
            .then(ingredients => {
              const ga4Items = products.map(prod => {
                const ingredient = ingredients.find(ingredient => {
                  return Object.keys(ingredient.articles).some(
                    key => key === prod.id
                  );
                });

                const productCategory =
                  prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                  undefined;

                const productQuantity =
                  recipe.items.find(x => x.itemNo === prod.id).quantity.value ||
                  1;

                return {
                  item_id: prod.gtin,
                  item_name: prod.name,
                  item_brand: prod.brand || 'City Gross',
                  item_category: productCategory,
                  item_variant: prod.variant || undefined,
                  discount: prod.totalDiscountAmount || 0,
                  quantity: productQuantity,
                  price: ingredient.price / productQuantity
                };
              });
              return ga4Items;
            })
            .then(ga4Items => {
              const total = ga4Items.reduce((total, currentItem) => {
                return (total += currentItem.quantity * currentItem.price);
              }, 0);
              const ga4CartItem = {
                value: Number(formatPrice(total)),
                currency: 'SEK',
                items: [
                  ...ga4Items,
                  {
                    item_id: recipe.recipeId,
                    item_name: recipe.name,
                    price: 0,
                    discount: 0,
                    quantity: 1,
                    item_brand: recipeRef.source || 'City Gross',
                    item_category: recipeCategory,
                    item_variant: recipe.variantId
                  }
                ]
              };
              ga4.addToCart(ga4CartItem);
            })
            .catch(err => {
              console.error(err);

              const total = productData.reduce((total, currProd) => {
                return (total += currProd.price * currProd.quantity);
              }, 0);

              ga4.addToCart({
                value: Number(formatPrice(total)),
                currency: 'SEK',
                items: generateGA4ItemsFromEecProducts(productData)
              });
            });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case RECIPE_REMOVED: {
        try {
          const { cart } = getState();
          const recipe = action.recipe;

          const recipeCategory =
            '/recept' +
              recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
            undefined;

          const cartRecipe = cart.recipes.receptkassen.editableRecipes.find(
            editableRecipe => {
              return editableRecipe.recipeId === recipe.recipeId;
            }
          );

          const ga4ItemsToRemove = cartRecipe.editableItems.map(item => {
            const cartItem = cart.items.items.find(
              cartItem => cartItem.itemNo === item.itemNo
            );

            const productRef = Object.values(cart.items.productRefs).find(
              prod => prod.id === item.itemNo
            );

            const productCategory =
              productRef && productRef.url
                ? productRef.url?.substring(
                    0,
                    productRef.url.lastIndexOf('/') + 1
                  )
                : undefined;

            const discount =
              cartItem.totalDiscountAmount === 0 ||
              cartItem.quantity.value == item.quantity.value
                ? cartItem.totalDiscountAmount
                : (cartItem.totalDiscountAmount / cartItem.quantity.value) *
                  item.quantity.value;

            return {
              item_id: item.gtin,
              item_name: item.name,
              price: Number(
                formatPrice(
                  cartItem.price?.gross ||
                    cartItem.netPrice ||
                    cartItem.normalPrice
                )
              ),
              quantity: item.quantity.value,
              item_brand: productRef?.brand || 'City Gross',
              item_category: productCategory,
              item_variant:
                cartItem.variant || productRef?.variant || undefined,
              discount: Number(formatPrice(discount))
            };
          });

          ga4ItemsToRemove.push({
            item_id: recipe.recipeId,
            item_name: recipe.name,
            price: 0,
            quantity: 1,
            item_brand: recipe.source || 'City Gross',
            item_category: recipeCategory,
            item_variant: recipe.id,
            discount: 0
          });

          const total = ga4ItemsToRemove.reduce((total, currentItem) => {
            return (total += currentItem.price);
          }, 0);

          ga4.removeFromCart({
            value: Number(formatPrice(total)),
            currency: 'SEK',
            items: ga4ItemsToRemove
          });
        } catch (error) {
          console.error(error);
        }
        break;
      }
      case CATERING_REMOVED: {
        const { cateredmeal } = action;
        const productCategory =
          cateredmeal?.cateredMeal?.url?.substring(
            0,
            cateredmeal?.cateredMeal?.url.lastIndexOf('/') + 1
          ) || undefined;
        const isCake =
          productCategory?.includes('tartor') &&
          !productCategory?.includes('personer');

        const ga4Item = {
          value: Number(
            formatPrice(cateredmeal.netPrice * cateredmeal?.quantity?.value)
          ),
          currency: 'SEK',
          items: [
            {
              item_id: cateredmeal.gtin,
              item_name: `${cateredmeal.name} ${
                isCake
                  ? `- ${cateredmeal?.cateredMeal?.quantity?.quantityFrom ??
                      cateredmeal?.cateredMeal?.quantityFrom} portioner`
                  : ''
              }`,
              quantity: cateredmeal?.quantity?.value,
              item_brand: 'City Gross',
              item_category: productCategory,
              item_variant: cateredmeal?.cateredMeal?.name || null,
              price: Number(formatPrice(cateredmeal.netPrice)),
              discount: Number(formatPrice(0))
            }
          ]
        };

        ga4.removeFromCart(ga4Item);
        break;
      }
      case '@@router/LOCATION_CHANGE': {
        // const { isFirstRendering } = action.payload;
        const state = getState();

        if (domSafe()) {
          sendTracking({
            event: 'pageview',
            pagePath:
              action.payload.location.pathname + action.payload.location.search,
            pageHostname: location.hostname,
            pageTitle: document.title,
            userId: state.auth.user?.settings?.id || undefined
          });
        }
        break;
      }
      default:
        // Do nothing.
        break;
    }
  });

  return next(action);
};

export const viewBag = (item, activeVariant) => {
  try {
    const bagCategory =
      item.url?.substring(0, item.url.lastIndexOf('/') + 1) || undefined;

    const assortmentValuesCookie = Cookie.get('assortment_values');
    const assortmentValues = JSON.parse(assortmentValuesCookie);

    const ga4Bag = {
      item_id: activeVariant.id,
      item_name: item.name,
      price: item.fromPrice,
      quantity: 1,
      item_brand: 'City Gross',
      item_category: bagCategory,
      item_variant: activeVariant.id,
      discount: 0
    };

    if (assortmentValues.userSiteId) {
      fetchStoreNumberBySiteId(assortmentValues.userSiteId)
        .then(response => {
          const storeNumber = response.data?.storeNumber;
          if (storeNumber) {
            const prices = activeVariant.prices.find(
              store => store.storeNumber == storeNumber
            );
            if (prices.currentPrice !== prices.ordinaryPrice) {
              ga4Bag.discount = prices.ordinaryPrice - prices.currentPrice;
            }
            ga4.viewItemDetails({
              value: prices.currentPrice,
              currency: 'SEK',
              items: [ga4Bag]
            });
          }
          ga4.viewItemDetails({
            value: item.fromPrice,
            currency: 'SEK',
            items: [ga4Bag]
          });
        })
        .catch(err => {
          console.error(err);
          ga4.viewItemDetails({
            value: item.fromPrice,
            currency: 'SEK',
            items: [ga4Bag]
          });
        });
    } else {
      ga4.viewItemDetails({
        value: item.fromPrice,
        currency: 'SEK',
        items: [ga4Bag]
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const viewRecipe = (recipe, numberOfMeals) => {
  try {
    const recipeCategory =
      '/recept' + recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
      undefined;

    const assortmentValuesCookie = Cookie.get('assortment_values');
    const assortmentValues = JSON.parse(assortmentValuesCookie);

    fetchStoreNumberBySiteId(assortmentValues.userSiteId)
      .then(response => {
        const storeNumber = response.data?.storeNumber;
        return fetchRecipeVariantPrice(
          recipe.id,
          numberOfMeals,
          false,
          false,
          false,
          false,
          false,
          storeNumber
        );
      })
      .then(response => {
        const result = response.data.results[0];
        return result?.minimumPrice;
      })
      .then(minimumPrice => {
        ga4.viewItemDetails({
          value: minimumPrice,
          currency: 'SEK',
          items: [
            {
              item_id: recipe.id,
              item_name: recipe.name,
              price: minimumPrice,
              quantity: 1,
              item_brand: recipe.source || 'City Gross',
              item_category: recipeCategory,
              item_variant: recipe.id,
              discount: 0
            }
          ]
        });
      })
      .catch(err => {
        console.error(err);
        ga4.viewItemDetails({
          value: undefined,
          currency: 'SEK',
          items: [
            {
              item_id: recipe.id,
              item_name: recipe.name,
              price: undefined,
              quantity: 1,
              item_brand: recipe.source || 'City Gross',
              item_category: recipeCategory,
              item_variant: recipe.id,
              discount: 0
            }
          ]
        });
      });
  } catch (error) {
    console.error(error);
  }
};
