import React, { Component } from 'react';
import { LoadMoreToolbar } from '../../components/LoadMoreToolbar';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  fetchProductsByTags,
  fetchProductsByIds
} from '../../api/endpoints/product';
import withBaseBlock from '../../components/BaseBlock';
import ProductGrid from '../../modules/ProductGrid';
import { connect } from 'react-redux';
import { pageLayoutSelector } from '../../selectors/page';
import { encode } from 'js-base64';

const applyTagsToObjectValues = tags => {
  const modifiedObj = Object.keys(tags).map(key => {
    const newKey = `${key}`;
    if (typeof tags[key] !== 'boolean' && tags[key] !== 'GARANT') {
      return {
        [newKey]:
          typeof tags[key] == 'object'
            ? tags[key].map(t => encode(t))
            : encode(tags[key])
      };
    }
    return { [newKey]: tags[key] };
  });

  return Object.assign({}, ...modifiedObj);
};

class ProductArticleBlock extends Component {
  state = {
    fetchedProducts: null,
    pagination: null,
    allRows: false,
    loading: true,
    fetchingFailed: false,
    currentStoreId: null
  };

  componentDidUpdate(prevProps) {
    if (
      this.state.currentStoreId !== this.props.storeId &&
      !this.state.loading
    ) {
      this.fetchProducts();
    }
  }
  componentDidMount() {
    this.fetchProducts();
  }

  loadMoreProducts = () => {
    if (!this.state.allRows) {
      return this.setState({
        allRows: true
      });
    }
    this.setState({
      loading: true
    });
    const { tags, storeId } = this.props;

    // add pagination to this queryString
    const modifiedTags = qs.stringify(applyTagsToObjectValues(tags));
    const newPageIndex = this.state.pagination.pageIndex + 1;
    fetchProductsByTags(modifiedTags, storeId, newPageIndex).then(
      ({ data }) => {
        this.setState({
          fetchedProducts: [...this.state.fetchedProducts, ...data.data],
          pagination: data.meta,
          loading: false
        });
      }
    );
  };

  fetchProducts = () => {
    this.setState({ loading: true });
    const { tags, ximEntitiesList, storeId } = this.props;
    if (ximEntitiesList && ximEntitiesList.length > 0) {
      fetchProductsByIds(ximEntitiesList)
        .then(data => {
          if (data.data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }

          this.setState({
            fetchedProducts: data.data,
            fetchingFailed: false,
            loading: false,
            currentStoreId: storeId
          });
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    } else {
      const modifiedTags = qs.stringify(applyTagsToObjectValues(tags));
      fetchProductsByTags(modifiedTags, storeId)
        .then(({ data }) => {
          if (data.data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }

          this.setState({
            fetchedProducts: data.data,
            pagination: data.meta,
            fetchingFailed: false,
            loading: false,
            currentStoreId: storeId
          });
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    }
  };
  render() {
    const {
      ximEntitiesList,
      paginationEnabled,
      tags,
      fontColor,
      isCarousel,
      ...rest
    } = this.props;
    const {
      pagination,
      fetchedProducts,
      allRows,
      loading,
      fetchingFailed
    } = this.state;

    if (fetchingFailed) {
      return null;
    }

    return (
      <div>
        <ProductGrid
          impressionListName={this.props.name}
          products={fetchedProducts}
          tags={tags}
          allRows={allRows}
          loading={loading}
          fontColor={fontColor}
          storeId={this.state.currentStoreId || this.props.storeId}
          noPad
          isCarousel={isCarousel}
          {...rest}
        />
        {paginationEnabled && !ximEntitiesList && this.state.fetchedProducts && !isCarousel &&  (
          <div style={{ paddingBottom: '30px' }}>
            <LoadMoreToolbar
              hideArticleCount={this.state.allRows}
              totalCount={pagination.totalCount}
              count={fetchedProducts.length}
              requestMore={this.loadMoreProducts}
              fetching={loading}
            />
          </div>
        )}
      </div>
    );
  }
}

ProductArticleBlock.propTypes = {
  id: PropTypes.number,
  storeId: PropTypes.number,
  tags: PropTypes.shape({
    category: PropTypes.string,
    superCategory: PropTypes.string,
    bfCategory: PropTypes.string,
    bfCategoryCode: PropTypes.string,
    tags: PropTypes.array,
    raw: PropTypes.string,
    discountonly: PropTypes.bool
  }),
  blockOptions: PropTypes.shape({
    marginTopBottom: PropTypes.string,
    useFlushContainer: PropTypes.bool,
    width: PropTypes.string,
    backgroundColor: PropTypes.string
  }),
  ispublished: PropTypes.bool,
  link: PropTypes.shape({
    internal: PropTypes.bool,
    url: PropTypes.string
  }),
  linkText: PropTypes.string,
  fontColor: PropTypes.string,
  name: PropTypes.string,
  numRows: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  ximEntitiesList: PropTypes.array,
  paginationEnabled: PropTypes.bool,
  __type__: PropTypes.string,
  isCarousel: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    pageLayout: pageLayoutSelector(state, props)
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(ProductArticleBlock));
