import React from 'react';
import PropTypes from 'prop-types';
import BagItem from './BagItem';
import './BagList.scss';
import * as ga4 from '@citygross/analytics';
import { ga4ImpressionClickTracking_Bag } from '../../lib/analytics/analytics';

const impressionListName = 'Bag List';

class BagList extends React.PureComponent {
  componentDidMount() {
    const { bags, listName } = this.props;
    if (!bags || !bags.length > 0) {
      return;
    }
    try {
      const ga4ImpressionItems = bags.map((bag, index) => {
        return {
          item_id: bag.variants[0]?.id,
          item_name: bag.name,
          item_brand: bag.brand || 'City Gross',
          item_category:
            bag.url?.substring(0, bag.url.lastIndexOf('/') + 1) || undefined,

          item_variant: bag.variants[0]?.id,

          item_list_name: listName || impressionListName,
          item_list_id: listName || impressionListName,
          index: index + 1,

          price: bag.fromPrice,
          quantity: 1,
          discount: 0
        };
      });

      ga4.viewItemList({
        items: ga4ImpressionItems,
        item_list_id: listName || impressionListName,
        item_list_name: listName || impressionListName
      });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const { bags, listName } = this.props;
    try {
      if (bags && prevProps.bags.length !== bags.length) {
        const ga4ImpressionItems = bags.map((bag, index) => {
          return {
            item_id: bag.variants[0]?.id,
            item_name: bag.name,
            item_brand: bag.brand || 'City Gross',
            item_category:
              bag.url?.substring(0, bag.url.lastIndexOf('/') + 1) || undefined,

            item_variant: bag.variants[0]?.id,

            item_list_name: listName || impressionListName,
            item_list_id: listName || impressionListName,
            index: index + 1,

            price: bag.fromPrice,
            quantity: 1,
            discount: 0
          };
        });

        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: listName || impressionListName,
          item_list_name: listName || impressionListName
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const { bags, listName } = this.props;

    return (
      <div className="c-baglist">
        {bags.map((bag, index) => (
          <BagItem
            key={bag.id}
            bag={bag}
            onBagItemSelect={() => {
              try {
                ga4ImpressionClickTracking_Bag({
                  bag,
                  impressionListName: listName || impressionListName,
                  impressionListPosition: index + 1
                });
              } catch (error) {
                console.error(error);
              }
            }}
          />
        ))}
      </div>
    );
  }
}

BagList.propTypes = {
  bags: PropTypes.array
};

export default BagList;
