import {
  FETCH_BAG_REQUESTED,
  FETCH_BAG_RECEIVED,
  FETCH_BAG_FAILED,
  FETCH_BAGS_REQUESTED,
  FETCH_BAGS_RECEIVED,
  FETCH_BAGS_FAILED,
  FETCH_BAGVARIANTWEEK_REQUESTED,
  FETCH_BAGVARIANTWEEK_RECEIVED,
  FETCH_BAGVARIANTRECIPES_RECEIVED,
  FETCH_BAGVARIANTWEEK_FAILED,
  FETCH_BAGVARIANTCURRENTWEEK_REQUESTED,
  FETCH_BAGVARIANTCURRENTWEEK_RECEIVED,
  FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED,
  FETCH_BAGVARIANTCURRENTWEEK_FAILED
} from '../../types/reducers/bag';

export function isFetchingBag(fetching) {
  return {
    type: FETCH_BAG_REQUESTED,
    fetching
  };
}

export function receiveBag(payload) {
  return {
    type: FETCH_BAG_RECEIVED,
    payload: payload
  };
}

export function fetchBagError(error) {
  return {
    type: FETCH_BAG_FAILED,
    error
  };
}

export function isFetchingBags(fetching) {
  return {
    type: FETCH_BAGS_REQUESTED,
    fetching
  };
}

export function receiveBags(payload) {
  return {
    type: FETCH_BAGS_RECEIVED,
    payload: payload
  };
}

export function fetchBagsError(error) {
  return {
    type: FETCH_BAGS_FAILED,
    error
  };
}

export function isFetchingBagVariantWeek(fetching) {
  return {
    type: FETCH_BAGVARIANTWEEK_REQUESTED,
    fetching
  };
}

export function receiveBagVariantWeek(
  variant,
  recipes,
  homeList,
  homeListIngredients
) {
  return {
    type: FETCH_BAGVARIANTWEEK_RECEIVED,
    variant,
    recipes,
    homeList,
    homeListIngredients
  };
}

export function receiveBagVariantRecipes(payload) {
  return {
    type: FETCH_BAGVARIANTRECIPES_RECEIVED,
    payload: payload
  };
}

export function fetchBagVariantWeekError(error) {
  return {
    type: FETCH_BAGVARIANTWEEK_FAILED,
    error
  };
}

export function isFetchingCurrentBagVariantWeek(fetching) {
  return {
    type: FETCH_BAGVARIANTCURRENTWEEK_REQUESTED,
    fetching
  };
}

export function receiveCurrentBagVariantWeek(variant, recipes, homeList) {
  return {
    type: FETCH_BAGVARIANTCURRENTWEEK_RECEIVED,
    variant,
    recipes,
    homeList
  };
}

export function receiveCurrentBagVariantRecipes(payload) {
  return {
    type: FETCH_BAGVARIANTCURRENTWEEKRECIPES_RECEIVED,
    payload: payload
  };
}

export function fetchCurrentBagVariantWeekError(error) {
  return {
    type: FETCH_BAGVARIANTCURRENTWEEK_FAILED,
    error
  };
}
