import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './Navigation.scss';
import SideMenuItem from '../SideModalTabs/SideModalMenu/SideMenuItem/SideMenuItem';

const isProductPage = (type) => {
  return type === 'ProductFolderPage' || type === 'RecipeFolderPage' || type === 'CateringFolderPage' ;
}

const Navigation = ({
  nav = {},
}) => {
  const [activeLink, setActiveLink] = useState();

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setActiveLink(node);
    }
  }, []);
  const navMemo = useMemo(() =>  {
    return  [
    {
      name: `${isProductPage(nav?.type) ? nav?.name === 'Catering' ? 'All':'Alla' : ""} ${nav?.name}`,
      link: nav?.link,
      id: nav?.id,
      visible: true
    },
    ...nav?.children
  ]?.map(categoryItem => {
    return (
      <SideMenuItem
        categoryItem={categoryItem}
        key={categoryItem?.id}
        activeRef={measuredRef}
        isSidebar
      />
    );
  })}, [nav]);
  return (
    <nav className="c-navigation">
     
      {nav && nav?.children && 
            navMemo}
     
    </nav>
  );
};
Navigation.propTypes = {
  nav: PropTypes.object,
  activePath: PropTypes.string,
  type: PropTypes.string,
  collapsed: PropTypes.bool,
  onTitleClick: PropTypes.func,
  closeMobileNav: PropTypes.func
};
Navigation.defaultProps = {
  nav: {},
  type: 'scroll',
  collapsed: false
};
export default Navigation;
