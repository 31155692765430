import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CartCoupons from '../CartCoupons';

import './CartSummary.scss';
import { userHasRole } from '../../../lib/user';
import {
  PickingFeeText,
  PromotionText,
  WeightReservation
} from '../../../constants/InformationText';
import { CartSubTotal, ECartSubTotalTypes } from '@citygross/components';
import { useTheme } from 'styled-components';
import cs from 'classnames';
const DEFAULT_PICKING_FEE_LIMIT = 1500;

export const CartSummary = ({
  totalDiscount,
  totalCateredPrice,
  totalBagPrice,
  totalItemPrice,
  totalRecipePrice,
  homeDeliveryFee,
  charges,
  cart,
  noCouponInput,
  totalAmount,
  withSum = false,
  isPaid,
  paymentDate,
  cartText,
  isAuthenticated,
  hideShadow
}) => {
  const theme = useTheme();
  const returnablePackages =
    charges &&
    charges.filter(charge => charge.subTypeId === 'ReturnablePackage');
  const freightCharge =
    !!charges &&
    charges
      .filter(charge => charge.subTypeId === 'FreightCharge')
      .reduce(
        (acc, curr) => {
          return (acc = {
            ...curr,
            totalGrossPrice: acc.totalGrossPrice + curr.price.gross
          });
        },
        { totalGrossPrice: 0 }
      );

  const pickingFee =
    (cart &&
      (totalItemPrice >= cart?.lowestPickingFeeLimit ||
      cart?.cateredMeals?.length
        ? 0
        : cart.originalPickingFee || cart.lowestPickingFee)) ??
    cart?.charges?.find(charge => charge?.subType === 'ChargePickingFee')
      ?.amount;

  const pickingFeeInfoText = useMemo(() => {
    return PickingFeeText(
      cart?.lowestPickingFeeLimit || DEFAULT_PICKING_FEE_LIMIT
    );
  }, [cart?.lowestPickingFeeLimit]);
  return (
    <div
      className={cs('summary', {
        'summary__no-shadow': hideShadow
      })}
    >
      {(!!totalItemPrice || !!totalCateredPrice) && (
        <CartSubTotal
          label={'Varor'}
          value={(totalItemPrice ?? 0) + (totalCateredPrice ?? 0)}
          type={ECartSubTotalTypes.items}
        />
      )}

      {!!homeDeliveryFee && (
        <CartSubTotal label={'Hemleverans'} value={homeDeliveryFee} />
      )}
      {!!charges && (
        <div className="charges">
          {returnablePackages.length > 0 && (
            <CartSubTotal
              key={returnablePackages[0].id}
              label={returnablePackages[0].name}
              type={returnablePackages[0].subTypeId}
              value={getReturnablePackagePrice(returnablePackages)}
            />
          )}
          {charges
            .filter(charge => charge.subTypeId !== 'ReturnablePackage')
            .filter(charge => charge.subTypeId !== 'FreightCharge')
            .filter(charge => charge.subTypeId !== 'ChargePickingFee')
            .map(charge => (
              <CartSubTotal
                key={charge.id}
                label={charge.name}
                value={charge.price.gross}
                type={charge.subTypeId}
                info={
                  charge.subTypeId === 'ChargePickingFee'
                    ? cartText && cartText.pickingFee
                      ? cartText.pickingFee
                      : 'Då vi har samma schyssta priser online som i butik tar vi en plockavgift för tjänsten istället.'
                    : charge.subTypeId === 'WeightReservation'
                    ? cartText && WeightReservation
                    : undefined
                }
              />
            ))}
          {cart?.totalAmount > 0 && (
            <CartSubTotal
              label={<p>Plockavgift</p>}
              value={pickingFee || 0}
              conditional={cart && Boolean(!cart.lowestPickingFee)}
              info={
                pickingFeeInfoText
                  ? pickingFeeInfoText
                  : '* Vi har samma schyssta priser online som i butik. Plockavgift är beroende på vilken leverans du väljer i check-out.'
              }
              priceColor={
                !pickingFee || pickingFee === 0
                  ? theme.palette?.alertRed
                  : theme.palette?.darkest
              }
              fromPrice={
                totalBagPrice === 0 &&
                totalCateredPrice === 0 &&
                cart &&
                cart.lowestPickingFee !== cart.highestPickingFee
              }
            />
          )}
          {!!freightCharge && freightCharge.totalGrossPrice > 0 && (
            <CartSubTotal
              label="Frakt"
              value={freightCharge.totalGrossPrice}
              type={freightCharge.subTypeId}
            />
          )}
        </div>
      )}
    </div>
  );
};

let getReturnablePackagePrice = returnablePackages => {
  let price = 0;
  returnablePackages.map(
    charge => (price += charge.price.gross * charge.quantity.value)
  );
  return price.toFixed(2);
};

export default CartSummary;
