import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  BAG_ADDED,
  BAG_REMOVED,
  CART_SYNC,
  CART_REPLACE,
  SET_BAG_REFS,
  CLEAR_ITEMS,
  CART_RECEIVED
} from '../../types/reducers/cart';
import { toLookup } from '../../lib/utils';
import makeReducer from '../makeReducer';
import { splitByType } from '../../lib/cart';

const initialState = {
  bags: [],
  bagRefs: {}
};

const sync = (state, { payload }) => {
  const { bags, ...rest } = splitByType(payload.cart);
  return {
    ...state,
    bags: bags
  };
};

const ACTION_HANDLERS = {
  [BAG_REMOVED]: (state, action) => ({
    ...state,
    bags: state.bags.filter(i => i.id !== action.id)
  }),
  [CART_SYNC]: sync,
  [CART_RECEIVED]: sync,
  [CART_REPLACE]: sync,
  [BAG_ADDED]: (state, action) => {
    const { bag } = action;
    return {
      ...state,
      bagRefs: {
        ...state.bagRefs,
        [bag.id]: bag
      }
    };
  },
  [SET_BAG_REFS]: (state, { bags }) => {
    return {
      ...state,
      bagRefs: { ...state.bagRefs, ...toLookup(bags) }
    };
  },
  [CLEAR_ITEMS]: (state, action) => initialState,
  [LOGOUT_SUCCESS]: (state, action) => initialState
};

export default makeReducer(ACTION_HANDLERS, initialState);
