import {
  recieveOrders,
  deleteOrder,
  fetchOrders,
  fetchOrdersFailed,
  setOrderInitialLoad,
  ordersLoaded
} from './sync';
import {
  receiveCart,
  setRecipeRefs,
  setProductRefs,
  setBagRefs,
  setReplacementMode,
  requestCart,
  setCateredMealsRefs
} from '../cart';
import { getCart, clearCart } from '../../api/endpoints/cart';
import { getOrderForUser, deleteOrderById } from '../../api/endpoints/order';
import { findNext, fetchCartItems } from '../../lib/cart';
import { requestRetry, REQUEST_IDS } from '../../lib/request/requestRetry';
import { Cart } from '../../types/order/Cart';
import { Order } from '../../types/order/Order';
import { push } from 'connected-react-router';
import { initialState as initialCartState } from '../../reducers/cart';
import { fetchSites } from '../../api/endpoints/delivery';
import { setAssortment } from '../assortments';
import { getStores } from '../../api/endpoints/page';
import { openSideModal, sideModalForceOpen } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../../components/SideModal';

export const syncItems = dispatch => async (cartToFetch: Cart) => {
  const [cart, [products, recipes, bags, cateredMeals]] = await fetchCartItems(
    cartToFetch
  );
  dispatch(setRecipeRefs(recipes ?? []));
  dispatch(setProductRefs(products ?? []));
  dispatch(setBagRefs(bags ?? []));
  dispatch(setCateredMealsRefs(cateredMeals ?? []));
  return cart;
};

const sanitizeOldCarts = (carts: Cart[], orders: Order[]) => {
  let cartsToDelete: Cart[] = [];

  const sortCarts = (a: Cart, b: Cart) =>
    new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime();

  const replacementCarts = carts.sort(sortCarts).filter((cart, index) => {
    if (cart.replacementForOrderId) {
      const match = orders.find(o => o.id === cart.replacementForOrderId);
      if (!match || new Date(match.deliveryLockDate || '') < new Date()) {
        cartsToDelete.push(cart);
        return null;
      }
      if (index > 0) {
        cartsToDelete.push(cart);
        return null;
      }
      return cart;
    }
  });

  const sanitizedCarts = carts
    .sort(sortCarts)
    .filter(cart => !cart.replacementForOrderId)
    .map((cart, index) => {
      if (index > 0 || replacementCarts?.length) {
        cartsToDelete.push(cart);
        return null;
      }

      const dateLimit = new Date();
      dateLimit.setMonth(dateLimit.getMonth() - 1);

      if (new Date(cart.modifiedDate) < dateLimit) {
        console.error(
          `${cart.id} has not been modified since 1 months, and will be removed`
        );
        cartsToDelete.push(cart);
        return null;
      }
      return cart;
    })
    .filter(Boolean);

  return {
    carts: [...replacementCarts, ...sanitizedCarts],
    orders,
    cartsToDelete
  };
};

export const getUserOrders = () => (dispatch, state) => {
  dispatch(fetchOrders());

  return requestRetry(getOrderForUser, dispatch, REQUEST_IDS.customerOrders)
    .then(async ({ data }) => {
      const curState = state();
      let isNewReplacementOrder = false;
      const { carts, orders, cartsToDelete } = sanitizeOldCarts(
        data.carts,
        data.orders
      );
      cartsToDelete.forEach(cart => {
        clearCart(cart.id);
      });
      const cart = findNext(carts);
      if (cart && cart.replacementForOrderId) {
        dispatch(setReplacementMode(true));
        const replacementOrder = orders.find(
          order => order.id === cart.replacementForOrderId
        );
        const zipCode = replacementOrder?.homeDelivery?.postalCode;
        const storeNumber =
          cart?.lindbakStoreNo ||
          replacementOrder?.itemFulfilmentLindbakStoreNum;
        if (replacementOrder?.deliveryMethod) {
          const sites = await fetchSites({ siteTypeId: 3 });
          const siteId = sites?.data?.sites?.find(
            site => Number(site.storeNumber) === Number(storeNumber)
          )?.id;
          const { selectableStores } = await getStores();

          const validStoreToPick = selectableStores?.find(
            storeData => storeData?.data?.siteId === siteId
          )?.data?.displayInSiteStoreSelector;

          if (validStoreToPick) {
            if (
              curState?.cart?.meta?.replacementForOrderId !==
              replacementOrder.id
            ) {
              isNewReplacementOrder = true;
              dispatch(
                setAssortment(
                  replacementOrder?.deliveryMethod,
                  Number(siteId),
                  zipCode
                )
              );
            }
          } else {
            dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
            dispatch(sideModalForceOpen(true));
          }
        }
      } else {
        dispatch(setReplacementMode(false));
      }
      dispatch(recieveOrders(data.orders, carts));

      let cartPromise;
      if (cart) {
        // dont fetch cart again if manually changing store, handled on store change
        if (
          !isNewReplacementOrder &&
          curState?.newCartPrep?.storeNoToBeSelected
        ) {
          dispatch(ordersLoaded());
        } else {
          dispatch(requestCart());
          cartPromise = getCart(cart.id)
            .then(({ data }) => {
              return syncItems(dispatch)(data);
            })
            .then(cart => {
              dispatch(receiveCart(cart));
              return cart;
            })
            .catch(error => {
              if (error?.response?.status === 404) {
                clearCart(cart.id);
              }
              dispatch(fetchOrders());
              return Promise.resolve(null);
            });
        }
      } else {
        dispatch(receiveCart(initialCartState));
        cartPromise = Promise.resolve(null);
      }

      return Promise.all([data, cartPromise]);
    })
    .then(([res, _cart]) => {
      dispatch(setOrderInitialLoad());
      return _cart;
    })
    .catch(err => {
      console.error('Failed fetching user orders', err);
      dispatch(fetchOrdersFailed());
      dispatch(setOrderInitialLoad());
      return Promise.reject(err);
    });
};

export const deleteUserOrder = (
  id: number,
  back: Function,
  location?: string
) => dispatch => {
  return deleteOrderById(id)
    .then(() => {
      back();
      dispatch(deleteOrder(id));
    })
    .then(() => {
      if (location && location.includes('/confirmation/')) dispatch(push('/'));
    })
    .catch(err => {
      console.error('Failed deleting order', err);
      return Promise.reject(err);
    });
};
