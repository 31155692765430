/* eslint-disable import/namespace */
import React from 'react';
import { AlertBox, Link } from '@citygross/components';
import { Icons } from '@citygross/icons';
import { BodyText } from '@citygross/typography';
import * as styles from './ReplacementBox.styles';
import { theme } from '@citygross/design-tokens';

export const ReplacementBox = ({
  onClick,
  isInModal
}: {
  onClick: () => void;
  isInModal: boolean;
}) => {
  return (
    <styles.ReplacementWrapper paddingTop={isInModal}>
      <AlertBox>
        <styles.ReplacementOrder>
          <div>
            <Icons.Alert
              width={20}
              height={20}
              color={theme.palette?.alertBlue}
            />
          </div>
          <styles.ReplacementBody>
            <BodyText fontWeight={'medium'}>
              Du ändrar en befintlig beställning
            </BodyText>
            <BodyText>
              Gå till kassan igen för att bekräfta ändringarna
            </BodyText>
            <styles.ReplacementOrderCancel>
              <Link onClick={onClick} asButton>
                Avbryt redigering
              </Link>
            </styles.ReplacementOrderCancel>
          </styles.ReplacementBody>
        </styles.ReplacementOrder>
      </AlertBox>
    </styles.ReplacementWrapper>
  );
};
