import React from 'react';
import cs from 'classnames';
import { fireCallbackOnEnter } from '../../lib/utils';
import './Tabs.scss';

export interface Tab {
  id: string | number,
  active: boolean,
  inactive: boolean,
  disabled: boolean,
  label: string
}
interface ITabs {
  asGrid?: boolean,
  tabs: Tab[],
  onTabClick: Function
}

export const Tabs : React.FC<ITabs> = ({ asGrid, tabs, onTabClick }) => (
  <div className={cs('c-tabs', { 'c-tabs--as-grid': asGrid })}>
    {tabs &&
      tabs.map(({ id, active, inactive, disabled, label }) => {
        const handleOnTrigger = () => !disabled && onTabClick(id);
        return (
          <div
            key={id.toString()}
            className={cs(
              'c-tabs__tab',
              { 'c-tabs__tab--active': active },
              { 'c-tabs__tab--inactive': inactive },
              { 'c-tabs__tab--disabled': disabled }
            )}
            role="link"
            onClick={handleOnTrigger}
            onKeyDown={fireCallbackOnEnter(handleOnTrigger)}
            tabIndex={0}
          >
            {label}
          </div>
        );
      })}
  </div>
);

Tabs.defaultProps = {
  asGrid: false
};
