import { array, bool, shape, number, string } from 'prop-types';

export default shape({
  deliveryAdress: shape({
    addressTypeId: string,
    city: string,
    co: string,
    contactId: number,
    countryCode: string,
    foAdress: string,
    id: number,
    street: string,
    street2: string,
    zip: string
  }),
  deliveryMethod: string,
  email: string,
  favoriteStorePageId: number,
  firstName: string,
  fullName: string,
  hasSettings: bool,
  kbdId: number,
  lastName: string,
  mobilePhone: string,
  oldSubscription: array,
  parAddress: shape({
    addressTypeId: string,
    city: string,
    co: string,
    contactId: number,
    countryCode: string,
    foAdress: string,
    id: number,
    street: string,
    street2: string,
    zip: string
  }),
  phone: string,
  siteId: number,
  zipCode: string
});
