import React, { useEffect, useState } from 'react';
import { BodyText } from '@citygross/typography';
import {
  CartCardLabel,
  QuantitySelector,
  CartCard
} from '@citygross/components';
import { useTheme } from 'styled-components';
import { checkPromotionPrice } from '../../../lib/price';
import { useDispatch } from 'react-redux';
import {
  cartLoading,
  decreaseItemQuantity,
  increaseItemQuantity,
  addToCart
} from '../../../actions/cart';
import cs from 'classnames';
import { formatPriceWithNoZero } from '@citygross/utils';
import { ONLY_NUMBERS_REGEX } from '../../../lib/validator';
import { showLanguage } from '../../../lib/product';
import { capitalizeWord } from '../../../lib/utils';

const MAX_QUANTITY_LABEL = 'Max antal uppnått';

const ProductCartCard = ({
  isDiscountPrice,
  totalPrice,
  quantity,
  cartItemId,
  isLast,
  ...itemProps
}) => {
  const {
    image,
    descriptiveSize,
    name,
    brand,
    price,
    maximumQuantity
  } = itemProps;
  const theme = useTheme();
  const formattedPrice = formatPriceWithNoZero(totalPrice);
  const dispatch = useDispatch();

  const { mixObject } = checkPromotionPrice(price);

  const catchPromise = (fn, newQuantity) => {
    dispatch(fn(cartItemId, Math.abs(newQuantity)))
      .then(() => {
        setPendingRequestState(!!false);
      })
      .catch(() => onError(newQuantity));
  };

  const [quantityState, setQuantityState] = useState(quantity ?? 1);
  const [inputQuantityState, setInputQuantityState] = useState(quantity ?? 1);
  const [pendingRequestState, setPendingRequestState] = useState(false);

  useEffect(() => {
    setQuantityState(quantity);
    setInputQuantityState(quantity);
  }, [quantity]);
  const handleQuantityChange = (addToCartfn, quantity, event) => {
    if (event) {
      event.stopPropagation();
    }

    //Prevent negative count on product
    const newQuantity = quantity < 0 ? 0 : quantity;
    const currentQuantity = !pendingRequestState
      ? quantityState || 0
      : quantity || 0;

    setQuantityState(newQuantity);
    setInputQuantityState(newQuantity);
    setPendingRequestState(!!currentQuantity);

    //Only catch promise if we go from 1 to 0 in count

    //!currentLessThan1 && !newQuantityGrt0
    if (currentQuantity) {
      catchPromise(addToCartfn, newQuantity);
    } else {
      dispatch(cartLoading(true));
      dispatch(addToCartfn(cartItemId, Math.abs(newQuantity))).catch(() =>
        onError(0)
      );
    }
  };
  const onError = newQuantity => {
    if (newQuantity === 1) {
      // assume that customer wants to re-add deleted item
      dispatch(addToCart(itemProps));
    }
    setQuantityState(quantity);
    setPendingRequestState(false);
  };
  return (
    <div
      className={cs('cart-card', {
        ['cart-card__last-card']: isLast
      })}
    >
      <CartCard
        title={name}
        image={(process.env.PUBLIC_URL || '') + image.url + '?w=80&h=80'}
        description={
          <div>
            <p className="cart-card__description cart-card__description-nowrap">
              {brand || ''} {descriptiveSize || ''}{' '}
              {showLanguage(itemProps?.bfCategoryCode)
                ? capitalizeWord(itemProps?.countryOfOrigin)
                : ''}
            </p>
            {mixObject &&
              mixObject?.length &&
              (mixObject[0]?.minQuantity || 0) > 1 && (
                <CartCardLabel
                  background={theme.palette?.alertRed}
                  label={`${mixObject[0].minQuantity} för ${mixObject[0].value} kr`}
                />
              )}
          </div>
        }
        maxReachedWarning={
          quantity !== maximumQuantity && inputQuantityState === maximumQuantity
            ? MAX_QUANTITY_LABEL
            : undefined
        }
        maxValue={maximumQuantity}
        value={inputQuantityState}
        price={
          <BodyText
            color={
              isDiscountPrice ? theme.palette?.alertRed : theme.palette?.darkest
            }
            fontWeight="medium"
          >
            {formattedPrice} kr
          </BodyText>
        }
        quanitytSelector={
          <QuantitySelector
            maxValue={maximumQuantity}
            onAdd={() => {
              if (quantity) {
                handleQuantityChange(
                  increaseItemQuantity,
                  quantityState + 1,
                  null
                );
              }
            }}
            onSubtract={() => {
              if (quantity > 0) {
                handleQuantityChange(
                  decreaseItemQuantity,
                  quantityState - 1,
                  null
                );
                setInputQuantityState(quantityState - 1);
              }
            }}
            onChange={e => {
              if (e.currentTarget?.value?.length < 4) {
                if (e.currentTarget?.valueAsNumber >= maximumQuantity) {
                  setInputQuantityState &&
                    setInputQuantityState(maximumQuantity);
                } else {
                  setInputQuantityState &&
                    setInputQuantityState(
                      e.target.value.replace(ONLY_NUMBERS_REGEX, '')
                    );
                }
              }
            }}
            onBlur={() => {
              if (!inputQuantityState && parseInt(inputQuantityState) !== 0) {
                handleQuantityChange(increaseItemQuantity, quantity, null);
                return;
              }
              if (inputQuantityState > quantityState) {
                handleQuantityChange(
                  increaseItemQuantity,
                  inputQuantityState,
                  null
                );
              } else if (inputQuantityState < quantityState) {
                handleQuantityChange(
                  decreaseItemQuantity,
                  inputQuantityState,
                  null
                );
              }
            }}
            value={inputQuantityState}
          />
        }
      />
    </div>
  );
};

export default ProductCartCard;
