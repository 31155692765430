import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  USER_RECEIVED,
  USER_CHANNEL_RECEIVED,
  PASSWORD_RESET,
  PHONE_UPDATE,
  ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED,
  ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED,
  ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED,
  ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED,
  ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED,
  ADD_CUSTOMER_FAVORITE_RECIPE_FAILED,
  REMOVE_CUSTOMER_FAVORITE_REQUESTED,
  REMOVE_CUSTOMER_FAVORITE_RECEIVED,
  REMOVE_CUSTOMER_FAVORITE_FAILED,
  SET_EMAIL_RECEIPT_REQUESTED,
  SET_EMAIL_RECEIPT_RECEIVED,
  SET_EMAIL_RECEIPT_FAILED,
  AuthState,
  USER_FAVORITES_RECEIVED
} from '../../types/reducers/auth';

export const initialState: AuthState = {
  fetching: false,
  isAuthenticated: false,
  isLoggedInAsCustomer: false,
  user: null,
  favoriteProductsLoading: [],
  favoriteRecipesLoading: [],
  recipeFavorites: [],
  productFavorites: [],
  errorMessage: null,
  stopSubscriptionLoading: false,
  stopSubscriptionSuccess: false,
  phoneUpdateRequesting: false,
  phoneUpdateSuccessfull: false,
  phoneUpdateError: false,
  phoneUpdateErrorMessage: null,
  requestingPasswordReset: false,
  passwordResetRequestSuccessfull: false,
  passwordResetRequestError: false,
  passwordResetRequestErrorMessage: null
};

export default function authReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AssortmentsActionTypes
): AuthState {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        fetching: true,
        isAuthenticated: false
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        isAuthenticated: true,
        errorMessage: ''
      }
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        fetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      }
    }
    case LOGOUT_SUCCESS: {
      return {
        ...initialState
      }
    }
    case USER_RECEIVED: {
      return {
        ...state,
        user: action.user
      }
    }
    case USER_FAVORITES_RECEIVED: {
      return {
        ...state,
        productFavorites: action.favorites.filter(c => c.favoriteTypeId === 1),
        recipeFavorites: action.favorites.filter(c => c.favoriteTypeId === 2)
      }
    }
    case USER_CHANNEL_RECEIVED: {
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          contact: {
            ...((state.user || {}).contact || {}),
            channels: ((state.user || {}).contact || {}).channels.map(chan =>
              chan.id === action.channel.id ? action.channel : chan
            )
          }
        } : null
      }
    }
    case PASSWORD_RESET: {
      return {
        ...state,
        requestingPasswordReset: action.isFetching || false,
        passwordResetRequestSuccessfull:
          (action.isSuccess && action.successMail) || false,
        passwordResetRequestError: action.isError || false,
        passwordResetRequestErrorMessage: action.errorMessage || undefined
      }
    }

    case PHONE_UPDATE: {
      return {
        ...state,
        phoneUpdateRequesting: action.isFetching || false,
        phoneUpdateSuccessfull: action.isSuccess || false,
        phoneUpdateError: action.isError || false,
        phoneUpdateErrorMessage: action.errorMessage || null
      }
    }

    // Add favorite product
    case ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED: {
      return {
        ...state,
        favoriteProductsLoading: state.favoriteProductsLoading.concat([
          action.productId
        ])
      }
    }

    case ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED: {
      return {
        ...state,
        productFavorites: state.productFavorites.concat([
          action.favorite
        ]),
        favoriteProductsLoading: state.favoriteProductsLoading.filter(
          item => item !== action.favorite.itemNo
        )
      }
    }

    case ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED: {
      return {
        ...state,
        favoriteProductsLoading: state.favoriteProductsLoading.filter(
          item => item !== action.productId
        )
      }
    }

    // Add favorite recipe
    case ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED: {
      return {
        ...state,
        favoriteRecipesLoading: state.favoriteRecipesLoading.concat([
          action.recipeId
        ])
      }
    }

    case ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED: {
      return {
        ...state,
        recipeFavorites: state.recipeFavorites.concat([
          action.favorite
        ]),
        favoriteRecipesLoading: state.favoriteRecipesLoading.filter(
          item => item !== action.favorite.itemNo
        )
      }
    }

    case ADD_CUSTOMER_FAVORITE_RECIPE_FAILED: {
      return {
        ...state,
        favoriteRecipesLoading: state.favoriteRecipesLoading.filter(
          item => item !== action.recipeId
        )
      }
    }

    // Remove favorite
    case REMOVE_CUSTOMER_FAVORITE_REQUESTED: {
      // Product
      if (action.favorite.favoriteTypeId === 1) {
        return {
          ...state,
          favoriteProductsLoading: state.favoriteProductsLoading.concat([
            action.favorite.itemNo
          ])
        };
        // Recipe
      } else if (action.favorite.favoriteTypeId === 2) {
        return {
          ...state,
          favoriteRecipesLoading: state.favoriteRecipesLoading.concat([
            action.favorite.itemNo
          ])
        };
      }
      return state;
    }

    case REMOVE_CUSTOMER_FAVORITE_RECEIVED: {
      // Product
      if (action.favorite.favoriteTypeId === 1) {
        return {
          ...state,
          productFavorites: state.productFavorites.filter(
            item => item.id !== action.favorite.id
          ),
          favoriteProductsLoading: state.favoriteRecipesLoading.filter(
            item => item !== action.favorite.itemNo
          )
        }
        // Recipe
      } else if (action.favorite.favoriteTypeId === 2) {
        return {
          ...state,
          recipeFavorites: state.recipeFavorites.filter(
            item => item.id !== action.favorite.id
          ),
          favoriteRecipesLoading: state.favoriteProductsLoading.filter(
            item => item !== action.favorite.itemNo
          )
        };
      }
      return state;
    }

    case REMOVE_CUSTOMER_FAVORITE_FAILED: {
      // Product
      if (action.favorite.favoriteTypeId === 1) {
        return {
          ...state,
          favoriteProductsLoading: state.favoriteProductsLoading.filter(
            item => item !== action.favorite.itemNo
          )
        };
        // Recipe
      } else if (action.favorite.favoriteTypeId === 2) {
        return {
          ...state,
          favoriteRecipesLoading: state.favoriteRecipesLoading.filter(
            item => item !== action.favorite.itemNo
          )
        };
      } else {
        return state;
      }
    }

    case SET_EMAIL_RECEIPT_REQUESTED: {
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          contact: {
            ...state.user.contact,
            emailRecieptCopy: !state.user.contact.emailRecieptCopy
          }
        } : null
      }
    }

    case SET_EMAIL_RECEIPT_RECEIVED: {
      return {
        ...state,
        ...action.user
      };
    }

    case SET_EMAIL_RECEIPT_FAILED: {
      return {
        ...state,
        user: state.user ? {
          ...state.user,
          contact: {
            ...state.user.contact,
            emailRecieptCopy: !state.user.contact.emailRecieptCopy
          }
        } : null
      }
    }
    default:
      return state;
  }
}
