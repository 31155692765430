import React, { useEffect } from 'react';
import { AlertBox, Button } from '@citygross/components';
import { useWindowSize } from '@citygross/utils';
import { BodyText } from '@citygross/typography';
import { Icons } from '@citygross/icons';
import Cookies from 'js-cookie';
import './OrderAlert.scss';

const NewOrdersInfoCookie = 'newOrderInfoCookie';

const OrderAlert = ({ newUpcommingOrders, setShow, show }) => {
  const { width } = useWindowSize();
  const cookie = Cookies.get(NewOrdersInfoCookie);
  useEffect(() => {
    if (!cookie) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [cookie]);
  return newUpcommingOrders && show ? (
    <div className="header-delivery-notice">
      <AlertBox icon={width > 768 ? <Icons.Info color="#0072BB" /> : undefined}>
        <div className="header-delivery-notice__text">
          <BodyText>
            Du kan nu se dina{' '}
            <a
              className="header-delivery-notice__link"
              href={'/mina-sidor/kop-historik?page=1'}
            >
              beställningar
            </a>{' '}
            på Mina Sidor.
          </BodyText>
          <div>
            <a href={'/mina-sidor/kop-historik?page=1'}>
              <Button color={'secondary'} size={'medium'}>
                Visa beställningar
              </Button>
            </a>
          </div>
          <div
            className="header-delivery-notice__close"
            onClick={() => {
              Cookies.set(NewOrdersInfoCookie, true);
              setShow(false);
            }}
          >
            <Icons.Cross color="#333333" />
          </div>
        </div>
      </AlertBox>
    </div>
  ) : null;
};

export default OrderAlert;
