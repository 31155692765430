import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
  increaseItemQuantity,
  decreaseItemQuantity,
  removeRecipeFromCart,
  removeBagFromCart,
  removeBagAndRecipesFromCart,
  removeFromCart,
  removeCateredMealFromCart
} from '../../actions/cart';
import { toggleModalActive } from '../../actions/assortments';
import { openInFlyout } from '../../actions/flyout';
import { toggleActiveRecipe } from '../../actions/shoppingOrder';
import {
  cartTotalValueSelector,
  cartTotalDiscountSelector,
  cartItemExtendedInfoSelector,
  cartBagTotalValue,
  cartItemTotalValue,
  cartRecipeTotalValue,
  extendedRecipes,
  extendedBags,
  extendedCateredMeal,
  cateredMealsTotalValue,
  cartCharges
} from '../../selectors/cart/cartSelector';
import {
  userSelector,
  authenticatedSelector,
  userSubscriptionsSelector
} from '../../selectors/user';
import { push } from 'connected-react-router';

import { upcomingOrders, nextCartSelector } from '../../selectors/order';

import CartList from '../../components/CartList';

import { appSettingsSelector } from '../../selectors/app';
import { selectedStore, assortmentsLoading } from '../../selectors/assortments';
import {
  hasNotificationStatusSelector,
  hasNotificationHeightSelector
} from '../../selectors/navigation';

class ShoppingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false
    };
  }
  componentDidMount() {
    this.setState({
      isMounted: true
    });
  }

  render() {
    const { isMounted } = this.state;
    return isMounted ? <CartList {...this.props} isInModal /> : null;
  }
}

const mapStateToProps = state => {
  const total = cartTotalValueSelector(state);
  const totalBagPrice = cartBagTotalValue(state);
  const totalCateredPrice = cateredMealsTotalValue(state);
  const totalItemPrice = cartItemTotalValue(state);
  const totalRecipePrice = cartRecipeTotalValue(state);
  const totalDiscount = cartTotalDiscountSelector(state);
  const items = cartItemExtendedInfoSelector(state);
  const bags = extendedBags(state);
  const recipes = extendedRecipes(state);
  const cateredMeals = extendedCateredMeal(state);
  const orders = upcomingOrders(state);
  const nextCart = nextCartSelector(state);
  const charges = cartCharges(state);
  const user = userSelector(state);
  const userSubscriptions = userSubscriptionsSelector(state);
  const appSettings = appSettingsSelector(state);
  const notificationStatus = hasNotificationStatusSelector(state);
  const notificationHeight = hasNotificationHeightSelector(state);
  return {
    isAuthenticated: authenticatedSelector(state),
    isLoggedInAsCustomer: state.auth.isLoggedInAsCustomer,
    isReplacement: state.cart.meta.isReplacement,
    activeStore: selectedStore(state),
    assortmentsLoading: assortmentsLoading(state),
    nextCart,
    ...state.shoppingOrder,
    cart: {
      ...state.cart.meta,
      recipes,
      items,
      bags,
      cateredMeals
    },
    summary: {
      charges,
      totalPrice: total,
      totalDiscount,
      totalBagPrice,
      totalItemPrice,
      totalRecipePrice,
      totalCateredPrice
    },
    orders,
    user,
    messageStatus: appSettings?.message.status,
    cartText: appSettings?.cart,
    userSubscriptions,
    notificationStatus,
    notificationHeight
  };
};

const mapDispatchToProps = {
  increaseItemQuantity,
  decreaseItemQuantity,
  removeRecipeFromCart,
  toggleActiveRecipe,
  removeFromCart,
  removeBagFromCart,
  removeBagAndRecipesFromCart,
  removeCateredMealFromCart,
  openInFlyout,
  push,
  toggleModalActive
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingContainer);
