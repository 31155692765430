import * as endpoints from '../../api/endpoints/bag';
import {
  fetchRecipesByVariants,
  fetchBatchRecipeVariantPrice
} from '../../api/endpoints/recipe';
import { fetchProductsByIds } from '../../api/endpoints/product';
import {
  isFetchingBag,
  receiveBag,
  fetchBagError,
  isFetchingBags,
  receiveBags,
  fetchBagsError,
  isFetchingBagVariantWeek,
  receiveBagVariantWeek,
  fetchBagVariantWeekError,
  isFetchingCurrentBagVariantWeek,
  receiveCurrentBagVariantWeek,
  fetchCurrentBagVariantWeekError
} from './sync';
import getISOWeek from 'date-fns/getISOWeek';
import { getNextBagDeliveryDate } from '../../lib/delivery';

export const getBagById = bagId => dispatch => {
  dispatch(isFetchingBag(true));
  return endpoints
    .fetchBagById(bagId)
    .then(({ data }) => {
      dispatch(receiveBag(data));
      return data;
    })
    .catch(err => {
      dispatch(fetchBagError(err));
      return Promise.reject(err);
    });
};

export const getAllBags = () => dispatch => {
  dispatch(isFetchingBags(true));
  return endpoints
    .fetchAllBags()
    .then(({ data }) => {
      dispatch(receiveBags(data.data));

      return data.data;
    })
    .catch(err => {
      dispatch(fetchBagsError(err));

      return Promise.reject(err);
    });
};

export const getBagVariantCurrWeek = bagId => dispatch => {
  dispatch(isFetchingCurrentBagVariantWeek());
  const nextDeliveryDate = getNextBagDeliveryDate();
  const week = getISOWeek(nextDeliveryDate);
  const year = nextDeliveryDate.getFullYear();

  let yr = year;
  let wk = week;
  if (year === 2019 && week === 1) {
    yr = 2020;
  }

  return endpoints
    .fetchBagVariantWeek(bagId, yr, wk)
    .then(({ data: variantWeek }) => {
      if (!variantWeek[0]) {
        return dispatch(fetchCurrentBagVariantWeekError());
      }

      const variantIdLookup = variantWeek[0].recipeIds;

      fetchRecipesByVariants(variantIdLookup).then(({ data }) => {
        let sortedArrOfRecipes = [];

        variantIdLookup.forEach(function(a) {
          const match = data.find(x => x.variants.some(y => y.id === a));
          if (match) {
            sortedArrOfRecipes.push(match);
          }
        });

        return dispatch(
          receiveCurrentBagVariantWeek(variantWeek[0], sortedArrOfRecipes, null)
        );
      });
    })
    .catch(err => {
      dispatch(fetchCurrentBagVariantWeekError());
      return Promise.reject(err);
    });
};

export const getBagVariantWeek = (bagId, year, week) => async (
  dispatch,
  getState
) => {
  const { assortments } = getState();
  dispatch(isFetchingBagVariantWeek(true));

  let yr = year;
  let wk = week;
  if (year === 2019 && week === 1) {
    yr = 2020;
  }

  try {
    const variantWeekResult = await endpoints.fetchBagVariantWeek(bagId, yr, wk);
    const variantWeek = variantWeekResult.data[0]
    if (!variantWeek) {
      return dispatch(
        fetchBagVariantWeekError(
          'Tyvärr finns det inga recept för denna vecka'
        )
      );
    }

    const variantIds = variantWeek.recipeIds;

    const recipeResult = await fetchRecipesByVariants(variantIds);
    const recipes = recipeResult.data

    if (!recipes || recipes.length <= 0) {
      return dispatch(
        fetchBagVariantWeekError(
          'Tyvärr finns det inga recept för denna vecka'
        )
      );
    }

    const sortedArrOfRecipes = sortRecipes(variantIds, recipes);
    const recipeData = getRecipeData(variantIds, recipes, assortments);

    const batchDataResult = await fetchBatchRecipeVariantPrice(recipeData);
    const batchData = batchDataResult.data;

    if (!batchData || batchData.length <= 0) {
      // No batchdata - unable to load homelist
      return dispatch(
        receiveBagVariantWeek(variantWeek, sortedArrOfRecipes, null)
      );
    }

    const physicalArticleIds = [];
    const ingredientNames = [];

    batchData.forEach(p => {
      p.calculatedRecipes[0].lines
        .filter(x => x.purchaseType === 'Hemma')
        .forEach(x => {
          if (x.physicalArticles?.length > 0) {
            physicalArticleIds.push(x.physicalArticles[0].articleNumber);
          } else {
            ingredientNames.push(x.ingredientName);
          }
        });
    });

    if (physicalArticleIds.length <= 0) {
      // No physicalArticleIds - unable to load homelist
      return dispatch(
        receiveBagVariantWeek(variantWeek, sortedArrOfRecipes, null)
      );
    }
    const filteredPhysicalArticles = physicalArticleIds.filter((x, i, a) => a.indexOf(x) === i);

    const productsResult = await fetchProductsByIds(
      filteredPhysicalArticles, undefined, filteredPhysicalArticles.length
    );
    const products = productsResult.data;

    return dispatch(
      receiveBagVariantWeek(
        variantWeek,
        sortedArrOfRecipes,
        products,
        ingredientNames.filter((x, i, a) => a.indexOf(x) === i)
      )
    );
  } catch(err) {
    dispatch(fetchBagVariantWeekError);
    return Promise.reject(err);
  }
};

const sortRecipes = (variantIds, recipes) => {
  let sortedArrOfRecipes = [];

  variantIds.forEach(function(a) {
    const match = recipes.find(x => x.variants?.some(y => y.id === a) || x.id === a.split("_")[0]);
    if (match) {
      sortedArrOfRecipes.push(match);
    }
  });
  return sortedArrOfRecipes;
}

const getRecipeData = (variantIds, recipes, assortments) => {
  const recipeData = variantIds
    // Matches the given variant with the correct homelist
    .map(id => {
      let m = id.split('_');
      m.length > 1 ? m.shift() : m.pop();

      const flags = {
        glutenFree: m.includes('GF'),
        lactoseFree: m.includes('LF'),
        organic: m.includes('ECO'),
        vegetarian: m.includes('VEG'),
        vegan: m.includes('VEGAN')
      };

      return {
        items: [
          {
            recipeId: id.split('_')[0],
            numberOfMeals: 4,
            ...flags
          }
        ],
        warehouseId: assortments.storeNo ?? 0 // storeId ?? 0 // TODO storeId
      };
    })
    .filter(x => x);

  return recipeData;
}
