import { createSelector } from 'reselect';
import { sortByProp } from './sorting';
import { makeProductEnhancerSelector } from '../product/productSelectors';
import { makeRecipeEnhancerSelector } from '../recipe/recipeSelectors';
import { makeBagEnhancerSelector } from '../bag/bagSelectors';
import { productCombination } from './cartItemsCombiners';
import { arrayToObject } from '../../lib/utils';
import { setImage } from '../../lib/product';
import { MAGIC } from '../../types/reducers/cart';
import { activeStoreNoSelector } from '../assortments';
import { mapCateredMealToProductCard } from '../../lib/catering';

// TODO move selectors & split this
const defaultRecipe = {};
export const getCartMeta = state => state.cart.meta;
const getAllCartItems = state => state.cart.items.items;
const getCartCharges = state => state.cart.meta.charges;
const getCartItemsRefs = state => state.cart.items.productRefs;
const getCateredMeals = state => state.cart.cateredMeals.cateredMeals;
const getCateredMealsRefs = state => state.cart.cateredMeals.cateredMealsRefs;
const getCartBags_ = state => state.cart.bags.bags;
const getCartRecipes_ = state => {
  const { recipeRefs, ...recipes } = state.cart.recipes;
  return recipes;
};
const getCartRecipeRefs = state => Object.values(state.cart.recipes.recipeRefs);
const getCartBagRefs = state => Object.values(state.cart.bags.bagRefs);
const getTotalValue = state => state.cart.meta.totalAmount;
const getTotalDiscount = state => state.cart.meta.totalDiscount;
const getCategoryProducts = state => state.product.category;
const getProducts = state => state.product.products;

export const getCartReplacementMode = state => state.cart.meta.isReplacement;
export const getImediateQueue = state => state.cart.items.imediateQueue;
const enhanceWithProp = (key, value) => obj => ({
  ...obj,
  [key]: value
});

const allCartRecipes = ({ cart }) => {
  const { recipes } = cart;
  const toRet = Object.values(MAGIC).reduce((acc, key) => {
    // Old recipes (with no items)
    if (recipes[key].recipes && recipes[key].recipes.length > 0) {
      acc = acc.concat(
        recipes[key].recipes.map(enhanceWithProp('gtin', recipes[key].itemNo))
      );
    }
    // New editableRecipes (with items)
    if (
      recipes[key].editableRecipes &&
      recipes[key].editableRecipes.length > 0
    ) {
      acc = acc.concat(
        recipes[key].editableRecipes.map(
          enhanceWithProp('gtin', recipes[key].itemNo)
        )
      );
    }
    return acc;
  }, []);

  return toRet;
};

const allCartBags = state => state.cart.bags.bags;
const getRecipeProducts = (state, props) =>
  state.recipe.recipes[props.id] || defaultRecipe;
const getRecipies = state => state.recipe.recipes;
const sortOrder = state => state.product.sortOrder;

// For blocks
const getBlockProducts = (_, products) => products;
const getBlockRecipes = (_, props) => props.recipes;

export const isFetchingCart = createSelector(
  [getCartMeta],
  cartMeta => cartMeta.loading === true
);

export const cartCharges = createSelector([getCartCharges], charges => charges);

export const getCartItems = createSelector([getAllCartItems], items => {
  return items.filter(item => item.type.toLowerCase() === 'item');
});

export const getCartBags = createSelector([getCartBags_], bags => bags);

export const hasCartFlexkasse = createSelector([getCartBags_], bags =>
  bags.some(bag => bag.itemNo.includes('Flexkasse'))
);

export const cartTotalValueSelector = createSelector(
  [getTotalValue],
  value => value
);

export const cartTotalDiscountSelector = createSelector(
  [getTotalDiscount],
  value => value
);

export const countCartItems = createSelector([getCartItems], items =>
  items?.reduce((acc, article) => {
    return (
      acc +
      (article?.quantity?.unit?.toLowerCase() === 'piece'
        ? article?.quantity?.value
        : 1)
    );
  }, 0)
);

export const countCartBags = createSelector([getCartBags], bags =>
  bags.reduce((acc, article) => {
    return (
      acc +
      (article.quantity.unit.toLowerCase() === 'piece'
        ? article.quantity.value
        : 1)
    );
  }, 0)
);

export const countCartRecipes = createSelector(
  [allCartRecipes],
  recipes => recipes.length
);

export const countCartCateredMeals = createSelector(
  [getCateredMeals, getCateredMealsRefs],
  (cateredMeals, ref) =>
    cateredMeals?.reduce((acc, item) => {
      const isPortions = ref?.[item?.itemNo]?.unit === 'per person';
      const qToAdd = isPortions ? 1 : item?.quantity?.value;
      return acc + item?.quantity?.value;
    }, 0)
);

export const cateredMealsTotalValue = createSelector([getCateredMeals], items =>
  items.reduce((acc, item) => acc + item.amount, 0)
);

export const cartTotalItemCountSelector = createSelector(
  [countCartItems, countCartCateredMeals],
  (items, cateredMeals) => items + cateredMeals
);

export const cartBagTotalValue = createSelector([getCartBags], items =>
  items.reduce((acc, item) => acc + item.amount, 0)
);

export const cartItemTotalValue = createSelector([getCartItems], items =>
  items.reduce((acc, item) => acc + item.amount, 0)
);

export const cartRecipeTotalValue = createSelector(
  [getCartRecipes_],
  recipeHandles =>
    Object.values(recipeHandles).reduce((acc, { amount }) => acc + amount, 0)
);

const blockProductsWithDefault = makeProductEnhancerSelector(getBlockProducts);

export const cartItemsBlockProductCombinationSelector = createSelector(
  [getCartItems, blockProductsWithDefault],
  productCombination
);

const categoryProductsWithDefaults = makeProductEnhancerSelector(
  getCategoryProducts
);
const productsWithDefaults = makeProductEnhancerSelector(getProducts);
export const cartItemsCategoryProductCombinationSelector = createSelector(
  [getCartItems, categoryProductsWithDefaults],
  productCombination
);
export const cartItemsProductCombinationSelector = createSelector(
  [getCartItems, productsWithDefaults],
  productCombination
);

export const sortedCartItemsCategoryProductCombinationSelector = createSelector(
  [cartItemsCategoryProductCombinationSelector, sortOrder],
  sortByProp
);
export const sortedCartItemsProductCombinationSelector = createSelector(
  [cartItemsProductCombinationSelector, sortOrder],
  sortByProp
);

export const extendedBags = createSelector(
  [makeBagEnhancerSelector(getCartBagRefs), allCartBags],
  (bags, cartBags) => {
    const combinedBags = cartBags
      .map(cb => {
        const isFlexbag = cb.itemNo && cb.itemNo.includes('Flexkassen');

        let matchedBag;
        if (isFlexbag) {
          matchedBag = bags.find(b => b.id === 'Flexkassen');
        } else {
          matchedBag = bags.find(b => b.id === cb.itemNo);
        }
        if (!matchedBag) {
          matchedBag = bags.find(b => b.variants.find(v => v.id === cb.itemNo));
        }
        if (!matchedBag) {
          return {
            ...cb,
            bag: { image: { url: '' } }
          };
        }

        let variant = { portions: 0, meals: 0 };
        if (isFlexbag) {
          variant = matchedBag.variants.find(x => x.id === cb.itemNo);
        } else {
          variant = matchedBag.variants.find(x => x.gtin === cb.gtin);
        }

        return {
          ...cb,
          bag: {
            ...matchedBag,
            name: matchedBag ? matchedBag.name : cb.name,
            variant: variant
          }
        };
      })
      .reverse();

    return combinedBags;
  }
);

// TODO Plz fix me, recipeRefs could be found in (1) time, without going through the enhancer
export const extendedRecipes = createSelector(
  [makeRecipeEnhancerSelector(getCartRecipeRefs), allCartRecipes],
  (recipes, cartRecipies) => {
    return cartRecipies
      .map(cr => ({
        ...cr,
        recipe: recipes.find(
          r => r.id === cr.recipeId || r.id === cr.variantId
        ) || {
          image: { url: '' }
        }
      }))
      .reverse();
  }
);

export const recipeWithCartRecipe = createSelector(
  [getRecipies, allCartRecipes],
  (recipes, cartRecipes) => {
    const lookup = arrayToObject(cartRecipes, 'recipeId');

    return Object.values(recipes).map(r =>
      lookup[r.id]
        ? {
            ...r,
            numberOfMeals: lookup[r.id].numberOfMeals
          }
        : {
            ...r,
            numberOfMeals: 4
          }
    );
  }
);

// TODO temporary until simplier model
export const cartItemExtendedInfoSelector = createSelector(
  [getCartItems, getCartItemsRefs, activeStoreNoSelector],
  (cartItems, refs, storeNumber) =>
    cartItems
      ?.sort((a, b) => (a.id > b.id ? 1 : -1))
      ?.map(item => {
        const product = refs[item.itemNo] || { image: { url: '' }, images: [] };

        return {
          ...item,
          totalCount: item.quantity.value,
          product: {
            ...product,
            image: setImage(product.images, 0),
            price: storeNumber
              ? product.prices?.find(p => p.storeNumber === storeNumber)
              : null
          }
        };
      })
      .reverse()
);

export const extendedCateredMeal = createSelector(
  [getCateredMeals, getCateredMealsRefs],
  (cateredMeals, refs) =>
    cateredMeals
      .map(item => {
        const cateredMeal = refs?.[item?.itemNo] || null;
        if (!cateredMeal) return;
        return {
          ...item,
          totalCount: item.quantity.value,
          cateredMeal: {
            ...cateredMeal,
            image: cateredMeal.images?.length
              ? setImage(cateredMeal.images, 0, 'catering')
              : { url: cateredMeal?.image, type: 0, alt: 'Standard' }
          }
        };
      })
      .reverse()
);

export const getRecipe = createSelector([getRecipeProducts], recipe => recipe);

const makeRecipeQuantitySelector = recipeGetter =>
  createSelector([extendedRecipes, recipeGetter], (cartRecipes, recipes) => {
    return Object.values(recipes).map(recipe => {
      return {
        ...recipe,
        quantity: cartRecipes.filter(cp => cp.recipeId === recipe.id).length
      };
    });
  });

export const allRecipesSelector = createSelector(
  [allCartRecipes],
  cartRecipes => cartRecipes
);

// For blocks not in state....
export const includeCartBlockRecipeQuantitySelector = makeRecipeQuantitySelector(
  getBlockRecipes
);

export const getCategoryCateringProducts = createSelector(
  state => ({
    items: state.catering.category,
    storeId: activeStoreNoSelector(state)
  }),
  catering => ({
    products: mapCateredMealToProductCard(catering.items, catering.storeId)
  })
);
