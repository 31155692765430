import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import config from '../../config/config';
const HelmetWrap = ({
  title,
  description,
  descriptionFallback,
  keywords,
  shareImage,
  link
}) => (
  <Helmet>
    <title>{title}</title>
    <meta
      name="description"
      content={description ? description : descriptionFallback}
    />
    <meta name="keywords" content={keywords} />
    {link && <link rel="canonical" href={config.HOST + link} />}
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content={description ? description : descriptionFallback}
    />
    {/* Helmet doesn't support React.Fragments :( */}
    {shareImage && shareImage.url && (
      <meta
        property="og:image"
        content={'https://www.citygross.se' + shareImage.url}
      />
    )}
    {shareImage && shareImage.width && (
      <meta property="og:image:width" content={shareImage.width} />
    )}
    {shareImage && shareImage.height && (
      <meta property="og:image:height" content={shareImage.height} />
    )}
    <meta name="theme-color" content="#FFFFFF" media="(prefers-color-scheme: light)"/>
    <meta name="theme-color" content="#FFFFFF" media="(prefers-color-scheme: dark)"/>
  </Helmet>
);

HelmetWrap.propTypes = {
  description: PropTypes.string,
  descriptionFallback: PropTypes.string,
  keywords: PropTypes.string,
  link: PropTypes.string,
  shareImage: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  title: PropTypes.string
};

export default HelmetWrap;
