import React, { Component } from 'react';
import BagList from '../../components/BagList';
import LoadingBlock from '../../components/LoadingBlock';
import PromotionHeader from '../../components/PromotionHeader';
import ErrorInfo from '../../components/ErrorInfo';

import './FoodbagCategory.scss';

class FoodbagCategory extends Component {
  render() {
    const {
      fetching,
      bags = [],
      page,
      errorBags,
      errorPage,
      getPageContent,
      getBagsContent
    } = this.props;
    return (
      <div className="c-foodbags">
        {errorPage ? (
          <ErrorInfo
            title="Hoppsan, något gick fel!"
            text="Det går inte att hämta denna sidan just nu."
            retryButtonFn={getPageContent}
          />
        ) : (
          <PromotionHeader {...page} />
        )}
        {errorBags ? (
          <ErrorInfo
            title={errorPage ? '' : 'Hoppsan, något gick fel!'}
            text="Det går inte hämta matkassarna just nu."
            retryButtonFn={getBagsContent}
          />
        ) : fetching ? (
          <LoadingBlock height={50} />
        ) : (
          <BagList bags={bags} />
        )}
      </div>
    );
  }
}

export default FoodbagCategory;
