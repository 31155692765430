import { ProductAvailability } from '../types/xim/product';

export const getNextAvailabilityDateForStore = (
  availabilityArray: ProductAvailability[],
  store: number | string | null,
  compareDate: Date = new Date()
) => {
  if (!availabilityArray || availabilityArray.length === 0) {
    return null;
  }

  const availability = availabilityArray.find(
    availability => availability.storeNumber === store
  );

  if (availability) {
    const { availableFrom, stockStatus } = availability;

    const date = compareDate;
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    const availableFromDate = new Date(availableFrom);
    availableFromDate.setHours(0, 0, 0, 0);

    if (availableFromDate <= date) {
      return null;
    }

    if (availableFromDate > date || stockStatus === 2) {
      return availableFrom;
    }
    return null;
  }
  return null;
};
