import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { authenticatedSelector } from '../../selectors/user';
import MediaQuery from 'react-responsive';
import cs from 'classnames';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import Link from '../../components/Link';
import * as pagelayouts from '../../prop-types/PageTypePropType';
import './ProductGrid.scss';
import ProductCardList from '../ProductCardList';

const ProductArticleBlock = ({
  title,
  link,
  linkText,
  products,
  isAuthenticated,
  mappedRedirectToLogin,
  mappedAddToCart,
  mappedIncreaseItemQuantity,
  mappedDecreaseItemQuantity,
  mappedSetProductFavorite,
  mappedRemoveFavorite,
  numRows = 2,
  pageLayout = pagelayouts.FULL,
  noPad = false,
  allRows,
  loading,
  fontColor,
  impressionListName,
  storeId,
  isCarousel
}) => {
  const cardFuncs = {
    addToCart: mappedAddToCart,
    increaseItemQuantity: mappedIncreaseItemQuantity,
    decreaseItemQuantity: mappedDecreaseItemQuantity,
    setProductFavorite: isAuthenticated
      ? mappedSetProductFavorite
      : mappedRedirectToLogin,
    removeFavorite: isAuthenticated
      ? mappedRemoveFavorite
      : mappedRedirectToLogin
  };

  return (
    <div
      className={cs(
        'b-productblock',
        { 'b-productblock--no-pad': noPad },
        { 'b-productblock--carousel': isCarousel },
        pageLayout.toLowerCase()
      )}
    >
      {title && <h2 style={{ color: fontColor }}>{title}</h2>}
      {isCarousel ? (
        <ProductCardList
          products={products}
          maxItems={numRows * 6}
          cardFuncs={cardFuncs}
          allRows={allRows}
          loading={loading}
          impressionListName={impressionListName}
          storeId={storeId}
          isCarousel={isCarousel}
        />
      ) : (
        <div className={'grid-container-mobileGutter'}>
          <MediaQuery maxWidth={600}>
            <ProductCardList
              products={products}
              maxItems={numRows * 2}
              cardFuncs={cardFuncs}
              allRows={allRows}
              loading={loading}
              impressionListName={impressionListName}
              storeId={storeId}
            />
          </MediaQuery>
          <MediaQuery minWidth={601} maxWidth={788}>
            <ProductCardList
              products={products}
              maxItems={numRows * 3}
              cardFuncs={cardFuncs}
              allRows={allRows}
              loading={loading}
              impressionListName={impressionListName}
              storeId={storeId}
            />
          </MediaQuery>
          <MediaQuery minWidth={789} maxWidth={1212}>
            <ProductCardList
              products={products}
              maxItems={numRows * 4}
              cardFuncs={cardFuncs}
              allRows={allRows}
              loading={loading}
              impressionListName={impressionListName}
              storeId={storeId}
            />
          </MediaQuery>
          <MediaQuery minWidth={1213} maxWidth={1700}>
            <ProductCardList
              products={products}
              maxItems={numRows * 5}
              cardFuncs={cardFuncs}
              allRows={allRows}
              loading={loading}
              impressionListName={impressionListName}
              storeId={storeId}
            />
          </MediaQuery>
          <MediaQuery minWidth={1701}>
            <ProductCardList
              products={products}
              maxItems={numRows * 6}
              cardFuncs={cardFuncs}
              allRows={allRows}
              loading={loading}
              impressionListName={impressionListName}
              storeId={storeId}
            />
          </MediaQuery>
        </div>
      )}

      {link && linkText && (
        <div className="b-productblock__moreprods">
          <Link link={link} style={{ color: fontColor }}>
            {linkText}
          </Link>
        </div>
      )}
    </div>
  );
};

ProductArticleBlock.propTypes = {
  title: PropTypes.string,
  link: PropTypes.object,
  linkText: PropTypes.string,
  products: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  mappedRedirectToLogin: PropTypes.func,
  mappedAddToCart: PropTypes.func,
  mappedIncreaseItemQuantity: PropTypes.func,
  mappedDecreaseItemQuantity: PropTypes.func,
  mappedSetProductFavorite: PropTypes.func,
  mappedRemoveFavorite: PropTypes.func,
  numRows: PropTypes.number,
  pageLayout: PropTypes.string,
  noPad: PropTypes.bool,
  allRows: PropTypes.bool,
  loading: PropTypes.bool,
  fontColor: PropTypes.string,
  impressionListName: PropTypes.string,
  storeId: PropTypes.number
};

ProductArticleBlock.defaultProps = {
  numRows: 1,
  pageLayout: pagelayouts.FULL,
  noPad: false
};

const mapStateToProps = (state, props) => ({
  products: cartItemsBlockProductCombinationSelector(state, props.products),
  isAuthenticated: authenticatedSelector(state)
});

const mapDispatchToProps = {
  mappedIncreaseItemQuantity: increaseItemQuantity,
  mappedDecreaseItemQuantity: decreaseItemQuantity,
  mappedRedirectToLogin: redirectToLogin,
  mappedAddToCart: addToCart,
  mappedSetProductFavorite: setProductFavorite,
  mappedRemoveFavorite: removeFavorite
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductArticleBlock)
);
