import styled from 'styled-components';

export const CartEmptyStateContainer = styled.div`
  height: 100%;
  padding: 24px;
  background: #fff;
  display: grid;
  place-content: center;
  text-align: center;
  strong {
    font-weight: 500;
  }
    a{
    color: #0072BB;
    text-decoration: underline;
    border-radius: 4px;
     &:hover {
    text-decoration:none};
    &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px #fff,0 0 0 4px #000,0 0 0 6px #fff;
    transition: all 0.1s ease-in-out;
  }
  }}
`;

export const CartEmptyButtonContainer = styled.div`
  margin-top: 16px;
`;
