import React, { useState, useEffect } from 'react';
import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { authenticatedSelector } from '../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTopSellers } from '../../api/endpoints/product';
import { Product } from '../../types/xim/product';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { AppState } from '../../reducers';
import Pagination from '../Pagination/Pagination';
import qs from 'query-string';
import { updateHistory } from '../../lib/utils';
import { isNumber } from '../../lib/number';

const ProductTopSellers = ({ title, numRows }) => {
  const getInitialpage = () => {
    const query = qs.parse(location.search);
    const queryP = Object.assign({}, query);
    return queryP?.page ? Number(queryP?.page) - 1 : 0;
  };
  const [fetching, setFetching] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationIndex, setPaginationIndex] = useState(getInitialpage());
  const [pageCount, setPageCount] = useState(0);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const activeStore: string | number | null = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const productTopSellers = useSelector(state =>
    cartItemsBlockProductCombinationSelector(state, products)
  );

  const onAddToCart = item => {
    if (isAuthenticated) {
      return dispatch(addToCart(item));
    } else {
      return dispatch(redirectToLogin);
    }
  };

  useEffect(() => {
    if (numRows > 0 && activeStore) {
      setFetching(true);

      const page = paginationIndex ? paginationIndex + 1 : 1;

      dispatch(updateHistory({ page }, location));
      fetchTopSellers({
        store: activeStore,
        size: 4 * numRows,
        page: page - 1
      })
        .then(({ data }) => {
          if (data.data && data.data.length > 0) {
            if (data.meta.totalCount) {
              setTotalCount(data.meta.totalCount);
              setPageCount(data.meta.pageCount);
            }

            setProducts(data.data);
          }
          setFetching(false);
        })
        .catch(e => {
          console.error(e);
          setFetching(false);
        });
    }
  }, [paginationIndex, activeStore]);

  return (
    <div className="c-productdetails__related">
      {!!title && <h2>title</h2>}
      <ProductList
        fetching={false}
        addToCart={onAddToCart}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={productFavorite =>
          dispatch(removeFavorite(productFavorite))
        }
        increaseItemQuantity={(id, quantity) =>
          dispatch(increaseItemQuantity(id, quantity))
        }
        decreaseItemQuantity={(id, quantity) =>
          dispatch(decreaseItemQuantity(id, quantity))
        }
        items={productTopSellers}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />
      {isNumber(paginationIndex) && isNumber(pageCount) && (
        <Pagination
          pageCount={pageCount}
          pageIndex={paginationIndex}
          totalCount={totalCount}
          fetchPage={page => setPaginationIndex(page)}
        />
      )}
    </div>
  );
};

export default ProductTopSellers;
