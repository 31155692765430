import { createSelector } from 'reselect';
import { breadCrumbSelector } from './routing/routingSelector';
import { appSettingsSelector, hasFetchedNavigationSelector } from './app';
import * as PageLayoutTypes from '../prop-types/PageTypePropType';
import { setImage as productSetImage } from '../lib/product';
import { setImage as recipeSetImage } from './recipe/recipeSelectors.js';

const propsPassThrough = (state, props) => props;

export const getStores = state => (state.page && state.page.pages) || [];
export const getStorePage = state => state.page && state.page.storePage;

// Product
export const getProducts = ({ product = {} }) =>
  product.products || product.category || [];
export const getProductId = (state, props) => {
  return props.match.params ? props.match.params.productId : -1;
};

export const productSelector = createSelector(
  [getProductId, getProducts],
  (id, products) => {
    return products.find(r => r.id === id) || null;
  }
);

// Recipe
export const getRecipes = ({ recipe = {} }) => recipe.recipes || [];
export const getRecipeId = (state, props) => {
  return props.match.params ? props.match.params.recipeId : -1;
};
export const recipeSelector = createSelector(
  [getRecipeId, getRecipes],
  (id, recipes) => {
    return recipes.find(r => r.id === 'R' + id) || null;
  }
);

//Pages
export const getPageId = ({ navigation = {} }, { match = {} }) => {
  const { pathLookup = [] } = navigation;
  const { path } = match;
  return path && pathLookup[path] && pathLookup[path].id;
};

export const getPages = ({ page = {} }) => page.pages || [];

export const pageIdSelector = createSelector([getPageId], id => id);

export const pageSelector = createSelector(
  [getPageId, getPages],
  (id, pages) => {
    const defaultPage = {
      contentBlocks: []
    };
    return pages.find(p => p.id === id) || defaultPage;
  }
);

export const getPageByIdSelector = createSelector(
  [getPages, propsPassThrough],
  (pages, id) => {
    const defaultPage = {
      contentBlocks: []
    };
    return pages.find(p => p.id === id) || defaultPage;
  }
);

export const pageLayoutSelector = createSelector([pageSelector], page => {
  if (page.type === 'FullWidthPage') {
    return PageLayoutTypes.FULL;
  }
  return PageLayoutTypes.SIDEBAR;
});

// Returns an SEO object
export const seoSelector = createSelector(
  [
    pageSelector,
    appSettingsSelector,
    hasFetchedNavigationSelector,
    breadCrumbSelector,
    recipeSelector,
    productSelector,
    propsPassThrough
  ],
  (
    page,
    appSettings,
    hasFetchedNavigation,
    breadCrumb,
    recipe,
    product,
    props
  ) => {
    const link = product
      ? product.url
      : recipe
      ? recipe.url
      : props.location
      ? props.match.path
      : undefined;
    const {
      seo: {
        title: appTitle,
        description: appDescription,
        keywords: appKeywords = [],
        shareImage: appShareImage = {}
      } = {}
    } = appSettings ?? {};
    const {
      seo: {
        title: pageTitle,
        description: pageDescription,
        keywords: pageKeywords = [],
        shareImage: pageShareImage = {}
      } = {},
      name: pageName
    } = page;
    const { current = {} } = breadCrumb;

    const title = pageTitle
      ? pageTitle
      : pageName
      ? pageName
      : current.name
      ? current.name
      : appTitle;
    const keywords = pageKeywords.length > 0 ? pageKeywords : appKeywords;
    const shareImage = pageShareImage.url ? pageShareImage : appShareImage;

    if (recipe) {
      let image;
      if (recipe.images) {
        image = recipeSetImage(recipe.images, 0);
      }
      const recipeKeywords = recipe.tags.map(x => x.name).join(',');
      const ingredients =
        'Ingredienser: ' +
        recipe.ingredients
          .map(
            x =>
              (x.quantity
                ? x.quantity.toString() + ' ' + (x.unit ? x.unit : '') + ' '
                : '') + x.name
          )
          .join(', ');

      return {
        title: recipe.name,
        description: recipe.name + ', ' + ingredients,
        keywords: recipeKeywords.length ? recipeKeywords : undefined,
        link: link,
        shareImage: {
          url: image.url,
          width: '',
          height: ''
        },
        descriptionFallback: appDescription
      };
    } else if (product) {
      let image;
      if (product.images) {
        image = productSetImage(product.images, 0);
      }
      return {
        title: product.name,
        description: product.description ? product.description : null,
        descriptionFallback: appDescription,
        link: link,
        keywords: product.searchKeywords,
        shareImage: {
          url: image ? image.url : '',
          width: '',
          height: ''
        }
      };
    } else if (page.type === 'ProductCategoryPage') {
      return {
        title: hasFetchedNavigation
          ? props.errorCode
            ? props.errorCode + (title ? ' ' + title : '')
            : title
            ? title
            : ''
          : '',
        description: pageDescription ? pageDescription : null,
        descriptionFallback: appDescription,
        keywords: keywords.length ? keywords.join(',') : undefined,
        link: link,
        shareImage: {
          url: shareImage ? shareImage.url : '',
          width: shareImage ? shareImage.width : '',
          height: shareImage ? shareImage.height : ''
        }
      };
    } else if (page.type === 'ArticlePage') {
      const mainImage = page.mainImage || page.topImage || shareImage;
      return {
        title: hasFetchedNavigation
          ? props.errorCode
            ? props.errorCode + (title ? ' ' + title : '')
            : title
            ? title
            : ''
          : '',
        description: pageDescription ? pageDescription : null,
        descriptionFallback: appDescription,
        keywords: keywords.length ? keywords.join(',') : undefined,
        link: link,
        shareImage: {
          url: mainImage ? mainImage.url : '',
          width: mainImage ? mainImage.width : '',
          height: mainImage ? mainImage.height : ''
        }
      };
    } else if (page.type === 'BagPage') {
      const mainImage = page.mainImage || page.topImage || shareImage;
      const prodImage = page.bag && page.bag.images && page.bag.images[0];
      return {
        title: hasFetchedNavigation
          ? props.errorCode
            ? props.errorCode + (title ? ' ' + title : '')
            : title
            ? title
            : ''
          : '',
        description: pageDescription ? pageDescription : null,
        descriptionFallback: appDescription,
        keywords: keywords.length ? keywords.join(',') : undefined,
        link: link,
        shareImage: {
          url: prodImage ? '/' + prodImage.url : mainImage ? mainImage.url : '',
          width: prodImage ? null : mainImage ? mainImage.width : '',
          height: prodImage ? null : mainImage ? mainImage.height : ''
        }
      };
    } else {
      return {
        title: hasFetchedNavigation
          ? props.errorCode
            ? props.errorCode + (title ? ' ' + title : '')
            : title
            ? title
            : ''
          : '',
        description: pageDescription ? pageDescription : null,
        descriptionFallback: appDescription,
        keywords: keywords.length ? keywords.join(',') : undefined,
        link: link,
        shareImage: {
          url: shareImage ? shareImage.url : '',
          width: shareImage ? shareImage.width : '',
          height: shareImage ? shareImage.height : ''
        }
      };
    }
  }
);
