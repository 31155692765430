import { fetchCartItems, splitByType } from '../../lib/cart';
import { toLookup } from '../../lib/utils';

const makeExtendedItems = (lookup, items, prop = 'gtin') => {
  const matchVariants = (matchOnThis, item) =>
    matchOnThis[item[prop]]
      ? matchOnThis[item[prop]]
      : item?.variants?.reduce((acc2, variant) => {
          if (matchOnThis[variant[prop]]) {
            acc2 = matchOnThis[variant[prop]];
          }
          return acc2;
        }, null);

  const extendedItems = items?.reduce((acc, item) => {
    let theLookup = lookup;

    // We need to convert to a list if it's not already are.
    if (!Array.isArray(theLookup)) {
      theLookup = [{ [item[prop]]: theLookup }];
    }

    let match;

    theLookup.forEach(object => {
      if (matchVariants(object, item)) {
        match = matchVariants(object, item);
      }
    });

    if (match) {
      acc.push({
        ...match,
        item
      });
    }

    return acc;
  }, []);

  return extendedItems;
};

export const getCartItemsData = cart => {
  return fetchCartItems(cart).then(
    ([cart, [productRefs, recipeRefs, bagRefs, cateredMealsRefs]]) => {
      const splitCart = splitByType(cart);
      const flatRecipes = Array.prototype
        .concat(
          ...splitCart.recipes.map(({ recipes }) => recipes),
          ...splitCart.recipes.map(({ editableRecipes }) => {
            if (!editableRecipes) {
              return;
            }
            return editableRecipes.map(recipe => ({
              ...recipe,
              type: 'Editable'
            }));
          })
        )
        .filter(x => x);
      
      return {
        ...splitCart,
        totalItemPrice: splitCart.items.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        totalRecipePrice: splitCart.recipes.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        totalBagPrice: splitCart.bags.reduce(
          (acc, item) => acc + item.amount,
          0
        ),
        totalCharges: splitCart.charges.reduce(
          (acc, charge) => acc + charge.price.gross,
          0
        ),
        items: makeExtendedItems(
          toLookup(splitCart.items, 'itemNo'),
          productRefs,
          'id'
        ),
        recipes: makeExtendedItems(
          toLookup(flatRecipes, 'recipeId', true),
          recipeRefs,
          'id'
        ),
        bags: makeExtendedItems(
          toLookup(splitCart.bags, 'itemNo', true),
          bagRefs,
          'id'
        ),
        cateredMeals: makeExtendedItems(
          toLookup(splitCart.cateredMeals, 'itemNo', true),
          cateredMealsRefs,
          'id'
        )
      };    }
  );
};
