import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import CategorySuggestions from '../CategorySuggestions';
import GroupedCategoriesSuggestion from '../GroupedCategoriesSuggestion';
import ProductSuggestion from '../ProductSuggestion';
import Suggestions from '../Suggestions';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../../api/endpoints/notify';

import './SearchSuggestions.scss';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Buttons';

export const SearchSuggestions = ({
  addToCart,
  bags,
  bagPage,
  cateredmeal,
  cmscontent,
  decreaseItemQuantity,
  isAuthenticated,
  increaseItemQuantity,
  onClose,
  onShowAll,
  products,
  query,
  recipes,
  redirectToLogin,
  resultCount,
  cateringCategoryPage,
  productCategoryPage,
  recipeCategoryPage,
  storePage,
  totalCount,
  storeId,
  setKeepOpen
}) => {
  const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;

  const hasProducts = Boolean(products.items.length);

  const hasCategorized =
    Boolean(cateredmeal.items.length) ||
    Boolean(cmscontent.items.length) ||
    Boolean(bags.items.length) ||
    Boolean(bagPage.items.length) ||
    Boolean(recipes.items.length) ||
    Boolean(cateringCategoryPage.items.length) ||
    Boolean(productCategoryPage.items.length) ||
    Boolean(recipeCategoryPage.items.length) ||
    Boolean(storePage.items.length);

  const hasCateredmeal = Boolean(cateredmeal.items.length);
  const hasCmscontent = Boolean(cmscontent.items.length);
  const hasBags = Boolean(bags.items.length);
  const hasBagPage = Boolean(bagPage.items.length);
  const hasRecipes = Boolean(recipes.items.length);
  const hasCateringCategory = Boolean(cateringCategoryPage.items.length);
  const hasProductCategory = Boolean(productCategoryPage.items.length);
  const hasRecipeCategory = Boolean(recipeCategoryPage.items.length);
  const hasStorePage = Boolean(storePage.items.length);

  const hasGroupedCategories =
    hasCateringCategory ||
    hasProductCategory ||
    hasRecipeCategory ||
    hasBagPage;

  const groupedCategoriesResultCount =
    hasGroupedCategories &&
    parseInt(
      resultCount?.find(countItem => countItem.id === 'category')?.count,
      10
    );

  const bagResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'bag')?.count,
    10
  );
  const cateredmealResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'cateredmeal')?.count,
    10
  );
  const cmscontentResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'cmscontent')?.count,
    10
  );
  const productsResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'product')?.count,
    10
  );
  const recipeResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'recipe')?.count,
    10
  );
  const storePageResultCount = parseInt(
    resultCount?.find(countItem => countItem.id === 'store')?.count,
    10
  );
 
  return (
    <div className={'c-search-suggestions'}>
      {hasProducts ? (
        <>
        <div
          className={cs('c-search-suggestions__main-suggestions', {
            'c-search-suggestions__main-suggestions--has-sidebar': hasCategorized
          })}
        >
          <div className={'inner-wrapper'}>
            <Suggestions
              count={productsResultCount}
              onShowAll={onShowAll}
              query={query}
              title={products.title}
              type="product"
              onClose={onClose}
            >
              {products.items
                .slice(0, 6)
                .map(item => (
             
                  <ProductSuggestion
                    product={item}
                    key={item.id}
                    noLazy={false}
                    setKeepOpen={setKeepOpen}
                    addToCart={() => addToCartFn(item)}
                    increaseItemQuantity={quantity =>
                      increaseItemQuantity(item.cartItemId, quantity)
                    }
                    decreaseItemQuantity={quantity =>
                      decreaseItemQuantity(item.cartItemId, quantity)
                    }
                    esalesClickTrigger={
                      item.ticket
                        ? () => {
                          ticketNotifierByClick(item.ticket) 
                        }
                        : () => {}
                    }
                    esalesClickTriggerOnAddingToCart={
                      item.ticket
                        ? () => ticketNotifierByAddingToCard(item.ticket)
                        : () => {}
                    }
                  />
                 
             
                ))}
            </Suggestions>
          </div>
        </div>
        <div
                    className="c-search-result__view-all c-search-view-all__mobile"
                  >
                    <Link
                      to={`/sok?Q=${encodeURIComponent(
                        query
                      )}&store=${storeId ? storeId : ''}&page=0`}
                    >
                      <Button
                        disabled={!totalCount > 0}
                        onClick={onClose}
                        minWidth={'170px'}
                      >
                        {' '}
                        Visa fler sökresultat
                      </Button>
                    </Link>
                  </div>
        </>
      ) : null}

      {hasCategorized ? (
        <div
          className={cs('c-search-suggestions__categorized-suggestions', {
            'c-search-suggestions__categorized-suggestions--is-sidebar': hasProducts,
            'c-search-suggestions__categorized-suggestions--is-main-area': !hasProducts
          })}
        >
          <div className={'inner-wrapper'}>
            {hasBags && (
              <CategorySuggestions
                count={bagResultCount}
                itemClickHandler={onClose}
                items={bags.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={bags.title}
                type="bag"
                isSidebar={hasProducts}
              />
            )}

            {hasCateredmeal && (
              <CategorySuggestions
                count={cateredmealResultCount}
                itemClickHandler={onClose}
                items={cateredmeal.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={cateredmeal.title}
                type="cateredmeal"
                isSidebar={hasProducts}
              />
            )}

            {hasRecipes && (
              <CategorySuggestions
                count={recipeResultCount}
                itemClickHandler={onClose}
                items={recipes.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={recipes.title}
                type="recipe"
                isSidebar={hasProducts}
              />
            )}

            {hasGroupedCategories && (
              <GroupedCategoriesSuggestion
                count={groupedCategoriesResultCount}
                itemClickHandler={onClose}
                bagPageItems={bagPage.items}
                cateringCategoryPageItems={cateringCategoryPage.items}
                productCategoryPageItems={productCategoryPage.items}
                recipeCategoryPageItems={recipeCategoryPage.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={'Kategorier'}
                type="category"
                isSidebar={hasProducts}
              />
            )}

            {hasStorePage && (
              <CategorySuggestions
                count={storePageResultCount}
                itemClickHandler={onClose}
                items={storePage.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={storePage.title}
                type="store"
                isSidebar={hasProducts}
              />
            )}

            {hasCmscontent && (
              <CategorySuggestions
                count={cmscontentResultCount}
                itemClickHandler={onClose}
                items={cmscontent.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={cmscontent.title}
                type="cmscontent"
                isSidebar={hasProducts}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

SearchSuggestions.propTypes = {
  addToCart: PropTypes.any,
  bags: PropTypes.any,
  cmscontent: PropTypes.any,
  decreaseItemQuantity: PropTypes.any,
  increaseItemQuantity: PropTypes.any,
  isAuthenticated: PropTypes.any,
  onClose: PropTypes.any,
  onShowAll: PropTypes.any,
  products: PropTypes.any,
  recipes: PropTypes.any,
  redirectToLogin: PropTypes.any,
  resultCount: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      count: PropTypes.string
    })
  ),
  query: PropTypes.any
};

SearchSuggestions.defaultProps = {
  cateredmeal: []
};

export default SearchSuggestions;
